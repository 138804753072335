import React from "react";
import { useEffect, useContext } from "react";

import footerLogo from "../images/logo.png";
import wave2 from "../images/wave2.svg";

import { en, hr } from "./Translation";
import { TranslationContext } from "./TranslationContext";

const Footer = () => {
  const { language, isFading } = useContext(TranslationContext);
  const texts = language === "hr" ? hr : en;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const backToTopButton = document.querySelector(".back-to-top");

      if (backToTopButton) {
        if (scrollTop > 500) {
          backToTopButton.style.display = "block";
        } else {
          backToTopButton.style.display = "none";
        }
      }
    };

    const handleClick = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return false;
    };

    window.addEventListener("scroll", handleScroll);

    const backToTopButton = document.querySelector(".back-to-top");
    if (backToTopButton) {
      backToTopButton.addEventListener("click", handleClick);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (backToTopButton) {
        backToTopButton.removeEventListener("click", handleClick);
      }
    };
  }, []);

  return (
    <>
      <div className="position-relative carousel-wrapper">
        <div className="shape overflow-hidden text-footer carousel-container">
          <img className="wave carousel-image-01" src={wave2} alt="wave" />
          <img className="wave carousel-image-02" src={wave2} alt="wave" />
        </div>
      </div>

      <footer className="footer">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-6 offset-lg-3 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
              <a href="#;" className="logo text-primary">
                <img
                  className="shadow-logo logom"
                  src={footerLogo}
                  width="80px"
                  alt="shadow logo"
                />
              </a>
              d.o.o.
              <p
                className={`mt-4 text-container ${isFading ? "fade-out" : ""}`}
              >
                {texts.footerText1}
              </p>
              <p className={`text-container ${isFading ? "fade-out" : ""}`}>
                Kapucinska 31, Osijek, Croatia
              </p>
              <p className={`text-container ${isFading ? "fade-out" : ""}`}>
                OIB: 98712426804
              </p>
              <p className={`text-container ${isFading ? "fade-out" : ""}`}>
                EUID: HRSR.030237716
              </p>
              <p
                className={`mb-0 text-container ${isFading ? "fade-out" : ""}`}
              >
                {texts.footerText2}:
              </p>
              <p
                className={`text-uppercase text-container ${
                  isFading ? "fade-out" : ""
                }`}
              >
                <a href="https://www.hanfa.hr/warnings-by-hanfa/virtual-currencies/">
                  {texts.footerText3}
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer footer-bar">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="text-sm-left">
                <p className="mb-0">
                  © 2023
                  <a href="#;" className="logo text-primary mx-1">
                    <img
                      className="shadow-logo logom"
                      src={footerLogo}
                      alt="logo"
                      width="50px"
                    />
                  </a>
                  .{" "}
                  <span
                    className={`text-container ${isFading ? "fade-out" : ""}`}
                  >
                    {texts.footerText4}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="btn btn-icon btn-primary back-to-top">
        <div className="icons">
          <i className="mdi mdi-arrow-up"></i>
        </div>
      </div>
    </>
  );
};

export default Footer;
