import React, { useRef, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  InfoWindowF,
  MarkerF,
  MarkerClusterer,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const center = {
  lat: 44.6910812,
  lng: 17.2008489,
};

const locations = [
  // Autoskola Starcevic
  { lat: 45.556166, lng: 18.6830981 },
  // Hotel Split
  { lat: 43.502831, lng: 16.470369 },
  // Ines Tehnika
  { lat: 45.29176, lng: 18.80755 },
  // Rubicon Pub
  { lat: 45.5552267, lng: 18.6810831 },
  // Autoskola Blue
  { lat: 45.5497858, lng: 18.6996728 },
  // DMTeam j.d.o.o.
  { lat: 45.5613455, lng: 18.6641791 },
  // Kamp Kalebic
  { lat: 43.8451303, lng: 15.6178724 },
  // Euroauto
  { lat: 45.5415146, lng: 18.6437817 },
  // Villa Katarina
  { lat: 45.5278909, lng: 18.97066 },
  // Poliklinika Sv. Ante
  { lat: 45.555538, lng: 18.6789019 },
  // Auto Reor
  { lat: 45.5488977, lng: 18.6605459 },
  // DreamCar
  { lat: 45.5593237, lng: 18.6705202 },
  // Rulla Street Food
  { lat: 45.5609164, lng: 18.6784706 },
  // AC Kotromanovic
  { lat: 45.5426713, lng: 18.6580124 },
  // Hemp Agro
  { lat: 45.5506571, lng: 18.5128256 },
  // Extreme Sport
  { lat: 45.564514, lng: 18.6407139 },
  // Cingi Lingi Carda
  { lat: 45.5922558, lng: 18.7380635 },
  //Hotel Tvrdja
  { lat: 45.561138, lng: 18.6966483 },
  //Krisclinic
  { lat: 45.56533, lng: 18.646065 },
  //Merlinka
  { lat: 45.484253, lng: 15.546548 },
  //Alice in blockchains
  { lat: 45.8104554, lng: 15.9837085 },
  //La Dutti
  { lat: 45.5558455, lng: 18.6789859 },
  //MEDrOS medicina rada i sporta
  { lat: 45.5535079, lng: 18.6787352 },
  //Studio Fusion
  { lat: 45.5321574, lng: 18.6258628 },
  //998 Garage
  { lat: 45.5390238, lng: 18.6387391 },
  //Pamida
  { lat: 45.5640766, lng: 18.6624857 },
  //Solvermax
  { lat: 45.6353346, lng: 18.6900853 },
  //Srebro
  { lat: 45.5497938, lng: 18.7009804 },
  //Exoro
  { lat: 45.5624044, lng: 18.6341529 },
  //Konjik d.o.o. - Optika Oko
  { lat: 45.8291853, lng: 16.05481 },
  //Massa d.o.o.
  { lat: 45.5397453, lng: 18.6452778 },
  //i-KONST
  { lat: 45.5626415, lng: 18.6206357 },
];

const fullName = [
  "Autoškola Starčević<br>Mail: autoskolastarcevic@gmail.com<br>091 1233 321",
  "Hotel Split d.d.<br>Mail: stjepko.kuran@hotelsplit.hr<br>091 2190 060",
  "Ines tehnika j.d.o.o. <br>Mail: zvonko.culo@gmail.com<br>032 338 333",
  "HU-BO d.o.o.<br>Mail: hbprojekt@gmail.com<br>098 842 822",
  "Autoškola Blue<br>Mail: askola.blue@gmail.com<br>097 7537 422",
  "DMTeam j.d.o.o.<br>Mail: dmteam.os@gmail.com<br>097 6969 196",
  "Kamp Kalebić<br>Mail: kamp.kalebic@gmail.com<br>092 2052 561",
  "Euroauto d.o.o.<br>Mail: info@euroauto.hr<br>095 533 307",
  "Villa Katarina <br>Mail: dskobic@gmail.com<br>091 3700 000",
  "Polikilinka Sv. Ante<br>Mail: hrvoje.mihalj@gmail.com<br>098 712 135",
  "Auto Reor d.o.o.<br>Mail: racunovodstvo@auto-reor.hr<br>031 297 230",
  "DreamCar<br>Mail: dreamcar.os@hotmail.com<br>091 3242 411",
  "Rulla j.d.o.o.<br>Mail: tomislav89gotovac@gmail.com<br>091 5025 358",
  "AC Kotromanović<br>Mail: vedran@kotromanovic.hr<br>098 1309 704",
  "Hemp Agro d.o.o.<br>Mail: gudelj.domagoj@gmail.com<br>091 1177 778",
  "Extreme sport d.o.o.<br>Mail: drazen@extremesport.hr<br>091 5307 703",
  "Cingi Lingi Carda d.o.o.<br>Mail: zeljko.boris.huber@gmail.com<br>099 3533 310",
  "Castellum Art d.o.o. <br>Mail: hbprojekthr@gmail.com<br>031 212 064",
  "KrisClinic Estetski Centar<br>Mail: kjuric75@gmail.com<br>098 9432 656",
  "Merlinka d.o.o.<br>Mail: merlinka1@ka.ht.hr<br>098 1924 444",
  "Alice in blockchains<br>Mail: info@aliceinblockchains.io<br>098 4535 00",
  "La Dutti frizerski obrt<br>Mail: ana.zrinjevac@gmail.com<br>098 8352 11",
  "MEDrOS medicina rada i sporta<br>Mail: medros@medros.hr<br>095 3100 011",
  "Studio Fusion<br>Mail: diana.bulaku@gmail.com<br>099 3274 758",
  "998 Garage d.o.o.<br>Mail: 998garage@gmail.com<br>091 6011 979",
  "Pamida d.o.o.<br>Mail: mchordacar@gmail.com<br>095 8132 419",
  "Solvermax j.d.o.o.<br>Mail: info@solvermax.hr<br>095 8665 493",
  "Srebro d.o.o.<br>Mail: ivgrabic@gmail.com<br>098 423 775",
  "Exoro d.p.<br>Mail: drazen@exoro.hr<br>091 3339 900",
  "Konjik d.o.o. | Optika Oko<br>Mail: marija.limit@gmail.com<br>097 6091 655",
  "Massa d.o.o.<br>Mail: ivan@massa.hr<br>091 5678 909",
  "i-KONST<br>Mail: ivan@massa.hr<br>091 5678 909",
];

const labels = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
];

const MapComponent = () => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [infoLabel, setInfoLabel] = useState(null);

  const mapRef = useRef(null);

  const handleMarkerClick = (location, label) => {
    setSelectedPlace(location);
    setInfoLabel(label);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBwDdWDzGNNhIu3SEZiJPSQ-EQrZXq_OWk">
      <GoogleMap
        id="map"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={7}
        ref={mapRef}
      >
        <MarkerClusterer>
          {(clusterer) =>
            locations.map((location, index) => (
              <MarkerF
                key={index}
                position={location}
                label={labels[index]}
                clusterer={clusterer} // link each marker to the clusterer
                onClick={() => handleMarkerClick(location, fullName[index])}
              />
            ))
          }
        </MarkerClusterer>

        {selectedPlace && (
          <InfoWindowF
            position={selectedPlace}
            onCloseClick={() => setSelectedPlace(null)}
          >
            <div dangerouslySetInnerHTML={{ __html: infoLabel }} />
          </InfoWindowF>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;

// import React, { useRef, useState } from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   InfoWindow,
//   MarkerF,
// } from "@react-google-maps/api";

// const containerStyle = {
//     width: "100%",
//     height: "600px",
//   };
  
//   const center = {
//     lat: 44.6910812,
//     lng: 17.2008489,
//   };
  
//   const locations = [
//     // Autoskola Starcevic
//     { lat: 45.556166, lng: 18.6830981 },
//     // Hotel Split
//     { lat: 43.502831, lng: 16.470369 },
//     // Ines Tehnika
//     { lat: 45.29176, lng: 18.80755 },
//     // Rubicon Pub
//     { lat: 45.5552267, lng: 18.6810831 },
//     // Autoskola Blue
//     { lat: 45.5497858, lng: 18.6996728 },
//     // DMTeam j.d.o.o.
//     { lat: 45.5613455, lng: 18.6641791 },
//     // Kamp Kalebic
//     { lat: 43.8451303, lng: 15.6178724 },
//     // Euroauto
//     { lat: 45.5415146, lng: 18.6437817 },
//     // Villa Katarina
//     { lat: 45.5278909, lng: 18.97066 },
//     // Poliklinika Sv. Ante
//     { lat: 45.555538, lng: 18.6789019 },
//     // Auto Reor
//     { lat: 45.5488977, lng: 18.6605459 },
//     // DreamCar
//     { lat: 45.5593237, lng: 18.6705202 },
//     // Rulla Street Food
//     { lat: 45.5609164, lng: 18.6784706 },
//     // AC Kotromanovic
//     { lat: 45.5426713, lng: 18.6580124 },
//     // Hemp Agro
//     { lat: 45.5506571, lng: 18.5128256 },
//     // Extreme Sport
//     { lat: 45.564514, lng: 18.6407139 },
//     // Cingi Lingi Carda
//     { lat: 45.5922558, lng: 18.7380635 },
//     //Hotel Tvrdja
//     { lat: 45.561138, lng: 18.6966483 },
//     //Krisclinic
//     { lat: 45.56533, lng: 18.646065 },
//     //Merlinka
//     { lat: 45.484253, lng: 15.546548 },
//     //Alice in blockchains
//     { lat: 45.8104554, lng: 15.9837085 },
//     //La Dutti
//     { lat: 45.5558455, lng: 18.6789859 },
//     //MEDrOS medicina rada i sporta
//     { lat: 45.5535079, lng: 18.6787352 },
//     //Studio Fusion
//     { lat: 45.5321574, lng: 18.6258628 },
//     //998 Garage
//     { lat: 45.5390238, lng: 18.6387391 },
//     //Pamida
//     { lat: 45.5640766, lng: 18.6624857 },
//     //Solvermax
//     { lat: 45.6353346, lng: 18.6900853 },
//     //Srebro
//     { lat: 45.5497938, lng: 18.7009804 },
//     //Exoro
//     { lat: 45.5624044, lng: 18.6341529 },
//     //Konjik d.o.o. - Optika Oko
//     { lat: 45.8291853, lng: 16.05481 },
//     //Massa d.o.o.
//     { lat: 45.5397453, lng: 18.6452778 },
//     //i-KONST
//     { lat: 45.5626415, lng: 18.6206357 },
//   ];
  
//   const fullName = [
//     "Autoškola Starčević<br>Mail: autoskolastarcevic@gmail.com<br>091 1233 321",
//     "Hotel Split d.d.<br>Mail: stjepko.kuran@hotelsplit.hr<br>091 2190 060",
//     "Ines tehnika j.d.o.o. <br>Mail: zvonko.culo@gmail.com<br>032 338 333",
//     "HU-BO d.o.o.<br>Mail: hbprojekt@gmail.com<br>098 842 822",
//     "Autoškola Blue<br>Mail: askola.blue@gmail.com<br>097 7537 422",
//     "DMTeam j.d.o.o.<br>Mail: dmteam.os@gmail.com<br>097 6969 196",
//     "Kamp Kalebić<br>Mail: kamp.kalebic@gmail.com<br>092 2052 561",
//     "Euroauto d.o.o.<br>Mail: info@euroauto.hr<br>095 533 307",
//     "Villa Katarina <br>Mail: dskobic@gmail.com<br>091 3700 000",
//     "Polikilinka Sv. Ante<br>Mail: hrvoje.mihalj@gmail.com<br>098 712 135",
//     "Auto Reor d.o.o.<br>Mail: racunovodstvo@auto-reor.hr<br>031 297 230",
//     "DreamCar<br>Mail: dreamcar.os@hotmail.com<br>091 3242 411",
//     "Rulla j.d.o.o.<br>Mail: tomislav89gotovac@gmail.com<br>091 5025 358",
//     "AC Kotromanović<br>Mail: vedran@kotromanovic.hr<br>098 1309 704",
//     "Hemp Agro d.o.o.<br>Mail: gudelj.domagoj@gmail.com<br>091 1177 778",
//     "Extreme sport d.o.o.<br>Mail: drazen@extremesport.hr<br>091 5307 703",
//     "Cingi Lingi Carda d.o.o.<br>Mail: zeljko.boris.huber@gmail.com<br>099 3533 310",
//     "Castellum Art d.o.o. <br>Mail: hbprojekthr@gmail.com<br>031 212 064",
//     "KrisClinic Estetski Centar<br>Mail: kjuric75@gmail.com<br>098 9432 656",
//     "Merlinka d.o.o.<br>Mail: merlinka1@ka.ht.hr<br>098 1924 444",
//     "Alice in blockchains<br>Mail: info@aliceinblockchains.io<br>098 4535 00",
//     "La Dutti frizerski obrt<br>Mail: ana.zrinjevac@gmail.com<br>098 8352 11",
//     "MEDrOS medicina rada i sporta<br>Mail: medros@medros.hr<br>095 3100 011",
//     "Studio Fusion<br>Mail: diana.bulaku@gmail.com<br>099 3274 758",
//     "998 Garage d.o.o.<br>Mail: 998garage@gmail.com<br>091 6011 979",
//     "Pamida d.o.o.<br>Mail: mchordacar@gmail.com<br>095 8132 419",
//     "Solvermax j.d.o.o.<br>Mail: info@solvermax.hr<br>095 8665 493",
//     "Srebro d.o.o.<br>Mail: ivgrabic@gmail.com<br>098 423 775",
//     "Exoro d.p.<br>Mail: drazen@exoro.hr<br>091 3339 900",
//     "Konjik d.o.o. | Optika Oko<br>Mail: marija.limit@gmail.com<br>097 6091 655",
//     "Massa d.o.o.<br>Mail: ivan@massa.hr<br>091 5678 909",
//     "i-KONST<br>Mail: ivan@massa.hr<br>091 5678 909",
//   ];
  
//   const labels = [
//     "1",
//     "2",
//     "3",
//     "4",
//     "5",
//     "6",
//     "7",
//     "8",
//     "9",
//     "10",
//     "11",
//     "12",
//     "13",
//     "14",
//     "15",
//     "16",
//     "17",
//     "18",
//     "19",
//     "20",
//     "21",
//     "22",
//     "23",
//     "24",
//     "25",
//     "26",
//     "27",
//     "28",
//     "29",
//     "30",
//     "31",
//     "32",
//   ];

// const MapComponent = () => {
//   const [selectedPlace, setSelectedPlace] = useState(null);
//   const [infoLabel, setInfoLabel] = useState(null);

//   const mapRef = useRef(null);

//   const handleMarkerClick = (location, label) => {
//     setSelectedPlace(location);
//     setInfoLabel(label);
//   };

//   return (
//     <LoadScript googleMapsApiKey="AIzaSyBwDdWDzGNNhIu3SEZiJPSQ-EQrZXq_OWk">
//       <GoogleMap
//         id="map"
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={7}
//         ref={mapRef}
//       >
//         {locations.map((location, index) => (
//           <MarkerF
//             key={index}
//             position={location}
//             label={labels[index]}
//             // onClick={() => handleMarkerClick(location, fullName[index])}
//           />
//         ))}

//         {selectedPlace && (
//           <InfoWindow
//             position={selectedPlace}
//             onCloseClick={() => setSelectedPlace(null)}
//           >
//             <div dangerouslySetInnerHTML={{ __html: infoLabel }} />
//           </InfoWindow>
//         )}
//       </GoogleMap>
//     </LoadScript>
//   );
// };

// export default MapComponent;
