import React, { useContext, useEffect } from "react";

import login from "../images/blokbe/login.png";
import transfer from "../images/blokbe/transfer.png";
import walkthrough1 from "../images/blokbe/Walkthrough1.png";
import walkthrough2 from "../images/blokbe/Walkthrough2.png";
import walkthrough3 from "../images/blokbe/Walkthrough3.png";

import { en, hr } from "./Translation";
import { TranslationContext } from "./TranslationContext";

const BlokbeApp = () => {
  const { language, isFading } = useContext(TranslationContext);
  const texts = language === "hr" ? hr : en;

  useEffect(() => {
  });

  return (
    <>
      <section
        className={`bg-half-170 d-table w-100 overflow-hidden text-container ${
          isFading ? "fade-out" : ""
        }`}
        id="home"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-7">
              <div className="title-heading mt-4">
                <h1
                  className="heading mb-3 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".2s"
                >
                  {texts.appTitle1}{" "}
                  <span className="text-primary">{texts.appTitle11}</span>{" "}
                  {texts.appTitle111}{" "}
                  <span className="text-primary">{texts.appTitle1111}</span>
                </h1>
                <p
                  className="para-desc wow animate__animated animate__fadeInUp"
                  data-wow-delay=".3s"
                >
                  {texts.appTitle11111}
                </p>
                <div
                  className="mt-4 wow animate__animated animate__fadeIn"
                  data-wow-delay=".4s"
                >
                  <a
                    href="https://apps.apple.com/app/blok-be/id6444213022"
                    className="btn btn-primary mt-2 mr-2"
                  >
                    <i className="mdi mdi-apple" />
                    App Store
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.blokbe.app"
                    className="btn btn-outline-primary mt-2 bg-light"
                  >
                    <i className="mdi mdi-google-play" /> Play Store
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="classic-app-image position-relative">
                <div className="bg-app-shape position-relative">
                  <img
                    src={transfer}
                    className="img-fluid mobpic mx-auto d-block wow animate__animated animate__fadeInRight appic"
                    data-wow-delay=".3s"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-light">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section
        className={`section overflow-hidden bg-light text-container ${
          isFading ? "fade-out" : ""
        }`}
      >
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <h4 className="title mb-4 wow animate__animated animate__fadeInUp">
                  {texts.appTitle2}{" "}
                  <span className="text-primary font-weight-bold">
                    {texts.appTitle21}
                  </span>{" "}
                  {texts.appTitle22}
                  <span className="text-primary font-weight-bold">
                    {" "}
                    {texts.appTitle23}
                  </span>
                  .
                </h4>
                <p className="text-muted para-desc mb-0 mx-auto wow animate__animated animate__fadeInUp">
                  {texts.appTitle24}
                  <span className="text-primary font-weight-bold">
                    {" "}
                    BlokBe
                  </span>{" "}
                  {texts.appTitle25}
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
              <div className="section-title">
                <h1 className="title mb-3 wow animate__animated animate__fadeInUp">
                  {texts.homeAppTitle1}{" "}
                  <span className="text-primary">{texts.homeAppTitle12}</span>{" "}
                  {texts.homeAppTitle13}
                </h1>
                <p className="text-muted wow animate__animated animate__fadeInUp">
                  {texts.homeAppUnderTitle11}
                </p>
                <ul className="list-unstyled text-muted wow animate__animated animate__fadeInUp">
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    {texts.homeAppUnderTitle12}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    {texts.homeAppUnderTitle13}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    {texts.homeAppUnderTitle14}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 order-1 order-md-2">
              <div className="app-feature-shape-right position-relative">
                <div className="text-center text-md-end">
                  <img
                    src={login}
                    className="img-fluid mobpic wow animate__animated animate__fadeInRight appic"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-5">
              <div className="app-feature-shape-left position-relative">
                <div className="text-center text-md-start">
                  <img
                    src={walkthrough1}
                    className="img-fluid mobpic wow animate__animated animate__fadeInLeft appic"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-7 mt-5 mt-sm-0">
              <div className="section-title">
                <h1 className="title mb-3 wow animate__animated animate__fadeInUp">
                  <i className="mdi mdi-chevron-double-right text-primary" />
                  {texts.homeAppTitle2}
                </h1>
                <p className="para-desc text-muted wow animate__animated animate__fadeInUp">
                  {texts.homeAppUnderTitle21}
                </p>
                <ul className="list-unstyled text-muted wow animate__animated animate__fadeInUp">
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    <span className="text-primary font-weight-bold">
                      {texts.step} 1
                    </span>
                    : {texts.homeAppUnderTitle22}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    <span className="text-primary font-weight-bold">
                      {texts.step} 2
                    </span>
                    : {texts.homeAppUnderTitle23}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    <span className="text-primary font-weight-bold">
                      {texts.step} 3
                    </span>
                    : {texts.homeAppUnderTitle24}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
              <div className="section-title">
                <h1 className="title mb-3 wow animate__animated animate__fadeInUp">
                  <i className="mdi mdi-chevron-double-right text-primary" />
                  {texts.homeAppTitle3}
                </h1>
                <p className="para-desc text-muted wow animate__animated animate__fadeInUp">
                  {texts.homeAppUnderTitle31}
                </p>
                <ul className="list-unstyled text-muted wow animate__animated animate__fadeInUp">
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    <span className="text-primary font-weight-bold">
                      {texts.step} 1
                    </span>
                    : {texts.homeAppUnderTitle32}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    <span className="text-primary font-weight-bold">
                      {texts.step} 2
                    </span>
                    : {texts.homeAppUnderTitle33}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    <span className="text-primary font-weight-bold">
                      {texts.step} 3
                    </span>
                    : {texts.homeAppUnderTitle34}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 order-1 order-md-2">
              <div className="app-feature-shape-right position-relative">
                <div className="text-center text-md-end wow animate__animated animate__fadeInRight">
                  <img src={walkthrough2} className="img-fluid mobpic appic" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-5">
              <div className="app-feature-shape-left position-relative">
                <div className="text-center text-md-start wow animate__animated animate__fadeInLeft">
                  <img src={walkthrough3} className="img-fluid mobpic appic" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-7 mt-5 mt-sm-0">
              <div className="section-title">
                <h1 className="title mb-3 wow animate__animated animate__fadeInUp">
                  <i className="mdi mdi-chevron-double-right text-primary" />
                  {texts.homeAppTitle4}
                </h1>
                <p className="para-desc text-muted wow animate__animated animate__fadeInUp">
                  {texts.homeAppUnderTitle41}
                </p>
                <ul className="list-unstyled text-muted wow animate__animated animate__fadeInUp">
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    <span className="text-primary font-weight-bold">
                      {texts.step} 1
                    </span>
                    : {texts.homeAppUnderTitle42}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    <span className="text-primary font-weight-bold">
                      {texts.step} 2
                    </span>
                    : {texts.homeAppUnderTitle43}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-check-circle-outline" />
                    </span>
                    <span className="text-primary font-weight-bold">
                      {texts.step} 3
                    </span>
                    : {texts.homeAppUnderTitle44}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section
        className={`section pt-0 text-container ${isFading ? "fade-out" : ""}`}
      >
        <div className="container mb-5">
          <div className="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title">
                <h4 className="title mb-4 wow animate__animated animate__fadeInUp">
                  {texts.contactAllTitle1}
                </h4>
                <p className="text-muted para-desc mx-auto wow animate__animated animate__fadeInUp">
                  {texts.contactAllUnderTitle1} {texts.contactAllUnderTitle2}{" "}
                  <span className="text-primary font-weight-bold">BlokBe</span>.{" "}
                  <br /> {texts.homeAppTitle5}
                </p>
                <div className="mt-4 wow animate__animated animate__fadeInUp">
                  <a
                    href="https://apps.apple.com/app/blok-be/id6444213022"
                    className="btn btn-primary mt-2 mr-2"
                  >
                    <i className="mdi mdi-apple" /> App Store
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.blokbe.app"
                    className="btn btn-outline-primary mt-2"
                  >
                    <i className="mdi mdi-google-play" /> Play Store
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlokbeApp;
