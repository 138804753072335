import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";

import busi from "../images/busi01.jpg";

import { en, hr } from "./Translation";
import { TranslationContext } from "./TranslationContext";

const About = () => {
  const { language, isFading } = useContext(TranslationContext);
  const texts = language === "hr" ? hr : en;

  useEffect(() => {
    
  });

  return (
    <>
      <section className="bg-half bg-light d-table w-100">
        <div
          className={`container text-container ${isFading ? "fade-out" : ""}`}
        >
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <div className="page-next-level">
                <h4 className="title">{texts.aboutTitle1}</h4>
                <div className="page-next">
                  <nav aria-label="breadcrumb" className="d-inline-block">
                    <ul className="breadcrumb bg-white rounded shadow mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">{texts.navHome}</NavLink>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {texts.navAbout}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="position-relative">
                <img
                  src={busi}
                  className="rounded img-fluid mx-auto d-block wow animate__animated animate__fadeInUp"
                  alt=""
                />
              </div>
            </div>
            <div
              className={`col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0 text-container ${
                isFading ? "fade-out" : ""
              }`}
            >
              <div className="section-title ml-lg-4 ">
                <h4
                  className="title mb-4 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".2s"
                >
                  {texts.aboutTitle2}
                </h4>
                <p
                  className="text-muted wow animate__animated animate__fadeInUp"
                  data-wow-delay=".3s"
                >
                  <span className="text-primary font-weight-bold">
                    Blok Be d.o.o.
                  </span>{" "}
                  {texts.aboutText1}
                  <br />
                  <br />
                  {texts.aboutText2}
                  <br />
                  <br />
                  {texts.aboutText3}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white rotate-180 bg-light">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section className="section bg-light">
        <div
          className={`container text-container ${isFading ? "fade-out" : ""}`}
        >
          <div className="row">
            <div className="col-12 text-center">
              <div className="section-title pb-2 wow animate__animated animate__fadeInUp">
                <h4 className="title mb-4">{texts.aboutTitle3}</h4>
                <p className="text-muted para-desc mx-auto mb-0">
                  {texts.aboutText4}
                </p>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative mb-4">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default About;
