import React, { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";

import { en, hr } from "./Translation";
import { TranslationContext } from "./TranslationContext";

// Images
import cryptoexchangeIMG from "../images/NEW/cryptoexchange.png";

const BlokbeCryptoExchange = () => {
  const { language, isFading } = useContext(TranslationContext);
  const texts = language === "hr" ? hr : en;

  useEffect(() => {
  });

  return (
    <>
      <section className="bg-half bg-light">
        <svg
          className="svvg"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 100 100"
        >
          <path
            fill="#6bb2fc"
            className="out-top"
            d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z"
          />
          <path
            fill="#165DA7"
            className="in-top"
            d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z"
          />
          <path
            fill="#6bb2fc"
            className="out-bottom"
            d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z"
          />
          <path
            fill="#165DA7"
            className="in-bottom"
            d="M102,67.1c-9.6-6.1-22-3.1-29.5,2-15.4,10.7-19.6,37.5-7.6,47.8s35.9,3.9,44.5-12.5C115.5,92.6,113.9,74.6,102,67.1Z"
          />
        </svg>
        <div
          className={`container text-container ${isFading ? "fade-out" : ""}`}
        >
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="section-title">
                <div className="text-center">
                  <h4 className="title mb-4 wow animate__animated animate__fadeInUp">
                    {texts.cryptoExchangeTitle1}
                  </h4>
                  <img
                    src={cryptoexchangeIMG}
                    className="img-fluid rounded-md shadow-md wow animate__animated animate__fadeIn animate__slow"
                    alt=""
                  />
                </div>
                <p className="text-muted mb-0 mt-4 wow animate__animated animate__fadeInUp">
                  {texts.cryptoExchangeUnderTitle11}{" "}
                  <span className="text-primary font-weight-bold">
                    {texts.cryptoExchangeUnderTitle12}{" "}
                  </span>
                  {texts.cryptoExchangeUnderTitle13}
                </p>
                <h4
                  className="my-4 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.cryptoExchangeTitle2}
                </h4>
                <p
                  className="text-muted wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.cryptoExchangeUnderTitle21}
                  <span className="text-primary font-weight-bold">
                    {" "}
                    {texts.cryptoExchangeUnderTitle22}
                  </span>{" "}
                  {texts.cryptoExchangeUnderTitle23}
                </p>
                <p
                  className="text-muted wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.cryptoExchangeUnderTitle24}
                </p>
                <p
                  className="text-muted mb-0 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.cryptoExchangeUnderTitle25}
                  <br />
                  <NavLink to="/contact">
                    {texts.cryptoExchangeUnderTitle26}
                  </NavLink>{" "}
                  <span className="font-weight-bold">BlokBe</span>{" "}
                  {texts.cryptoExchangeUnderTitle27}
                </p>
                <h4
                  className="my-4 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.cryptoExchangeTitle3}
                </h4>
                <p
                  className="text-muted wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.cryptoExchangeUnderTitle31}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section className="section pt-0 mb-5">
        <div className="container">
          <div className="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
            <div className="col-12 text-center">
              <div
                className="section-title wow animate__animated animate__fadeInUp"
                data-wow-delay=".45s"
              >
                <h4
                  className={`title mb-2 text-container ${
                    isFading ? "fade-out" : ""
                  }`}
                >
                  {texts.contactAllTitle}
                </h4>
                <p
                  className={`text-muted para-desc mx-auto text-container ${
                    isFading ? "fade-out" : ""
                  }`}
                >
                  {texts.contactAllUnderTitle1} <br />{" "}
                  {texts.contactAllUnderTitle2}{" "}
                  <span className="text-primary font-weight-bold">BlokBe</span>.
                </p>
                <div className="mt-2">
                  <div className="btn btn-primary mt-2 mr-2">
                    <i className="mdi mdi-account-box" />
                    <NavLink
                      className={`text-white text-container ${
                        isFading ? "fade-out" : ""
                      }`}
                      to="/contact"
                    >
                      {texts.contactAllButton}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlokbeCryptoExchange;
