import React from "react";
import { useEffect, useContext } from "react";

import bg from "../images/contact-detail.jpg";

import { en, hr } from "./Translation";
import { TranslationContext } from "./TranslationContext";

const Contact = () => {
  const { language, isFading } = useContext(TranslationContext);
  const texts = language === "hr" ? hr : en;

  useEffect(() => {
  });

  return (
    <>
      {/* <div id="preloader">
                <div id="status">
                    <div class="spinner">
                        <div class="double-bounce1"></div>
                        <div class="double-bounce2"></div>
                    </div>
                </div>
            </div> */}

      <section
        className="d-flex align-items-center contact-bg min-vh-100"
        style={{
          background: `url('${bg}')`,
          backgroundSize: "cover",
        }}
      >
        <div className="bg-overlay bg-overlay-white" />
        <div className="container">
          <div className="row align-items-center justify-content-around">
            <div className="col-lg-5 col-md-5 pt-2 pt-sm-0 order-2 order-md-1">
              <div className=" rounded border-0">
                <div className="card-body py-5">
                  <div className="custom-form mt-3">
                    <form
                      action="https://formsubmit.co/info@blokbe.com"
                      method="POST"
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">
                              {texts.contactForm1}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <input
                                type="text"
                                name="Subject"
                                id="Subject"
                                className="form-control ps-5"
                                maxLength={50}
                                placeholder={texts.contactForm1}
                                required
                                autoFocus
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              {texts.contactForm2} Email{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <input
                                name="Email"
                                id="email"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Email:"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">
                              {texts.contactForm3}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <textarea
                                type="text"
                                name="Message"
                                id="Message"
                                rows={4}
                                className="form-control ps-5"
                                maxLength={500}
                                placeholder={texts.contactForm3}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              value={texts.contactButton}
                              data-wait="Please wait..."
                              className="btn btn-primary"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div
                className={`title-heading mt-5 pt-4 text-container ${
                  isFading ? "fade-out" : ""
                }`}
              >
                <h1 className="heading">{texts.contactTitle}</h1>
                <p className="text-dark">
                  {texts.contactUnderTitle1}{" "}
                  <span className="text-primary font-weight-bold">BlokBe</span>
                  {texts.contactUnderTitle2}
                </p>
                <div className="media contact-detail align-items-center mt-3">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-mail fea icon-m-md text-dark mr-3"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6" />
                    </svg>
                  </div>
                  <div className="media-body content">
                    <h5 className="title font-weight-bold mb-0">Email</h5>
                    <p className="text-primary">info@blokbe.com</p>
                  </div>
                </div>
                <div className="media contact-detail align-items-center mt-3">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-map-pin fea icon-m-md text-dark mr-3"
                    >
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                      <circle cx={12} cy={10} r={3} />
                    </svg>
                  </div>
                  <div className="media-body content">
                    <h5 className="title font-weight-bold mb-0">
                      {texts.contactLocation}
                    </h5>
                    <a
                      href="https://goo.gl/maps/v3sh136Xzb7z4QZUA"
                      className="video-play-icon text-primary"
                    >
                      Kapucinska 31, 31 000 Osijek
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
