import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { en, hr } from "./Translation";
import { TranslationContext } from './TranslationContext';

const Privacy = () => {
  const { language, isFading } = useContext(TranslationContext);
  const texts = language === 'hr' ? hr : en;

  useEffect(() => {
  });

  return (
    <>
      <section className="bg-half bg-light d-table w-100">
        <div className={`container text-container ${isFading ? 'fade-out' : ''}`}>
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <div className="page-next-level">
                <h4 className="title">{texts.privacyTitle1}</h4>
                <ul className="list-unstyled mt-4">
                  <li className="list-inline-item h6 date text-muted"> <span className="text-dark">{texts.privacyDate1}</span> {texts.privacyDate2}</li>
                </ul>
                <div className="page-next">
                  <nav aria-label="breadcrumb" className="d-inline-block">
                    <ul className="breadcrumb bg-white rounded shadow mb-0">
                      <li className="breadcrumb-item"><NavLink to="/">{texts.privacyLocation1}</NavLink></li>
                      <li className="breadcrumb-item active" aria-current="page">{texts.privacyLocation2}</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
          </svg>
        </div>
      </div>
      <section className={`section text-container ${isFading ? 'fade-out' : ''}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 text-justify">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h5 className="card-title">{texts.privacyTitle2}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle21}
                  </p>
                  <p className="text-muted">{texts.privacyUnderTitle22}</p>
                  <p className="text-muted">{texts.privacyUnderTitle23}
                  </p>
                  <p className="text-muted">{texts.privacyUnderTitle24}</p>
                  <p className="text-muted">{texts.privacyUnderTitle25}</p>
                  <p className="text-muted">{texts.privacyUnderTitle26}</p>
                  <p className="text-muted">{texts.privacyUnderTitle27}
                  </p>
                  <h5 className="card-title">{texts.privacyTitle3}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle31}</p>
                  <p className="text-muted">{texts.privacyUnderTitle32}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle33}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle34}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle35}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle36}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle37}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle38}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle39}</li>
                  </ul>
                  <br />
                  <p className="text-muted">{texts.privacyUnderTitle310}</p>
                  <p className="text-muted">{texts.privacyUnderTitle311}
                  </p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle312}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle313}</li>
                  </ul>
                  <br />
                  <p className="text-muted">{texts.privacyUnderTitle314}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle315}
                    </li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle316}
                    </li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle317}</li>
                  </ul>
                  <br />
                  <p className="text-muted">{texts.privacyUnderTitle318}</p>
                  <p className="text-muted">{texts.privacyUnderTitle319}</p>
                  <h5 className="card-title">{texts.privacyTitle4}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle41}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle42}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle43}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle44}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle45}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle46}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle47}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle48}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle49}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle410}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle411}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle412}</li>
                  </ul>
                  <br />
                  <h5 className="card-title">{texts.privacyTitle5}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle51}</p>
                  <p className="text-muted font-weight-bold">{texts.privacyUnderTitle52}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle53}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle54}</li>
                  </ul>
                  <br />
                  <p className="text-muted font-weight-bold">{texts.privacyUnderTitle55}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle56}</li>
                  </ul>
                  <br />
                  <p className="text-muted font-weight-bold">{texts.privacyUnderTitle57}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle58}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle59}</li>
                  </ul>
                  <br />
                  <p className="text-muted font-weight-bold">{texts.privacyUnderTitle510}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle511}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle512}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle513}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle514}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle515}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle516}</li>
                  </ul>
                  <br />
                  <p className="text-muted font-weight-bold">{texts.privacyUnderTitle517}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle518}</li>
                  </ul>
                  <br />
                  <p className="text-muted">{texts.privacyUnderTitle519}</p>
                  {/* <p class="text-muted">Substantial public interest</p>
                      <ul class="list-unstyled text-muted">
                          <li><i data-feather="arrow-right" class="fea icon-sm mr-2"></i>Where we process your
                              sensitive Personal Data (sometimes known as special category personal data) to
                              adhere to government regulations or guidance, such as our obligation to support you
                              if you are or become a vulnerable customer.</li>
                      </ul>

                      <p class="text-muted">Marketing purposes</p>
                      <ul class="list-unstyled text-muted">
                          <li><i data-feather="arrow-right" class="fea icon-sm mr-2"></i>We may use your personal
                              information to send you marketing communications by email or other agreed forms
                              (including social media campaigns), to ensure you are always kept up-to-date with
                              our latest products and services. If we send you marketing communications we will do
                              so based on your consent and registered marketing preferences.</li>
                      </ul>

                      <p class="text-muted">Legal Notifications</p>
                      <ul class="list-unstyled text-muted">
                          <li><i data-feather="arrow-right" class="fea icon-sm mr-2"></i>Often the law requires us
                              to advise you of certain changes to products or services or laws. We may need to
                              inform you of changes to the terms or the features of our products or services. We
                              need to process your personal information to send you these legal notifications. You
                              will continue to receive this information from us even if you choose not to receive
                              direct marketing information from us.</li>
                      </ul> */}
                  <h5 className="card-title">{texts.privacyTitle6}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle61}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle62} </li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle63}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle64}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle65}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle66}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle67}
                    </li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle68}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle69}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle610}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle611}</li>
                  </ul>
                  <br />
                  <p className="text-muted">{texts.privacyUnderTitle612}</p>
                  <p className="text-muted">{texts.privacyUnderTitle613}</p>

                  <h5 className="card-title">{texts.privacyTitle7}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle71}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle72}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle73}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle74}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle75}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle76}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle77}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle78}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle79}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle710}</li>
                  </ul>
                  <br />
                  <p className="text-muted">{texts.privacyUnderTitle711}</p>
                  <h5 className="card-title">{texts.privacyTitle8}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle81}
                  </p>
                  <p className="text-muted">{texts.privacyUnderTitle82}</p>
                  <h5 className="card-title">{texts.privacyTitle9}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle91}</p>
                  <p className="text-muted">{texts.privacyUnderTitle92}</p>
                  <p className="text-muted">{texts.privacyUnderTitle93}</p>
                  <p className="text-muted">{texts.privacyUnderTitle94}</p>
                  <p className="text-muted">{texts.privacyUnderTitle95}</p>
                  <h5 className="card-title">{texts.privacyTitle10}</h5>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle101}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle102}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle103}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle104}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle105}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle106}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle107}
                    </li>
                  </ul>
                  <h5 className="card-title">{texts.privacyTitle11}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle111}</p>
                  <p className="text-muted">{texts.privacyUnderTitle112}</p>
                  <ul className="list-unstyled text-muted">
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle113}</li>
                    <li><i data-feather="arrow-right" className="fea icon-sm mr-2 erou" />{texts.privacyUnderTitle114}</li>
                  </ul>
                  <br />
                  <p className="text-muted">{texts.privacyUnderTitle115}</p>
                  <h5 className="card-title">{texts.privacyTitle12}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle121}</p>
                  <h5 className="card-title">{texts.privacyTitle13}</h5>
                  <p className="text-muted">{texts.privacyUnderTitle131}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
