export const en = {
    // NAVBAR
    navHome: "HOME",
    navServices: "SERVICES",
    navExchange: "BLOKBE CRYPTO EXCHANGE",
    navPayment: "BLOKBE CRYPTO PAYMENT",
    navApp: "BLOKBE APP",
    navAtm: "ATM",
    navEducation: "EDUCATION",
    navAbout: "ABOUT US",
    navPrivacyAndTerms: "PRIVACY POLICY AND TERMS",
    navPrivacy: "PRIVACY POLICY",
    navTerms: "TERMS OF SERVICE",
    navContact: "CONTACT",

    // CONTACT END
    contactAllTitle: "Contact Us !",
    contactAllTitle1: "Check it out !",
    contactAllUnderTitle1: "Start your crypto journey",
    contactAllUnderTitle2: "with",
    contactAllButton: "CONTACT",

    // FOOTER
    footerText1: "A fin-tech company with a vision to offer safe and enjoyable experience with cryptocurrencies.",
    footerText2: "Regulatory",
    footerText3: "CROATIAN FINANCIAL SERVICES SUPERVISORY AGENCY",
    footerText4: "Created with <3 in Croatia.",

    // LANDING
    homeTitle1: "BLOKBE EXCHANGE",
    homeTitle2: "BLOKBE PAYMENT",
    homeTitle3: "BLOKBE APP",
    homeTitle4: "ATM",
    homeUnderTitle1: "Buy or sell cryptocurrencies.",
    homeUnderTitle11: "Your trusted and secure BlokBe Crypto Exchange",
    homeUnderTitle2: "Introduce payment in cryptocurrencies to your business",
    homeUnderTitle22: "Completely free",
    homeUnderTitle3: "Buy and spend crypto.",
    homeUnderTitle33: "Safe and Simple.",
    homeUnderTitle4: "Coming soon...",
    homeButtonTitle1: "LEARN MORE",
    homeButtonTitle2: "LEARN MORE",
    homeButtonTitle3: "LEARN MORE",
    homeButtonTitle4: "CONTACT US",
    sliderTitle1: "EXCHANGE",
    sliderTitle2: "PAYMENT",
    sliderTitle3: "BLOK BE APP",
    sliderTitle4: "BITCOIN ATM",

    // LANDING PARTNERS
    landingPartnersTitle: "Our partners",
    landingPartnersUnderTitle: "Embracing the power of synergy, our network of partners amplifies our ability to unlock the full potential of crypto.",

    // HOME SERVICES LIST
    homeServicesTitle: "Briefly about our services",
    homeServicesUnderTitle1: "With cutting edge technology and methodology,",
    homeServicesUnderTitle2: "can help you to get started with cryptocurrencies.",

    homeServiceTitle1: "Crypto Exchange",
    homeServiceTitle2: "Crypto Payment",
    homeServiceTitle3: "BlokBe App",
    homeServiceTitle4: "Bitcoin ATM",
    homeServiceUnderTitle41: "Coming soon...",

    homeServiceUnderTitle1: "BlokBe crypto exchange was created with the aim of enabling users to quickly and safely buy, sell and/or exchange cryptocurrencies.",
    homeServiceUnderTitle2: "BlokBe crypto payment is an electronic payment service for processing payments in cryptocurrencies, intended for various retail, wholesale traders and sales services that make payments physically and/or through online stores, which will enable its customers to pay in cryptocurrencies, whereby they will receive EURO on their business account.",
    homeServiceUnderTitle21: "is an electronic payment service for processing payments in cryptocurrencies, intended for various retail, wholesale traders and sales services that make payments physically and/or through online stores, which will enable its customers to pay in cryptocurrencies, whereby they will receive EURO on their business account.",

    homeServiceUnderTitle22: "Introduce BlokBe crypto payment completely free of charge.",
    homeServiceUnderTitle3: "With BlokBe app you can safely enjoy buying and spending cryptocurrencies at specialized, trusted online merchants.",
    homeServiceUnderTitle4: "",
    homeService1Subtitle1: "General",
    homeService1Subtitle2: "Details",
    homeService1UnderSubtitle1: "BlokBe crypto exchange supports over 200 different cryptocurrencies that you can buy, sell and exchange. The exchange provides a unique individual approach to each user. We also provide educational and professional assistance to all our users.",
    homeService1UnderSubtitle11: "If you own cryptocurrencies and want to exchange them for FIAT? If you would like to invest in cryptocurrencies? Do it through the BlokBe exchange - fast, simple and safe.",
    homeService1UnderSubtitle111: "Do you want to invest in cryptocurrencies?",
    homeService1UnderSubtitle1111: "Contact",
    homeService1UnderSubtitle11111: "BlokBe and we will do the whole process for you.",
    homeService1UnderSubtitle2: "The BlokBe exchange is regulated by HANFA (CROATIAN FINANCIAL SERVICES SUPERVISORY AGENCY), and therefore we verify all our users, and we make payments and withdrawals exclusively through a bank account.",


    // CRYPTO PAYMENT
    homeCryptoPaymentTitle1: "General",
    homeCryptoPaymentUnderTitle1: "BlokBe crypto payment gateway",
    homeCryptoPaymentUnderTitle11: "(CPG)",
    homeCryptoPaymentUnderTitle12: "is designed to be",
    homeCryptoPaymentUnderTitle13: "very easy to use",
    homeCryptoPaymentUnderTitle14: ", both for the merchant and the end customer. You don't necessarily need to know and deal with cryptocurrencies to provide your customers with this cutting-edge payment method. BlokBe will do everything for you,",
    homeCryptoPaymentUnderTitle15: "completely free of charge",
    homeCryptoPaymentUnderTitle16: ", your reliable partner and intermediary for the use of cryptocurrencies in business.",
    homeCryptoPaymentUnderTitle17: "CPG Login",

    homeCryptoPaymentTitle2: "How to become a BlokBe partner",
    homeCryptoPaymentUnderTitle21: "Take advantage of the opportunity, become an exclusive BlokBe partner and introduce cryptocurrency payments to your business. Pay for a product or service in cryptocurrencies - at online stores and physical locations. You receive payments in cryptocurrencies, and FIAT are charged to your account.",
    homeCryptoPaymentUnderTitle211: "Take advantage of the opportunity, become an",
    homeCryptoPaymentUnderTitle212: "exclusive BlokBe partner",
    homeCryptoPaymentUnderTitle213: "and introduce cryptocurrency payments to your business. Pay for a product or service in cryptocurrencies - at online stores and physical locations. You receive payments in cryptocurrencies, and FIAT are charged to your account.",

    homeCryptoPaymentUnderTitle22: "Step 1.  Download form",
    homeCryptoPaymentUnderTitle23: "Step 2. Fill out form",
    homeCryptoPaymentUnderTitle24: "Step 3. Send form to",
    homeCryptoPaymentUnderTitle25: "treasury@blokbe.com",
    homeCryptoPaymentUnderTitle26: "DOWNLOAD FORM",
    homeCryptoPaymentUnderTitle27: "Get in touch and arrange an initial meeting with the BlokBe company, where will our expert associates explain in detail the way of functioning, implementation and of using our software. We are also available for all your questions.",

    homeCryptoPaymentTitle3: "Benefits",
    homeCryptoPaymentUnderTitle31: "Increasingly, tech-savvy consumers are using cryptocurrencies to pay for products and services around the world. Speed, flexibility and diversification of payment options are important to such consumers. The world has become a fast-paced place, where consumers no longer have time to wait in lines, exchange foreign currency for local national currencies, lose money on unfavorable exchange rates, waste time converting values from one currency to another and carry large amounts of cash when going on vacations, trips and similar.",
    homeCryptoPaymentUnderTitle32: "Now over 300 million consumers use cryptocurrencies as a means of payment for a wide range of products and services. Follow global financial technology trends and become one of the first merchants in Croatia to accept cryptocurrencies as a means of payment. Be recognized as a technologically aware company that sets contemporary trends in financial business.",
    homeCryptoPaymentUnderTitle321: "Now",
    homeCryptoPaymentUnderTitle322: "over 300 million consumers",
    homeCryptoPaymentUnderTitle323: "use cryptocurrencies as a means of payment for a wide range of products and services. Follow global financial technology trends and become one of the first merchants in Croatia to accept cryptocurrencies as a means of payment. Be recognized as a technologically aware company that sets contemporary trends in financial business.",

    homeCryptoPaymentTitle4: "BlokBe Payment Benefits",
    homeCryptoPaymentUnderTitle41: "Modernization",
    homeCryptoPaymentUnderTitle411: "Modernization of business by introducing the most up-to-date method of payment.",
    homeCryptoPaymentUnderTitle42: "Innovation",
    homeCryptoPaymentUnderTitle422: "Opening the market for a completely new, technologically aware generation of consumers.",
    homeCryptoPaymentUnderTitle43: "Adoption",
    homeCryptoPaymentUnderTitle433: "You become one of the first merchants to introduce a modern payment method with cryptocurrencies and gain a competitive advantage.",
    homeCryptoPaymentUnderTitle44: "Marketing",
    homeCryptoPaymentUnderTitle444: "Free Marketing by BlokBe - Your Cryptocurrency Partner.",
    homeCryptoPaymentUnderTitle45: "Efficiency",
    homeCryptoPaymentUnderTitle455: "Super fast and easy transactions.",
    homeCryptoPaymentUnderTitle46: "Savings",
    homeCryptoPaymentUnderTitle466: "Introduction of BlokBe payment gateway is free and has no fees or additional costs.",
    homeCryptoPaymentUnderTitle47: "Maximum flexibility",
    homeCryptoPaymentUnderTitle477: "You choose the dynamics of payment of funds to your business account.",
    homeCryptoPaymentUnderTitle48: "Professional and fast support",
    homeCryptoPaymentUnderTitle488: "We provide all our partners with professional and fast support 24/7.",
    homeCryptoPaymentUnderTitle49: "High level of security",
    homeCryptoPaymentUnderTitle499: "BlokBe is fully regulated by the Croatian Financial Services Supervisory Agency - HANFA.",

    homeCryptoPaymentTitle5: "BlokBe Partners",

    homeCryptoPaymentTitle6: "Process Diagram",
    homeCryptoPaymentTitle61: "The user requires payment for the service in crypto.",
    homeCryptoPaymentTitle62: "The cashier selects which cryptocurrency to pay with.",
    homeCryptoPaymentTitle63: "After selecting and entering the price of the service, the employee chooses to print the QR code.",
    homeCryptoPaymentTitle64: "The user scans the QR code and pays for the service through his mobile crypto wallet.",
    homeCryptoPaymentTitle65: "The employee instantly receives the confirmation of the transaction and provides the service to the user.",
    homeCryptoPaymentTitle66: "BlokBe automatically performs the exchange of cryptocurrency in EUR.",
    homeCryptoPaymentTitle67: "BlokBe executes the payment according to the agreed terms in EUR.",

    // BLOKBE APP
    appTitle1: "Buy and spend crypto.",
    appTitle11: "Safe",
    appTitle111: "and",
    appTitle1111: "Simple.",
    appTitle11111: "With BlokBe app you can safely enjoy buying and spending cryptocurrencies at specialized, trusted online merchants.",
    appTitle2: "We demand",
    appTitle21: "Privacy",
    appTitle22: "and",
    appTitle23: "Security",
    appTitle24: "Without security we cannot talk about privacy.",
    appTitle25: "mobile apps implements OWASP security standard.",


    homeAppTitle1: "Here at",
    homeAppTitle12: "BlokBe",
    homeAppTitle13: "security is not a product but a process.",
    homeAppUnderTitle11: "Our procedures are handcrafted with the slightest details. We use cutting edge technologies to make sure your assets are always safe.",
    homeAppUnderTitle12: "Scrambled keypad design prevents onlookers from detecting the PIN",
    homeAppUnderTitle13: "Native biometric authentication in mobile banking procedures",
    homeAppUnderTitle14: "We do not store any of sensitive credit or debit card data. Your device, your storage.",

    homeAppTitle2: "Non-custodial wallet",
    step: "STEP",
    homeAppUnderTitle21: "BlokBe let's you to be your own bank. This implies that you have full control over your funds.",
    homeAppUnderTitle22: "Create your wallet",
    homeAppUnderTitle23: "Add it to BlokBe App",
    homeAppUnderTitle24: "Start banking.",

    homeAppTitle3: "Fast transfer",
    homeAppUnderTitle31: "In few clicks transfer assets to our trusted merchants or directly into your wallet.",
    homeAppUnderTitle32: "Payment method",
    homeAppUnderTitle33: "Merchant or wallet",
    homeAppUnderTitle34: "Amount. Done.",

    homeAppTitle4: "Verified partners program",
    homeAppUnderTitle41: "BlokBe verified partners program provides most reliable and secure merchant network to work with trust.",
    homeAppUnderTitle42: "Setup merchant reference",
    homeAppUnderTitle43: "Choose offered packages",
    homeAppUnderTitle44: "Start banking.",

    homeAppTitle5: "Get the app on",


    // BLOKBE CRYPTO EXCHANGE
    cryptoExchangeTitle1: "BlokBe Crypto Exchange",
    cryptoExchangeUnderTitle11: "BlokBe crypto exchange was created with the aim of enabling users to",
    cryptoExchangeUnderTitle12: "quickly and safely",
    cryptoExchangeUnderTitle13: "buy, sell and/or exchange cryptocurrencies.",

    cryptoExchangeTitle2: "General",
    cryptoExchangeUnderTitle21: "BlokBe crypto exchange supports",
    cryptoExchangeUnderTitle22: "over 200",
    cryptoExchangeUnderTitle23: "different cryptocurrencies that you can buy, sell and exchange. The exchange provides a unique individual approach to each user. We also provide educational and professional assistance to all our users.",
    cryptoExchangeUnderTitle24: "If you own cryptocurrencies and want to exchange them for FIAT? If you would like to invest in cryptocurrencies? Do it through the BlokBe exchange - fast, simple and safe.",
    cryptoExchangeUnderTitle25: "Do you want to invest in cryptocurrencies?",
    cryptoExchangeUnderTitle26: "Contact",
    cryptoExchangeUnderTitle27: "and we will do the whole process for you.",

    cryptoExchangeTitle3: "Details",
    cryptoExchangeUnderTitle31: "The BlokBe exchange is regulated by HANFA (CROATIAN FINANCIAL SERVICES SUPERVISORY AGENCY), and therefore we verify all our users, and we make payments and withdrawals exclusively through a bank account.",


    //  CONTACT PAGE
    contactTitle: "Let's talk about your questions",
    contactUnderTitle1: "Start working with",
    contactUnderTitle2: " that can provide everything you need to accept crypto as a merchant or to start using them in everyday life.",
    contactLocation: "Location",
    contactForm1: "Subject",
    contactForm2: "Your",
    contactForm3: "Message",
    contactButton: "Send",

    // ATM PAGE
    atmTitle1: "ATM",
    atmTitle2: "Coming soon...",

    // EDUCATION PAGE
    educationMainTitle: "Education",
    educationUnderMainTitle1: "Whether you're new to the world of cryptocurrency or looking to",
    educationUnderMainTitle2: "deepen your knowledge",
    educationUnderMainTitle3: ", our expert instructors provide valuable insights and hands-on experience to help you navigate the complex landscape of the digital asset industry.",
    educationUnderMainTitle4: "Our courses cover a wide range of topics, including",
    educationUnderMainTitle5: "blockchain technology, cryptocurrencies, wallets, exchanges, and much more",
    educationUnderMainTitle6: "With our education resources, you'll gain a solid understanding of the fundamentals of blockchain and cryptocurrency, as well as",
    educationUnderMainTitle7: "practical tips and strategies",
    educationUnderMainTitle8: "for maximizing your investments. Explore our courses today and start your journey towards becoming a cryptocurrency and blockchain expert!",
    educationUnderMainTitle9: "we provide lessons to both private individuals and business users",
    educationUnderMainTitle10: "improve your business by investing in the education of your employees and introducing a crypto payment system for your end users",
    
    educationTitle1: "Blockchain technology",
    educationUnderTitle11: "What is Blockchain?",
    educationUnderTitle12: "Proof of Work; mining, mining farms",
    educationUnderTitle13: "Proof of Stake",
    educationUnderTitle14: "Soft and hard fork",

    educationTitle2: "Types of cryptocurrencies",
    educationUnderTitle21: "What are cryptocurrencies?",
    educationUnderTitle22: "Bitcoin",
    educationUnderTitle23: "Altcoins, Stablecoins & Privacy coins",
    educationUnderTitle24: "NFT",
    educationUnderTitle25: "DeFi",

    educationTitle3: "Cryptocurrency Exchanges",
    educationUnderTitle31: "Type of exchange providers",
    educationUnderTitle32: "Account verification",
    educationUnderTitle33: "Trading & Margin trade",
    educationUnderTitle34: "Exchange costs",
    educationUnderTitle35: "Staking & Lending",

    educationTitle4: "Wallets",
    educationUnderTitle41: "What is a crypto wallet and how does it work?",
    educationUnderTitle42: "Difference between private and public key",
    educationUnderTitle43: "Types of wallets",
    educationUnderTitle44: "(web, mobile, paper, desktop, hardware...)",

    // ABOUT PAGE
    aboutTitle1: "About us",
    aboutTitle2: "Our Story",
    aboutText1: "is a fin-tech company and our vision is to enable our customers to safely include cryptocurrencies into their everyday lives and business.",
    aboutText2: "We took the best from both worlds, finance and technology, to offer a simple and safe crypto exchange, a crypto payment gateway, and education about cryptocurrency. We also provide a user-friendly mobile app to easily buy and spend crypto.",
    aboutText3: "We are an experienced team of finance and cryptocurrency enthusiasts who strive to make the world of digital currencies accessible to everyone.",
    aboutTitle3: "Our Greatest Minds",
    aboutText4: "To ensure the best solutions and innovative products, we established a team that is a perfect mixture of young talent and experienced professionals.",

    // PRIVACY POLICY PAGE
    privacyTitle1: "Privacy Policy",
    privacyDate1: "Last Revised :",
    privacyDate2: "31th Mar, 2023",
    privacyLocation1: "HOME",
    privacyLocation2: "PRIVACY POLICY",

    privacyTitle2: "Introduction",
    privacyUnderTitle21: "BLOK BE d.o.o., Kapucinska Ulica 31, Osijek, OIB: 98712426804 (hereinafter: 'BLOK BE') is a data controller according to this Privacy Policy. BLOK BE runs a digital asset exchange platform for purchases of stablecoins. The terms “we,” “us,” and “our” refer to BLOK BE and its affiliates. We are committed to protecting and respecting your (“you”, “your”, “customer”) privacy, as well as to safeguarding the data that our website visitors and service users share with us.",
    privacyUnderTitle22: "This Privacy policy sets how we collect, use and manage the personal information we receive from you, or a third party, in connection with our provision of services to you or which we collect from your use of our services ('Services') and/or our website (the 'Site').",
    privacyUnderTitle23: "By accessing and using our Service, you are accepting and consenting to the practices described in our Privacy Policy. Please read the following carefully. If you don’t agree with this Privacy Policy, you should not use our Site and Services.",
    privacyUnderTitle24: "We will also ask you to agree to our use of cookies in accordance with our Cookie Policy when you first visit our website. For more information about cookies, the types of cookies we use and how we use them please see our Cookie Policy.",
    privacyUnderTitle25: "We have incorporated firm security measures to ensure the protection of your personal data (“Personal Data”) against unauthorized or unlawful processing and use, and against accidental loss, destruction, damage, theft or disclosure.",
    privacyUnderTitle26: "Every update of our Privacy Policy will be communicated to you and published on our website.",
    privacyUnderTitle27: "If you have any questions about our Privacy Policy as outlined above, please contact us. (contact details are listed below in the 'Contact Us' section).",

    privacyTitle3: "Data collection and use",
    privacyUnderTitle31: "We collect the Personal Data which you provide directly to us or which we generate when you open a BLOK BE Account, perform any transactions on our Platform, or use other BLOK BE Services or our website.",
    privacyUnderTitle32: "The data you provide us are by registering for a BLOK BE account, using our support on the Site or corresponding with us by phone, email or any other medium. This may include:",
    privacyUnderTitle33: "Contact information, such as full name, home address and email address, date and place of birth, mobile phone number;",
    privacyUnderTitle34: "Account information, such as username, password, account settings and preferences;",
    privacyUnderTitle35: "Financial information, such as bank account numbers, bank statement and trading information;",
    privacyUnderTitle36: "Identity verification information, such as images of your government-issued ID, passport, national ID card or driving licence;",
    privacyUnderTitle37: "Residence verification information, such as utility bill details or similar information;",
    privacyUnderTitle38: "Information regarding the way in which you use our Services, such as when you used our Services, and the specific Services used;",
    privacyUnderTitle39: "Information relating to communications with us, whether through our website or via email, over the phone or via any other medium.",
    privacyUnderTitle310: "Furthermore, we may collect and process Personal Data that you voluntarily and with your consent give to BLOK BE in our website’s forms, such as when you sign up for information and newsletters. You can unsubscribe from the newsletter by opening one of BLOK BE emails, which you received, and clicking “unsubscribe” at the bottom of the page.",
    privacyUnderTitle311: "Each time you use our Services or access our website, we may automatically collect information which will help us with website analytics and improvement of user experience as well as our services. This information may include:",
    privacyUnderTitle312: "Computer or mobile device information, including IP address, operating system, network system, browser type and settings;",
    privacyUnderTitle313: "Website usage information.",
    privacyUnderTitle314: "Some Personal Data may be collected from third parties who provide services to you or us, credit reference, fraud prevention, government agencies, and other financial institutions (where permitted by law), such as:",
    privacyUnderTitle315: "Reputational information;",
    privacyUnderTitle316: "Financial information;",
    privacyUnderTitle317: "Business activities of corporate customers.",
    privacyUnderTitle318: "Our Services may also include integrated content or links to content provided by third parties (such as live chat, social media content, plug-ins and applications). Additional third parties may include our affiliated entities.",
    privacyUnderTitle319: "For specific and limited purposes, such as detecting and preventing financial crime or to make our services accessible to customers, we may also process certain special data categories. We will only process them where we’ve obtained your explicit consent or are otherwise lawfully permitted to do so. This may include biometric information, relating to the physiological, physical, or behavioural characteristics of a person, including facial or fingerprint recognition or similar technologies for money laundering and fraud prevention.",

    privacyTitle4: "Purpose of processing Personal Data",
    privacyUnderTitle41: "The information we collect and use are for the following purposes below:",
    privacyUnderTitle42: "Verification of your identity in accordance with applicable know-your-customer, money-laundering and other financial sector legislation or regulations;",
    privacyUnderTitle43: "Communication and establishment of a responsible commercial relationship with you;",
    privacyUnderTitle44: "Customized content by understanding your needs and your eligibility for products and services;",
    privacyUnderTitle45: "Information to you about new products and services;",
    privacyUnderTitle46: "Information to you about changes and enhancements to our Site;",
    privacyUnderTitle47: "Showing ads on other websites to you",
    privacyUnderTitle48: "Processing billing and collection of any fees;",
    privacyUnderTitle49: "Delivery of products and services to you;",
    privacyUnderTitle410: "Survey conduct and get feedback from you;",
    privacyUnderTitle411: "Monitor and improvement of our Site;",
    privacyUnderTitle412: "Compliance with our legal or regulatory obligations",

    privacyTitle5: "Legal bases for processing your Personal Data",
    privacyUnderTitle51: "Legal basis for processing your Personal Data may be one of the following:",
    privacyUnderTitle52: "Keeping to our contracts and agreements with you",
    privacyUnderTitle53: "Due to the contractual relationship with our clients, we process personal data for providing our services and products and relevant information, as well as in relation to on-boarding processes.",
    privacyUnderTitle54: "We are often required by law to notify you of certain product changes or service or law. We may need to notify you of changes to the Terms or features of our products or services. We need to process your personal data and how we would send you these legal notices. You will continue to receive this information from us even if you choose not to receive direct marketing information from us.",
    privacyUnderTitle55: "Legal obligations",
    privacyUnderTitle56: "We are subject to a number of legal obligations imposed by relevant laws due to which we have to collect and store your Personal Data (we must hold certain information about our customers due to anti-money laundering laws) for identity verification, compliance with court orders, payment processing, tax laws or other reporting obligations and anti-money laundering controls.",
    privacyUnderTitle57: "Legal obligation to comply with “Know Your Customer” and customer due diligence regulatory obligations",
    privacyUnderTitle58: "To verify your identity in relation to accepting you as our client, we may include third parties carrying out credit or identity checks on our behalf.",
    privacyUnderTitle59: "Where we process your sensitive personal data (sometimes known as a special category of personal data) to comply with government regulations or guidance, such as our commitment to support you if you are or become a vulnerable customer.",
    privacyUnderTitle510: "Legitimate interest",
    privacyUnderTitle511: "For monitoring and improving our website and services, we may collect and use your Personal Data, pursued by us or by a third party. There are various commercial or business reasons to use your Personal Data, such as system security, crime prevention, assets security, development of products and services, aml or risk management.",
    privacyUnderTitle512: "We may need to use Personal Data collected from you to investigate issues or to settle disputes with you because it is our legitimate interest to ensure that issues and disputes get investigated and resolved in a timely and efficient manner.",
    privacyUnderTitle513: "We may need to use your Personal Data to comply with any applicable laws and regulations, subpoenas, court orders or other judicial processes, or requirements of any applicable regulatory authority. We do this not only to comply with our legal obligations but because it may also be in our legitimate interest to do so.",
    privacyUnderTitle514: "Our website pages and emails may contain web beacons or pixel tags or any other similar types of data analysis tools that allow us to track receipt of correspondence and count the number of users that have visited our webpage or opened our correspondence. We may aggregate your personal information with the personal information of our other clients on an anonymous basis (that is, with your personal identifiers removed), so that more rigorous statistical analysis of general patterns may lead us to providing better products and services. If your personal information is completely anonymised, we do not require a legal basis as the information will no longer constitute personal information. If your personal information is not in an anonymised form, it is in our legitimate interest to continually evaluate that personal information to ensure that the products and services we provide are relevant to the market.",
    privacyUnderTitle515: "We may need to process your personal information for internal business and research purposes as well as for record keeping purposes. Such processing is in our own legitimate interests and is required in order to comply with our legal obligations. This may include any communications that we have with you in relation to the products and services we provide to you and our relationship with you. We will also keep records to ensure that you comply with your contractual obligations pursuant to the agreement (‘Terms of Service”) governing our relationship with you.",
    privacyUnderTitle516: "We may use your personal information to send you marketing communications by e-mail or other agreed forms (including campaigns on social networks), to ensure that you are always up to date with our latest products and services. If we send you marketing communications, we will do this based on your consent and registered marketing preferences.",
    privacyUnderTitle517: "Consent",
    privacyUnderTitle518: "When you agree for us to collect your Personal Data. Generally, we do not rely on consent as a legal basis for processing your Personal Data other than in relation to our use of cookies or when we send third party direct marketing communications to you via email or text message.",
    privacyUnderTitle519: "Regarding the personal data that we process based on your consent, we draw your attention that you can withdraw your consent at any time. In that case we will stop processing these personal data, but only for the period after the withdrawal of consent and only those personal data that we do not process on any other legal basis.",

    privacyTitle6: "Security of information",
    privacyUnderTitle61: "Security and confidentiality of your Personal Data is of the utmost importance to us. To ensure it, we use a variety of security measures:",
    privacyUnderTitle62: "Only authorized staff are permitted access to your Personal Information and are required to treat the information as highly confidential;",
    privacyUnderTitle63: "Staff data protection and information security trainings;",
    privacyUnderTitle64: "Password protected directories and databases;",
    privacyUnderTitle65: "Security and data protection policies and procedures;",
    privacyUnderTitle66: "Secure Sockets Layered (SSL) technology to ensure that your information is fully encrypted and sent across the Internet securely;",
    privacyUnderTitle67: "2-factor authentication;",
    privacyUnderTitle68: "Vulnerability Scanning to actively protect our servers from hackers and other vulnerabilities;",
    privacyUnderTitle69: "All financially sensitive and/or credit information is transmitted via SSL technology and encrypted in our database;",
    privacyUnderTitle610: "Constant security review and improvement;",
    privacyUnderTitle611: "Other measures to mitigate risks identified during the risk assessment process.",
    privacyUnderTitle612: "However, it is important to notice that we cannot guarantee the absolute security of your information. Moreover, we are not responsible for the security of information you transmit to us over networks that we do not control, including the internet and wireless networks.",
    privacyUnderTitle613: "You are responsible for keeping your BLOK BE account passcode and pin numbers safe and secure. Never share your account details with anyone else or let them sign in as you. If there is an unauthorised use or any other breach of security involving your information, you must notify us as soon as possible (see Contact Us).",

    privacyTitle7: "Disclosures",
    privacyUnderTitle71: "Your information may be shared to third parties and legal and regulatory authorities only where it is necessary for us to lawfully carry out our business activities, such as:",
    privacyUnderTitle72: "With your consent or at your request;",
    privacyUnderTitle73: "When required to do so pursuant to any applicable law, regulations or rules;",
    privacyUnderTitle74: "Where required to provide services to you;",
    privacyUnderTitle75: "To protect the rights, property, or safety of us, our clients, or others;",
    privacyUnderTitle76: "With other financial institutions regarding a misdirect payment or financial crime;",
    privacyUnderTitle77: "With third parties providing services to us, such as market analysis, correspondent banks, fraud prevention agencies and other agents acting on our behalf;",
    privacyUnderTitle78: "If our legitimate business interests require disclosure;",
    privacyUnderTitle79: "In anonymised form as part of statistics or other aggregated data shared with third parties; or",
    privacyUnderTitle710: "To enforce or apply our Terms of Service and other agreements .",
    privacyUnderTitle711: "Your information will not be sold, exchanged, or shared with any third parties without your consent, except to provide BLOK BE Services or as required by law. By using our Services and accepting our Terms of Service, you consent to the disclosure of your Personal Information as described in this Privacy Policy.",

    privacyTitle8: "Transferring your information",
    privacyUnderTitle81: "All data you provide to us is stored on our secure servers. Where we transfer our data outside of the EEA, we ensure that adequate safeguards are in place.",
    privacyUnderTitle82: "Your personal data is processed in the EEA but may be transferred worldwide, due to some of our third-party service providers who are engaged on our behalf. Personal Data and other data may therefore be exported outside of the jurisdiction in which you reside. Your Personal Data may be processed and stored in a foreign country or countries. Under those circumstances, the governments, courts, law enforcement, or regulatory agencies of that country or those countries may be able to obtain access to your Personal Data through foreign laws. You need to be aware that the privacy standards of those countries may be lower than those of the jurisdiction in which you reside. We will always make sure that every transfer of your personal data to be legal, to use appropriate protective measures, and standard ones at that clauses on data protection adopted by the Commission, and that the Data Processors are in the third countries obliged to comply with the General Regulation (EU) 2016/679 on the protection of personal data (GDPR) and the Act on the Implementation of the General Regulation on Data Protection.",

    privacyTitle9: "Data retention",
    privacyUnderTitle91: "We have set policies and procedures for data retention in order to retain any personal information for no longer than required by applicable laws or regulations, for purposes for which it was obtained.",
    privacyUnderTitle92: "Retention periods for records are determined based on the type of record, the nature of the activity, product or service, the country and the applicable local legal or regulatory requirements.",
    privacyUnderTitle93: "When a users’ account is terminated or expired, we may need to maintain records for a significant period of time, as we are subject to certain anti-money laundering laws which require us to retain the following, for a period of 5 years after our business relationship with you has ended.",
    privacyUnderTitle94: "Retention periods may be changed from time to time (or waived where deemed low-risk) based on business or legal and regulatory requirements.",
    privacyUnderTitle95: "We will also delete your data at your request, unless we are obligated to maintain them to comply with our legal obligations. You can find out more about this by contacting us.",

    privacyTitle10: "Your rights",
    privacyUnderTitle101: "You may access, verify, correct and update your Personal Data held by us. You may inform us at any time that your personal details have changed. In some cases we may need supporting documents from you as a proof i.e. personal information that we are required to keep for regulatory or other legal purposes. You may receive a copy of the Personal Data we hold on you.",
    privacyUnderTitle102: "You may request the deletion of your Personal Information held by us. We may refuse a request for erasure if we still need you Personal Data for compliance with law. If we have disclosed your personal information to others, we will let them know about the erasure request where possible.",
    privacyUnderTitle103: "You can ask us to suppress or block the processing of your personal information in certain circumstances. For example, you may want to establish the accuracy of your Personal Data or you don’t agree with the reason for processing it. If we have disclosed your personal information to others, we will let them know about the restriction of processing if possible.",
    privacyUnderTitle104: "You may request the transfer of a certain part of your Personal Data to another party.",
    privacyUnderTitle105: "You have the right to ask for reconsideration of any decision about you, that was based on an automated process, that affects your ability to access our products or services or has another significant effect on you, and requires human intervention.",
    privacyUnderTitle106: "If we are processing your Personal Data based on a legitimate interest (or that of a third-party) you may challenge this. However, we may be entitled to continue processing your information based on our legitimate interests or where this is relevant to legal claims. You also have the right to object where we are processing your Personal Data for direct marketing purposes.",
    privacyUnderTitle107: "You have a right to lodge a complaint with supervisory authority, to enforce your rights, as specified above.",


    privacyTitle11: "Marketing",
    privacyUnderTitle111: "Company news and updates, as well as relevant marketing information may be communicated to you. We shall only do this where you have given us your consent or otherwise where we are permitted to do so under law in pursuit of our legitimate interests such as, promoting our Services. We may also administer a contest, promotion or a survey.",
    privacyUnderTitle112: "If you do not wish to receive BLOK BE marketing material by any form of communication from us, you may unsubscribe at any time:",
    privacyUnderTitle113: "Click “unsubscribe” at the bottom of an email we sent you",
    privacyUnderTitle114: "Contact us and request to opt out.",
    privacyUnderTitle115: "Note that we can still contact you for account status and activity updates, survey requests in respect of products and Services, respond to your inquiries or complaints, and similar communications, that we have provided to you after you have unsubscribed.",

    privacyTitle12: "Updates to Privacy Policy",
    privacyUnderTitle121: "Our Privacy Policy may be revised, modified and/or updated, without prior notice. We review it regularly to ensure that we have taken into consideration any new obligations and technologies regarding our business operations. Any changes we make will be posted on this page, so it is advisable to review it frequently.",

    privacyTitle13: "Contact Us",
    privacyUnderTitle131: "If you have any questions or comments regarding this Privacy Policy and/or practices, feel free to contact us at the following e-mail address: [support@blokbe.com], or by mail to the address Kapucinska ulica 31, Osijek. If you are not satisfied with the way we process your personal data or if we do not provide you satisfactory answer to your request or complaint, you can contact the Protection Agency personal data, Selska cesta 136, Zagreb, e-mail: azop@azop.hr.",



    // TERMS OF SERVICE PAGE
    termsOfServiceTitle1: "Terms of Service",
    termsOfServiceDate1: "Last Revised :",
    termsOfService1Date2: "31th Mar, 2023",
    termsOfServiceLocation1: "HOME",
    termsOfServiceLocation2: "TERMS OR SERVICE",

    termsOfServiceTitle2: "Introduction",
    termsOfServiceUnderTitle21: "This legal document is a valid and legally binding agreement between you as an individual or legal entity and the company BLOK BE (the 'Agreement'). If you do not agree with this Terms of Service, outlined in this Agreement, please do not access our Site and Services. The Agreement can be terminated immediately by the Customer in a written notice to BLOK BE or can be terminated by BLOK BE in accordance with all the provisions of this agreement.",
    termsOfServiceUnderTitle22: "refers to a company incorporated under the Laws of Croatia, with the company number 030237716, registered at the following address: Kapucinska 31, Osijek.",
    termsOfServiceUnderTitle23: "Please note that you may not be able to use all the functionalities of the Site or Services, that will depend on your country of residence. Also, note that in some jurisdictions, the use of cryptocurrencies may be illegal. Therefore, it is your responsibility to follow the rules and laws in your country of residence and/or country from which you access this Site and/or App and the Services we offer.",

    termsOfServiceTitle3: "Eligibility",
    termsOfServiceUnderTitle31: "By opening a BLOK BE account, and/or using our Site and/or App, you expressly agree that you have read, fully understood and accepted our Terms of Service, Cookie Policy and Privacy Policy. Furthermore, you guarantee that you are of appropriate age, and have the full capacity to accept these Terms of Service and enter into a transaction involving digital assets.",
    termsOfServiceUnderTitle32: "You also have to be a resident of a country where BLOK BE services are available and permitted. The list of those countries can be found here.",
    termsOfServiceUnderTitle33: "Regarding BLOK BE business customers (corporate clients), the availability of services will depend on your country of incorporation and registered office address.",

    termsOfServiceTitle4: "Rights and limitations to our Site and Services",
    termsOfServiceUnderTitle41: "The name BLOK BE, the website www.blokbe.com, logos and all copyright and other intellectual property rights in all materials on this Site are the property of BLOK BE, unless otherwise indicated by us, and are protected by copyright, trademark and other intellectual property rights laws. You are not permitted to copy, imitate or use them without our prior written consent or any third party's copyright, trade secret, patent or other intellectual property rights, or rights of publicity or privacy.",
    termsOfServiceUnderTitle42: "As long as you agree to and comply with these Terms of Service, BLOK BE grants you the personal, non-exclusive, non-transferable, non-sublicensable and limited right to enter and use the Site and the Services, solely for approved purposes as permitted by BLOK BE. You agree that you will not copy, distribute, transmit, sell, modify, publish or otherwise make any derivative uses of our materials or use them other than for their intended purposes; any such unauthorized use is violating copyright, trademark and other applicable laws and could result in criminal or civil penalties. Provided that you comply with all copyright and other proprietary notices, you may freely view, print and/or download a copy of the materials from this Site, solely for your personal, informational and/or non-commercial use. Use of our materials for any other purpose other than stated in this Terms of Service is strictly prohibited and violates copyright, trademark and other applicable laws and could result in criminal or civil penalties. Reference to any products, services, processes or other information, by name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation by us.",
    termsOfServiceUnderTitle43: "You agree and acknowledge your sole responsibility for the content that you provide us, on our Site or through communication with us, including but not limited to information and materials provided in connection with your profiles, inquiries, requests for information, comments, and BLOK BE excludes its full responsibility for it.",
    termsOfServiceUnderTitle44: "Nothing in these Terms of Service gives you any license (other than as set out in this paragraph), right, title, or ownership of, in, or to the Site, any of the Services, the copyrights or the marks.",

    termsOfServiceTitle5: "Our Services",
    termsOfServiceUnderTitle51: "BLOK BE provides its customers with a digital asset exchange platform for purchases of general cryptocurrencies such as but not limited to various stable coins, available on our website at",
    termsOfServiceUnderTitle5111: ", for you to download on your mobile device (Android, iOS).",

    termsOfServiceUnderTitle52: "We offer our customers to create a BLOK BE account, which enables them to:",
    termsOfServiceUnderTitle53: "Purchase digital assets for fiat money through debit/credit card payment option",
    termsOfServiceUnderTitle54: "Receive payout to their specified digital assets wallet address",
    termsOfServiceUnderTitle55: "Purchases of digital assets",
    termsOfServiceUnderTitle56: "You may purchase digital assets through BLOK BE only by using valid payment methods specified by us. The purchase can be performed through our mobile application, available for both Android and iOS. We use our banking and card payment service providers in order to receive customer funds.",
    termsOfServiceUnderTitle57: "When placing an order for digital asset purchase, you will be presented with predefined packages (name and price) to choose from. After you choose your desired order, i.e. the amount of funds you are required to pay, you will be presented with the amount of digital assets you will receive for it. You have the option to accept the order within a certain amount of time and by that express your understanding and agreement to our exchange rate. After you confirm your order, we will transfer the purchased digital assets, as soon as possible, to your designated digital asset wallet address, provided by you in the order slip. After we send you your digital asset, it will have to be confirmed by the digital assets network, which can take a certain period of time.",
    termsOfServiceUnderTitle58: "BLOK BE does not guarantee that you will receive the exact amount of digital assets stated in the purchase order. You agree to accept our purchase price when you authorise a transaction, but the purchase depends on actual receipt of the funds by us, made by a valid payment method by you, as well as payment of any applicable fees. Please note that your bank or card provider may charge you an additional fee.",
    termsOfServiceUnderTitle59: "We reserve the right to correct any errors occurred including but not limited to via our Services, in purchase order confirmation and purchase processing, by revising your purchase transaction accordingly or by cancelling the purchase and refunding any amount received.",
    termsOfServiceUnderTitle510: "You agree that all purchases are final, once they are authorized by you. Once you click to confirm your order, you will be asked to confirm it again by entering your PIN number or by Touch/Face ID. Therefore, the possibility of errors is significantly reduced. BLOK BE does not accept any returns or provide refunds for your purchase of digital assets. Once we have sent the order to the specified digital assets address that you have indicated in the order slip, it cannot be recalled or retrieved under any circumstances.",
    termsOfServiceUnderTitle511: "In case of you filing a chargeback procedure with your bank, we reserve the right to pursue any financial losses suffered, such as, but not limited to, administrative fees levied by the card acquirer and card schemes.",
    termsOfServiceUnderTitle512: "BLOK BE has the right to ask you for confirmation / guarantee that the indicated digital wallet address you have entered is indeed yours.",
    termsOfServiceUnderTitle513: "When placing your purchase order, you will have to provide a digital assets wallet address where you wish to receive your assets. Upon receiving buy order initiation by you, BLOK BE will complete the order by purchasing digital assets on your behalf and sending it to the digital assets wallet address you specified in the order.",
    termsOfServiceUnderTitle514: "Additionally, BLOK BE does not provide the service of investment advice in connection with our Services. It is your sole decision to purchase digital assets, and in cases of any losses, BLOK BE shall not be liable.",
    termsOfServiceUnderTitle515: "IMPORTANT: Please note that all purchases are irreversible once the digital assets are transferred to a certain digital assets wallet account.",

    termsOfServiceTitle6: "Blok Be Account",
    termsOfServiceUnderTitle61: "To create a BLOK BE account, you need to register on our platform by using your name, email, create account password and provide all required information. By using our Services, you agree that you will use them only for yourself, and not on behalf of any third party. It is your responsibility for all activities that occur under your account, therefore, we recommend to carefully maintain security of your account access and to notify us immediately if you have a suspicion of any security breach related to your account with us. You accept all risks of any authorized or unauthorized access to your BLOK BE account, to the maximum extent permitted by law.",
    termsOfServiceUnderTitle62: "We recommend you to create a strong password, with a combination of uppercase and lowercase letters, numbers and special characters, that you do not use for any other website or online service.",

    termsOfServiceTitle7: "Identity verification",
    termsOfServiceUnderTitle71: "Enabled 2-factor authentication and biometric authentication (Touch/Face ID), which BLOK BE uses for all purchases, are obligatory. You can read more about this in the Security section, further below.",
    termsOfServiceUnderTitle72: "Customer Verification is a mandatory process due to the Anti-money laundering rules (“AML”) and our Know your customer (“KYC”) procedures that we must comply with. We may engage with third-party providers in order to verify your identity. The verification process will depend on order amount, among other criteria. You will be asked for government-issued proof of your identity and we may ask for a selfie photo. Other information we may request from you include, but are not limited to, contact information, financial information, selfie photos and other identity verification information. You agree to provide accurate and truthful information, and advise us of any changes to verification documents. You can read more about this in our KYC/AML Policy.",
    termsOfServiceUnderTitle73: "Our business customers will be required to provide certain information about the company itself, about the beneficial owners who hold more than 10% of the company, authorized representatives and also will be required to submit all the documentation necessary for understanding ownership structure if needed. The requirements will be defined in the company KYC/AML policy.",
    termsOfServiceUnderTitle74: "We may, in our sole discretion, refuse to open a BLOK BE account for you, or limit the number of accounts that you may hold or suspend or terminate any account, especially, if you fail to provide any information that we deem necessary for your application for a BLOK BE account, due to KYC and AML laws and regulations.",
    termsOfServiceUnderTitle75: "We reserve the right to conduct enquiries, ourselves or through third parties, regarding the verification of your identity or your protection and/or ours against fraud or other financial crime. This could lead to your personal information being disclosed to credit reference and fraud prevention or financial crime agencies, which you agree upon by accepting these Terms of Service.",
    termsOfServiceUnderTitle76: "For detailed information about how we collect, process, use and disclose your personal data, please refer to our",
    termsOfServiceUnderTitle761: "Privacy Policy",
    termsOfServiceUnderTitle762: ". You acknowledge that you have read and understood our Privacy Policy before providing us with any such personal data. As your privacy is of utmost importance to us, we recommend that you read it carefully, in order to understand how we keep your personal data safe.",

    termsOfServiceTitle8: "Security",
    termsOfServiceUnderTitle81: "You acknowledge that you are responsible for all order purchases that were made by using your BLOK BE account. 2-factor authentication must be enabled when accessing your BLOK BE account and making purchase orders. Every order must be confirmed by you, by entering your PIN number or biometric authentication, i.e. fingerprint or face ID authorization by you.",
    termsOfServiceUnderTitle82: "It is your sole responsibility to keep your account data secure. We recommend that you use a strong password that you do not use for any other service. Do not share your account data with anyone and keep it secure at all times.",
    termsOfServiceUnderTitle83: "BLOK BE will not be responsible for any loss or damage due to lost or stolen account data, or unauthorized access of your BLOK BE account or your email address. If you suspect any of these fraudulent activities please contact us immediately at",

    termsOfServiceTitle9: "Limits",
    termsOfServiceUnderTitle91: "BLOK BE has implemented purchasing limits for its customers on a periodical (daily, monthly) basis. Based on our internal AML and CFT policies and rules, there may be maximum daily deposit or maximum monthly deposit limitation for purchases of digital assets.",
    termsOfServiceUnderTitle92: "e may also limit the number of orders made by a payment card per day. The limits can vary depending on different factors such as, but not limited to, verification level and payment method.",
    termsOfServiceUnderTitle93: "You can view your limits by logging to your account in our application. If you wish, you may ask for higher purchasing limits by contacting us at",
    termsOfServiceUnderTitle94: "We may ask for additional documentation in order for us to validly decide on the outcome of your request. We can deny your request for raising your limits or even lower them due to our AML and CFT policies and other rules. We reserve the right to change purchasing limits as we deem necessary.",

    termsOfServiceTitle10: "Account Closure",
    termsOfServiceUnderTitle101: "At any given time, you have the right to close your BLOK BE account. If you wish to no longer have an account with us for any reason, please contact us at",
    termsOfServiceUnderTitle1011: "BLOK BE will proceed with your account closure, unless there is an ongoing investigation regarding your BLOK BE account.",
    termsOfServiceUnderTitle102: "Note that you will remain liable for any actions taken prior to closure of your BLOK BE account.",
    termsOfServiceUnderTitle103: "Personal data shall be stored, archived and secreted in accordance with legal and other regulations or decisions or optional act of the controller. personal data is deleted after the expiration of all legal obligations related to the storage of personal data.",

    termsOfServiceTitle11: "Account suspension, termination, restriction and change of services",
    termsOfServiceUnderTitle111: "BLOK BE reserves the right to suspend, restrict, change or terminate your use of our Services without prior notice to you, at any given time, including, but not limited to following cases:",
    termsOfServiceUnderTitle112: "In the event of you breaching any of these Terms of Service or engaging in any restricted activity.",
    termsOfServiceUnderTitle113: "When you have provided us with false or misleading information, or failed to provide us with documentation that we requested in order to comply with AML and KYC regulations",
    termsOfServiceUnderTitle114: "We have a suspicion of money laundering, fraud, terrorist financing or any other financial crime",
    termsOfServiceUnderTitle115: "Where we reasonably believe that we are required to do so to comply with law, court order, regulation or any authority to which we are subject in any jurisdiction, as well as the requirements of the competent public and local authorities",
    termsOfServiceUnderTitle116: "Where we reasonably believe, that our security is or could be potentially compromised, or your use of your BLOK BE account is harmful to us, or used in any fraudulent or unauthorized manner",
    termsOfServiceUnderTitle117: "Where we reasonably believe that we need to protect your personal information of your BLOK BE account, regarding data being stolen or misplaced",
    termsOfServiceUnderTitle118: "In order to protect our reputation",
    termsOfServiceUnderTitle119: "If there is an ongoing investigation regarding your BLOK BE account",
    termsOfServiceUnderTitle1110: "If you request a significantly larger purchase order of digital assets",
    termsOfServiceUnderTitle1111: "If you are our business customer and you've been declared bankrupt or insolvent, are being wound up, or a similar event is taking place",
    termsOfServiceUnderTitle1112: "If you are our business customer and there's been a change in the beneficial ownership of the issued share capital of your business",
    termsOfServiceUnderTitle1113: "If you are our business customer and the person who is a legal representative of your business has been replaced",
    termsOfServiceUnderTitle1114: "In other occasions that require our involvement in order to comply with laws and regulations, and security, both of your Account and BLOK BE",
    termsOfServiceUnderTitle1115: "You agree that we are not liable for any losses incurred in connection with account suspension. We reserve the right to restrict you from certain parts of our Services, according to our reasonable belief that it poses a risk for our Company. We can reverse the restrictions if we estimate that the conditions for that are met.",
    termsOfServiceUnderTitle1116: "BLOK BE reserves the right to terminate your BLOK BE account at any time, by giving you a notice in advance. However, any further information about your account termination or suspension is not required to be provided to you by us. You agree that due to our security and risk management procedures, we are under no obligation to disclose any details further.",


    termsOfServiceTitle12: "Set of clause",
    termsOfServiceUnderTitle121: "We reserve the right to be compensated by you for any outstanding amounts. If you owe us fees or any other amount, we will exercise our right to set off by taking that amount from any amount we are due to pay to you or we will charge your selected payment method stored with us. You authorize us, or our designated payment processor, to charge or deduct your stored payment card for any applicable fees or amounts owed in connection with your account with us and your use of our Services.",

    termsOfServiceTitle13: "General Liability",
    termsOfServiceUnderTitle131: "It is your responsibility to:",
    termsOfServiceUnderTitle132: "Respect and follow these Terms of Service, as well as our Privacy Policy",
    termsOfServiceUnderTitle133: "Comply with any and all laws, regulations and rules of your jurisdiction that may apply to your use of our Services",
    termsOfServiceUnderTitle134: "Conclude what, if any, taxes, and to what extent, apply to your purchases via our Services, and to collect, report and remit the correct tax amounts to the appropriate tax authority. We shall not be liable for your execution of tax obligations.",
    termsOfServiceUnderTitle135: "Provide accurate, truthful and complete information for KYC verification, and inform immediately of any changes to your KYC documents and information after the verification process is completed",
    termsOfServiceUnderTitle136: "Keep a copy of any information you provide us or that is accessible through your BLOK BE account. We shall not be liable for any incurred losses of information",
    termsOfServiceUnderTitle137: "You acknowledge and confirm that you will not use our Services and Site for any purpose that is illegal, unlawful, unethical or inconsistent and/or contrary with these Terms of Service and the purpose for which this Site and Services were created.",
    termsOfServiceUnderTitle138: "All unauthorized uses of this website and any threats will result in investigation by us. We reserve the right to determine all violations of these Terms of Service and to act accordingly, including, but not limited to, reporting suspicion of illegal activities to appropriate law enforcement or other authorized legal or regulatory authorities. We will also provide them with needed information and personal data of the suspected person.",


    termsOfServiceTitle14: "Disclosures to legal authorities",
    termsOfServiceUnderTitle141: "BLOK BE reserves the right to share your information and personal data to legal authorities when it deems it necessary and reasonable to do so, including, but not limited to law requirements, court order or other legal procedure, investigation and suspicion of illegal activities, prevention of financial loss or physical harm, and investigation of violations of our Terms of Service or Privacy Policy.",
    termsOfServiceUnderTitle142: "You agree and consent that we may share your Personal Data. For more information, please refer to our Privacy Policy.",

    termsOfServiceTitle15: "Your Warranties and Representation",
    termsOfServiceUnderTitle151: "By agreeing to these Terms of Service, you represent, warrant and undertake to BLOK BE that you:",
    termsOfServiceUnderTitle152: "are of legally appropriate age, and have the full capacity, power and authority to accept these Terms of Service and enter into a transaction involving digital assets and other digital assets",
    termsOfServiceUnderTitle153: "are entering into this Agreement as a principal and not on behalf of any third party",
    termsOfServiceUnderTitle154: "understand the risks associated with our Services",
    termsOfServiceUnderTitle155: "are not prohibited or restricted from using our Site/Application",
    termsOfServiceUnderTitle156: "will not act in violation of any applicable law or regulation by entering into this Agreement",
    termsOfServiceUnderTitle157: "will not use our Services or Site to conceal or disguise any breach of applicable AML or CTF laws",
    termsOfServiceUnderTitle158: "have not violated, debarred, sanctioned, the subject of Economic Sanctions-related restrictions, or otherwise penalized under; received any oral or written notice from any Government concerning actual or possible violation by you under; or received any other report that you are the subject or target of sanctions, restrictions, penalties, or enforcement action or investigation under, any applicable Laws (including but not limited to anti-money laundering laws, counterterrorism financing laws, anti-corruption laws or economic sanctions laws)",
    termsOfServiceUnderTitle159: "will not purchase digital assets with anything other than fiat funds that have been legally obtained by you and that belong to you",
    termsOfServiceUnderTitle1510: "will fairly and promptly collect, report and remit the correct tax amounts in connection with your activity on our Site to the appropriate tax authority",
    termsOfServiceUnderTitle1511: "will not falsify or provide inaccurate, misleading or false information requested by us, including but not limited to the process of verifying your identity and registration or during any other due diligence processes; will provide us immediately with new information in cases of incorrect or outdated information provided to us",
    termsOfServiceUnderTitle1512: "will employ reasonable anti-virus, anti-malware and other software and techniques to protect you and your digital assets wallet from unauthorized access or other malicious actions; will not facilitate any computer programming routines including but not limited to viruses, malware, trojan horses or worms that may cause damage, corruption, interruption, misuse or unauthorized access to any data or our Services",
    termsOfServiceUnderTitle1513: "will not use any robot, spider, other automatic device, or manual process to access, monitor or copy our website without our prior written permission",
    termsOfServiceUnderTitle1514: "will not illtreat and/or threaten our employees or other users",
    termsOfServiceUnderTitle1515: "will fully assist with our reasonable requests regarding our obligations under these Terms of Service",
    termsOfServiceUnderTitle1516: "will immediately and accurately inform us if you know or have reason to know whether any of the foregoing representations or warranties no longer is correct or becomes incorrect",

    termsOfServiceTitle16: "No warranties",
    termsOfServiceUnderTitle161: "BLOK BE Services are provided on an 'as is' and 'as available' basis, except if we expressly state to the contrary in a writing form. We do not make any representations, warranties, covenants, or guarantees to you of any kind, to the extent permitted by applicable Laws. We expressly disclaim, and you waive, all warranties of any kind, expressed, implied or statutory, including but not limited to any implied warranties of title, merchantability, fitness for a particular purpose and/or non-infringement, with respect to the Site and Services.",
    termsOfServiceUnderTitle162: "We do not guarantee that access to the Site or Services and its materials will be uninterrupted, timely or will not contain bugs or errors. You understand and acknowledge that we do not make any representations or warranties to accuracy or completeness of information we provide to you through our Site or Services",
    termsOfServiceUnderTitle163: "You understand and agree that we do not warrant that any of our Services are suitable or appropriate for your needs and that you must take your own independent legal and other advice on such services;",
    termsOfServiceUnderTitle164: "You acknowledge your sole responsibility for backing up and maintaining copies of any information you store or transfer to our Services, in view of the fact that information may become lost or corrupted or temporarily unavailable due to internet outages, software failure, unauthorized access or other disasters and malfunctions.",

    termsOfServiceTitle17: "Indemnity",
    termsOfServiceUnderTitle171: "Hereby you agree to indemnify BLOK BE and its partners, to the full extent permitted by applicable law, against any claim, demand, action, liability, cost, loss, damage, proceeding or expense suffered or incurred if directly or not directly arising from your use of the Site, your use of the Services or from your violation of these Terms of Service or your violation of any rights of any other person or entity.",

    termsOfServiceTitle18: "Third-party content",
    termsOfServiceUnderTitle181: "You may encounter links to third-party websites or services that are not under the control of BLOK BE.",
    termsOfServiceUnderTitle182: "BLOK BE makes no representations whatsoever and accepts no liabilities for any Third-Party Services. We have no control over them or any of their content, do not endorse or adopt any of it and shall have no responsibility for it. You shall bear all risks associated with the use of such content. Also, you may be bound by any additional terms required by providers of Third-Party Services; that is solely between you and the third parties, and we disclaim any liability or responsibility for any loss or damage incurred. We are not responsible for the accuracy or reliability of any information, data, opinions, advice, or statements contained in Third-Party Services.",

    termsOfServiceTitle19: "Jurisdiction",
    termsOfServiceUnderTitle191: "These Terms of Service shall be governed by and interpreted in accordance with the law of the Republic of Croatia. In case of a dispute, the parties hereto agree to irrevocably submit to the exclusive jurisdiction of the court in Osijek having jurisdiction over the substance of the matter to solve it.",

    termsOfServiceTitle20: "Force majeure",
    termsOfServiceUnderTitle201: "In case of damages caused by force majeure, including but not limited to natural disasters, power outages or failures, acts of God, acts of any Government, market movements, computer, server of Internet malfunctions, security breaches or cyberattacks, or, any other delays, defaults, failures or interruptions that cannot reasonably be foreseen or provided against, BLOK BE will not be liable for any and all performance obligations under these Terms of Service.",

    termsOfServiceTitle21: "Notice and Communications",
    termsOfServiceUnderTitle211: "You hereby agree and consent to electronic receipt of all communications, agreements, documents, receipts, notices and disclosures that BLOK BE provides in relation to your BLOK BE account and/or use of our Services. We may communicate with you by publication of information on our Site and/or by sending you information to your authorized e-mail address, stated in your account with BLOK BE. We may also contact you at the postal. Please make sure that the address, phone number and email address stated in your account with BLOK BE are up to date. The information sent / publicated by us will be deemed as effective and received by you, when it is posted by us on our Site, for postal communication on the second business day after posting and for mail communication at the time it is sent to your email address.",

    termsOfServiceTitle22: "Modification of Terms of Service",
    termsOfServiceUnderTitle221: "BLOK BE reserves the right to make changes and updates to our Terms of Service at any time. We will notify you of any changes in advance, where possible. It is your sole responsibility to review the amended Terms of Service and any possible changes. If you do not agree with the amended Terms of Service, do not use this Site and our Services anymore. Continuing to use our Services signifies your acceptance and agreement to the modified Terms of Service. The date of the last update is at the top of this document.",

    termsOfServiceTitle23: "Complaints",
    termsOfServiceUnderTitle231: "Please contact us for any comment, complaint or help needed.",

    termsOfServiceTitle24: "Miscellaneous",
    termsOfServiceUnderTitle241: "Only you, as the holder of a BLOK BE account have any rights to enforce these Terms. Your rights, licenses and/or obligations are personal to you and you cannot transfer them to any third party. BLOK BE may transfer or assign our rights and obligations under these Terms at any time, including as part of a merger, acquisition or other corporate reorganization. If you do not agree with the transfer, you may terminate this Agreement, and not use our Services and Site anymore.",
    termsOfServiceUnderTitle242: "We may not always strictly enforce our rights under these Terms of Service; where we do not enforce our rights under these Terms, we do not waive our rights.",
    termsOfServiceUnderTitle243: "If any provision of this Agreement is held to be invalid or unenforceable, unless otherwise stated in these Terms of Service, it will not affect the validity of the remaining provisions. Any other provision will be enforced to the extent possible.",
    termsOfServiceUnderTitle244: "In case of termination or expiration of this Agreement, all provisions that by nature extend beyond termination or expiration of the Agreement, will continue to be binding and afterwards, including but not limited to general use of the Site, termination/suspension/cancellation, disputes, debts toward BLOK BE and general provisions.",

    termsOfServiceTitle25: "Disclamer",
    termsOfServiceUnderTitle251: "Financial risk: Purchasing digital assets may carry a significant risk. Markets may quickly shift in terms of price, liquidity, market depth and trading dynamics. The reason for those big fluctuations can come from loss of confidence in digital assets, decisions made by the government or political statements, news, hacker attacks, creation of (ir)rational bubbles or technical problems. The value of digital assets may drastically change and potentially even fall to zero.",
    termsOfServiceUnderTitle252: "Additional risks:",
    termsOfServiceUnderTitle253: "There may be additional risks that we have not foreseen or identified in our Terms of Service. Therefore, you should carefully consider your financial situation and risk tolerance before using our Service. You are solely responsible and liable for any and all purchasing activity on our Site. Also, you agree to be fully responsible for safeguarding all information provided through our Site or mobile application, including, but not limited to, private keys, usernames, passwords, and bank account details. We strongly recommend you to regularly change your passwords and keep them safely stored from unauthorized access, as well as your private keys, security codes and seed words.",
    termsOfServiceUnderTitle254: "There is no guarantee that there won’t be malfunctions that are beyond our control in relation to Internet connections, hardware, software, or Blockchain network 'that could interfere with our Service, and cause errors, delays or failures. You hereby acknowledge that BLOK BE shall not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using our Services. Nevertheless, we will always strive to provide you with our Services as soon as possible.",
    termsOfServiceUnderTitle255: "Limitation of liability: Digital assets prices can and do fluctuate on any given moment. BLOK BE disclaims all liability associated with the use of digital assets. It is your sole responsibility to estimate your knowledge and finance situation to understand the risks involved with the use of digital assets, including but not limited to market fluctuations, regulatory or legislative changes or unknown inherent technical defects.",
    termsOfServiceUnderTitle256: "You shall bear any loss resulting in your own error or fault, including but not limited to security breaches of passwords or private keys, unauthorized access to account or email.",
    termsOfServiceUnderTitle257: "BLOK BE is not liable for any errors for information provided on our Site, that may be inaccurate or contain typographical errors and omissions. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information at any time without prior notice, so we recommend that you, before relying on those information and making decisions or actions, verify them with independent sources.",
    termsOfServiceUnderTitle258: "We assume no liability or responsibility for any losses directly or indirectly connected with any errors, delays, malfunctions, omissions, or interruptions in operating our Site or providing any Service.",
    termsOfServiceUnderTitle259: "BLOK BE will not be liable for any and all losses of profits, loss of revenue, loss or corruption of data, loss of business, goodwill or reputation, loss of opportunity or for any direct, indirect, punitive, incidental, consequential, and other damages that are not a result of gross negligence, wilful deceit or fraud, whether the losses are based on a contract, negligence, tort, unjust enrichment, strict liability, violation of law or regulation, or any other basis.",
    termsOfServiceUnderTitle2510: "Nothing in these terms excludes or limits the liability of BLOK BE for fraud, fraudulent misrepresentation or fraudulent misstatement, or death or personal injury caused by our negligence which may not be limited or excluded by law. Your actions and decisions remain entirely your responsibility.",
    termsOfServiceUnderTitle2511: "Nothing in these Terms of Service shall be deemed or is intended to be deemed, nor shall it cause you and BLOK BE to be treated as partners or joint ventures, or either you or BLOK BE to be treated as the agent of the other.",
    termsOfServiceUnderTitle2512: "Unless expressly and specifically stated in these Terms, BLOK BE disclaims all warranties, implied or expressed, to tu fullest extent possible under the applicable law in connection with the availability, quality, reliability, relevance, timeliness or accuracy in relation to our Services, website content or the use of our Services. All warranties, conditions, representations and all other terms of any kind whatsoever implied by statute or common law are excluded from these Terms of Service to the fullest extent permitted by applicable law.",
};

export const hr = {
    // NAVBAR
    navHome: `POČETNA`,
    navServices: "USLUGE",
    navExchange: "BLOKBE KRIPTO MJENJAČNICA",
    navPayment: "BLOKBE KRIPTO PLAĆANJE",
    navApp: "BLOKBE MOBILNA APLIKACIJA",
    navAtm: "KRIPTO BANKOMAT",
    navEducation: "EDUKACIJA",
    navAbout: "O NAMA",
    navPrivacyAndTerms: "PRAVILA PRIVATNOSTI I UVJETI",
    navPrivacy: "PRAVILA PRIVATNOSTI",
    navTerms: "UVJETI PRUŽANJA USLUGE",
    navContact: "KONTAKT",

    // CONTACT END
    contactAllTitle: "Kontaktirajte Nas !",
    contactAllTitle1: "Isprobajte aplikaciju !",
    contactAllUnderTitle1: "Započnite svoje kripto",
    contactAllUnderTitle2: "putovanje s",
    contactAllButton: "KONTAKT",

    // FOOTER
    footerText1: "Fin-tech tvrtka s vizijom da ponudi sigurno i ugodno iskustvo u kripto svijetu.",
    footerText2: "Regulator",
    footerText3: "HRVATSKA AGENCIJA ZA NADZOR FINANCIJSKIH USLUGA",
    footerText4: "Proizvedeno sa <3 u Hrvatskoj.",

    // LANDING
    homeTitle1: "KRIPTO MJENJAČNICA",
    homeTitle2: "KRIPTO PLAĆANJE",
    homeTitle3: "MOBILNA APLIKACIJA",
    homeTitle4: "KRIPTO BANKOMAT",
    homeUnderTitle1: "Kupovina, prodaja i razmjena kriptovaluta.",
    homeUnderTitle11: "Vaša pouzdana i sigurna BlokBe kripto mjenjačnica.",
    homeUnderTitle2: "Besplatno uvedite uslugu plaćanja kriptovalutama",
    homeUnderTitle22: "u svoje poslovanje",
    homeUnderTitle3: "Kupi i troši kriptovalute.",
    homeUnderTitle33: "Jednostavno i sigurno.",
    homeUnderTitle4: "U pripremi...",
    homeButtonTitle1: "PROČITAJ VIŠE",
    homeButtonTitle2: "PROČITAJ VIŠE",
    homeButtonTitle3: "PROČITAJ VIŠE",
    homeButtonTitle4: "KONTAKT",
    sliderTitle1: "MJENJAČNICA",
    sliderTitle2: "PLAĆANJE",
    sliderTitle3: "APLIKACIJA",
    sliderTitle4: "BTC BANKOMAT",

    // LANDING PARTNERS
    landingPartnersTitle: "Naši partneri",
    landingPartnersUnderTitle: "Naša mreža partnera pojačava našu sposobnost da otključamo puni potencijal kriptovaluta.",


    // HOME SERVICES LIST
    homeServicesTitle: "Ukratko o našim uslugama",
    homeServicesUnderTitle1: "S vrhunskom tehnologijom i metodologijom",
    homeServicesUnderTitle2: "vam može pomoći da započnete s kriptovalutama.",

    homeServiceTitle1: "Kripto Mjenjačnica",
    homeServiceTitle2: "Kripto Plaćanje",
    homeServiceTitle3: "BlokBe Mobilna Aplikacija",
    homeServiceTitle4: "Kripto Bankomat",
    homeServiceUnderTitle41: "U pripremi...",

    homeServiceUnderTitle1: "BlokBe kripto mjenjačnica je stvorena s ciljem da omogući korisnicima brzu i sigurnu kupovinu, prodaju i/ili zamjenu kriptovaluta.",
    homeServiceUnderTitle2: "BlokBe crypto plaćanje je elektronički platni servis za procesuiranje uplata u kriptovalutama, namijenjen raznim maloprodajnim, veleprodajnim trgovcima i prodajnim servisima koji vrše naplatu fizički i/ili preko online trgovina, koji će omogućiti svojim kupcima plaćanje u kriptovalutama, pri čemu će na svoj poslovni račun primati EUR.",
    homeServiceUnderTitle21: "je elektronički platni servis za procesuiranje uplata u kriptovalutama, namijenjen raznim maloprodajnim, veleprodajnim trgovcima i prodajnim servisima koji vrše naplatu fizički i/ili preko online trgovina, koji će omogućiti svojim kupcima plaćanje u kriptovalutama, pri čemu će na svoj poslovni račun primati EUR.",

    homeServiceUnderTitle22: "Uvedite BlokBe kripto plaćanje potpuno besplatno.",
    homeServiceUnderTitle3: "S BlokBe aplikacijom možete sigurno uživati u kupnji i trošenju kriptovaluta kod specijaliziranih, pouzdanih online trgovaca.",
    homeServiceUnderTitle4: "",
    homeService1Subtitle1: "Općenito",
    homeService1Subtitle2: "Detalji",
    homeService1UnderSubtitle1: "BlokBe kripto mjenjačnica podržava preko 200 raznih kriptovaluta koje možete kupiti, prodati i zamijeniti. Kripto mjenjačnica pruža jedinstven individualni pristup svakom korisniku. Također pružamo edukacijsku i stručnu pomoć svim našim korisnicima.",
    homeService1UnderSubtitle11: "Posjedujete kriptovalute i želite ih zamijeniti za EUR ili neku drugu valutu?",
    homeService1UnderSubtitle111: " Učinite to preko BlokBe kripto mjenjačnice - brzo, jednostavno i sigurno.",
    homeService1UnderSubtitle1111: "Kontaktirajte",
    homeService1UnderSubtitle11111: "BlokBe.",
    homeService1UnderSubtitle2: "BlokBe mjenjačnica regulirana je od strane HANFA-e (HRVATSKE AGENCIJA ZA NADZOR FINANCIJSKIH USLUGA) te stoga vršimo verifikaciju svih naših korisnika, a uplate i isplate radimo isključivo transakcijski preko bankovnog računa.",


    // CRYPTO PAYMENT
    homeCryptoPaymentTitle1: "Općenito",
    homeCryptoPaymentUnderTitle1: "BlokBe crypto payment gateway",
    homeCryptoPaymentUnderTitle11: "(CPG)",
    homeCryptoPaymentUnderTitle12: "je softver osmišljen da bude vrlo",
    homeCryptoPaymentUnderTitle13: "jednostavan za korištenje",
    homeCryptoPaymentUnderTitle14: "kako za trgovca tako i za krajnjeg kupca. Ne morate nužno znati i baviti se kriptovalutama da biste svojim kupcima omogućili ovaj najsuvremeniji način plaćanja. Za Vas će sve odraditi BlokBe i to",
    homeCryptoPaymentUnderTitle15: "potpuno besplatno",
    homeCryptoPaymentUnderTitle16: ", Vaš pouzdani partner i posrednik za korištenje kriptovaluta u poslovanju.",
    homeCryptoPaymentUnderTitle17: "CPG Prijava",

    homeCryptoPaymentTitle2: "Kako postati BlokBe Partner",
    homeCryptoPaymentUnderTitle21: "Iskoristite priliku, postanite ekskluzivni BlokBe partner i uvedite plaćanje kriptovalutama u Vaše poslovanje. Naplatite proizvod ili uslugu u kriptovalutama - u online trgovinama i na fizičkim lokacijama. Primate uplate u kriptovalutama, a na račun Vam se uplaćuju euri.",
    homeCryptoPaymentUnderTitle211: "Iskoristite priliku, postanite",
    homeCryptoPaymentUnderTitle212: "ekskluzivni BlokBe partner",
    homeCryptoPaymentUnderTitle213: "i uvedite plaćanje kriptovalutama u Vaše poslovanje. Naplatite proizvod ili uslugu u kriptovalutama - u online trgovinama i na fizičkim lokacijama. Primate uplate u kriptovalutama, a na račun Vam se uplaćuju euri.",

    homeCryptoPaymentUnderTitle22: "Korak 1.  Preuzmi obrazac",
    homeCryptoPaymentUnderTitle23: "Korak 2. Ispuni obrazac",
    homeCryptoPaymentUnderTitle24: "Korak 3. Pošalji obrazac na",
    homeCryptoPaymentUnderTitle25: "treasury@blokbe.com",
    homeCryptoPaymentUnderTitle26: "PREUZMI OBRAZAC",
    homeCryptoPaymentUnderTitle27: "Ostvarite kontakt i dogovorite inicijalni sastanak sa tvrtkom BlokBe. Naši stručni suradnici detaljno će Vam objasniti način funkcioniranja, implementacije i korištenja našeg softvera. Također smo na raspolaganju za sva Vaša pitanja.",

    homeCryptoPaymentTitle3: "Prednosti uvođenja BlokBe Payment Gateway-a",
    homeCryptoPaymentUnderTitle31: "Sve više tehnološko i informatičko osviješteni potrošači koriste kriptovalute za plaćanje proizvoda i usluga diljem svijeta. Takvim potrošačima je bitna brzina, fleksibilnost i diverzifikacija mogućnosti plaćanja. Svijet je postao ubrzano mjesto, gdje potrošači više nemaju vremena čekati u redovima, mijenjati devize u lokalne nacionalne valute, gubiti novce na nepovoljnim tečajevima, gubiti vrijeme na preračunavanja vrijednosti iz jedne valute u druge i nositi velike količine gotovine kada idu na godišnje odmore, putovanja i slično.",
    homeCryptoPaymentUnderTitle32: "Sada već preko 300 milijuna potrošača koristi kriptovalute kao sredstvo plaćanja za širok spektar proizvoda i usluga. Pratite svjetske financijsko tehnološke trendove te postanite među prvim trgovcima u Hrvatskoj koji primaju kriptovalute kao sredstvo plaćanja. Budite prepoznati kao tehnološki osviještena tvrtka koja postavlja suvremene trendove financijskog poslovanja.",
    homeCryptoPaymentUnderTitle321: "Sada već",
    homeCryptoPaymentUnderTitle322: "preko 300 milijuna potrošača",
    homeCryptoPaymentUnderTitle323: "koristi kriptovalute kao sredstvo plaćanja za širok spektar proizvoda i usluga. Pratite svjetske financijsko tehnološke trendove te postanite među prvim trgovcima u Hrvatskoj koji primaju kriptovalute kao sredstvo plaćanja. Budite prepoznati kao tehnološki osviještena tvrtka koja postavlja suvremene trendove financijskog poslovanja.",

    homeCryptoPaymentTitle4: "BlokBe Crypto Payment Prednosti",
    homeCryptoPaymentUnderTitle41: "Modernizacija",
    homeCryptoPaymentUnderTitle411: "Modernizacija poslovanja uvođenjem najsuvremenijeg načina plaćanja.",
    homeCryptoPaymentUnderTitle42: "Inovacija",
    homeCryptoPaymentUnderTitle422: "Otvaranje tržišta za potpuno novu, tehnološki osvještenu generaciju potrošača.",
    homeCryptoPaymentUnderTitle43: "Praćenje trendova",
    homeCryptoPaymentUnderTitle433: "Postajete među prvim trgovcima u Hrvatskoj koji su uveli suvremeni način plaćanja sa kriptovalutama te ostvarite konkurentsku prednost.",
    homeCryptoPaymentUnderTitle44: "Marketing",
    homeCryptoPaymentUnderTitle444: "Besplatni marketing od strane tvrtke BlokBe - Vašeg partnera za kriptovalute.",
    homeCryptoPaymentUnderTitle45: "Efikasnost",
    homeCryptoPaymentUnderTitle455: "Super brze i jednostavne transakcije.",
    homeCryptoPaymentUnderTitle46: "Ušteda",
    homeCryptoPaymentUnderTitle466: "Bez naknada transakcija i dodatnih troškova, za Vas je uvođenje BlokBe payment gateway-a potpuno besplatno, a s time omgućavate racionalizaciju troškova.",
    homeCryptoPaymentUnderTitle47: "Maksimalna fleksibilnost",
    homeCryptoPaymentUnderTitle477: "Vi birate dinamiku uplate sredstava na Vaš poslovni račun.",
    homeCryptoPaymentUnderTitle48: "Stručna i brza podrška",
    homeCryptoPaymentUnderTitle488: "Svim našim partnerima pružamo stručnu i brzu podršku 24/7.",
    homeCryptoPaymentUnderTitle49: "Visoka razina sigurnosti",
    homeCryptoPaymentUnderTitle499: "BlokBe, Vaš partner za kriptovalute je u potpunosti reguliran od strane Hrvatske agencije za nadzor financijskih usluga - HANFA-e.",

    homeCryptoPaymentTitle5: "BlokBe Partneri",

    homeCryptoPaymentTitle6: "Dijagram Procesa",
    homeCryptoPaymentTitle61: "Korisnik zahtjeva plaćanje kriptovalutama.",
    homeCryptoPaymentTitle62: "Djelatnik na blagajni odabire vrstu kriptovalute za plaćanje.",
    homeCryptoPaymentTitle63: "Nakon odabira i unosa cijene usluge, djelatnik odabire ispis QR koda.",
    homeCryptoPaymentTitle64: "Korisnik skenira QR kod teizvršava plaćanje usluge preko svog mobilnog kripto novčanika.",
    homeCryptoPaymentTitle65: "Djelatnik instant prima potvrdu transakcije te korisniku pruža uslugu.",
    homeCryptoPaymentTitle66: "BlokBe automatski izvršava zamjene kriptovalute u EUR.",
    homeCryptoPaymentTitle67: "BlokBe izvršava uplatu po ugovorenim terminima na bankovni račun partnera.",


    // BLOKBE APP
    appTitle1: "Kupi i troši kriptovalute.",
    appTitle11: "Jednostavno",
    appTitle111: "i",
    appTitle1111: "Sigurno.",
    appTitle11111: "S BlokBe aplikacijom možete sigurno uživati u kupnji i trošenju kriptovaluta kod specijaliziranih, pouzdanih online trgovaca.",
    appTitle2: "Mi zahtjevamo",
    appTitle21: "Privatnost",
    appTitle22: "i",
    appTitle23: "Sigurnost",
    appTitle24: "Bez sigurnosti ne možemo govoriti o privatnosti.",
    appTitle25: "mobilne aplikacije imaju implementiran OWASP sigurnosni standard.",

    homeAppTitle1: "Ovdje u",
    homeAppTitle12: "BlokBe-u",
    homeAppTitle13: "sigurnost nije proizvod već proces.",
    homeAppUnderTitle11: "Naši su postupci ručno izrađeni s najsitnijim detaljima. Koristimo vrhunske tehnologije kako bismo bili sigurni da je vaša imovina uvijek sigurna.",
    homeAppUnderTitle12: "Kodirani dizajn tipkovnice sprječava promatrače da otkriju PIN",
    homeAppUnderTitle13: "Izvorna biometrijska autentifikacija u postupcima mobilnog bankarstva",
    homeAppUnderTitle14: "Ne pohranjujemo nikakve osjetljive podatke o kreditnim ili debitnim karticama. Vaš uređaj, vaša pohrana.",

    homeAppTitle2: "Neskrbnički novčanik",
    step: "KORAK",
    homeAppUnderTitle21: "BlokBe vam omogućuje da budete sami svoja banka. To znači da imate potpunu kontrolu nad svojim sredstvima.",
    homeAppUnderTitle22: "Kreirajte svoj novčanik",
    homeAppUnderTitle23: "Dodajte ga u BlokBe aplikaciju",
    homeAppUnderTitle24: "Pokrenite bankarstvo.",

    homeAppTitle3: "Brza transakcija",
    homeAppUnderTitle31: "U nekoliko klikova prenesite sredstva našim pouzdanim trgovcima ili izravno u svoj kripto novčanik.",
    homeAppUnderTitle32: "Način plaćanja",
    homeAppUnderTitle33: "Trgovac ili novčanik",
    homeAppUnderTitle34: "Iznos. Gotovo.",

    homeAppTitle4: "Program provjerenih partnera",
    homeAppUnderTitle41: "Program provjerenih partnera BlokBe pruža najpouzdaniju i najsigurniju trgovačku mrežu za rad s povjerenjem.",
    homeAppUnderTitle42: "Postavite referencu trgovca",
    homeAppUnderTitle43: "Odaberite ponuđene pakete",
    homeAppUnderTitle44: "Pokrenite bankarstvo.",

    homeAppTitle5: "Preuzmite aplikaciju na",

    // BLOKBE CRYPTO EXCHANGE
    cryptoExchangeTitle1: "BlokBe Kripto Mjenjačnica",
    cryptoExchangeUnderTitle11: "BlokBe kripto mjenjačnica je stvorena s ciljem da omogući korisnicima",
    cryptoExchangeUnderTitle12: "brzu i sigurnu",
    cryptoExchangeUnderTitle13: "kupovinu, prodaju i/ili zamjenu kriptovaluta.",

    cryptoExchangeTitle2: "Općenito",
    cryptoExchangeUnderTitle21: "BlokBe kripto mjenjačnica podržava",
    cryptoExchangeUnderTitle22: "preko 200",
    cryptoExchangeUnderTitle23: "raznih kriptovaluta koje možete kupiti, prodati i zamijeniti. Kripto mjenjačnica pruža jedinstven individualni pristup svakom korisniku. Također pružamo edukacijsku i stručnu pomoć svim našim korisnicima.",
    cryptoExchangeUnderTitle24: "Posjedujete kriptovalute i želite ih zamijeniti za EUR ili neku drugu valutu? Učinite to preko BlokBe kripto mjenjačnice - brzo, jednostavno i sigurno.",
    cryptoExchangeUnderTitle25: "Želite li ulagati u kriptovalute?",
    cryptoExchangeUnderTitle26: "Kontaktirajte",
    cryptoExchangeUnderTitle27: "BlokBe i mi ćemo odraditi cijeli proces za vas.",

    cryptoExchangeTitle3: "Detalji",
    cryptoExchangeUnderTitle31: "BlokBe mjenjačnica regulirana je od strane HANFA-e (HRVATSKE AGENCIJA ZA NADZOR FINANCIJSKIH USLUGA) te stoga vršimo verifikaciju svih naših korisnika, a uplate i isplate radimo isključivo transakcijski preko bankovnog računa.",


    //  CONTACT PAGE
    contactTitle: "Razgovarajmo o vašim pitanjima",
    contactUnderTitle1: "Počnite raditi s",
    contactUnderTitle2: "-om koji vam može pružiti sve što vam je potrebno da prihvatite kriptovalute kao trgovac ili da ih počnete koristiti u svakodnevnom životu.",
    contactLocation: "Adresa",
    contactForm1: "Tema",
    contactForm2: "Vaš",
    contactForm3: "Poruka",
    contactButton: "Pošalji",

    // ATM PAGE
    atmTitle1: "BANKOMAT",
    atmTitle2: "U pripremi...",

    // EDUCATION PAGE
    educationMainTitle: "Edukacija",
    educationUnderMainTitle1: "Ukoliko ste novi u svijetu kriptovaluta ili želite",
    educationUnderMainTitle2: "produbiti svoje znanje",
    educationUnderMainTitle3: ", naši stručni instruktori pružaju dragocjene uvide i praktično iskustvo kako bi vam pomogli u snalaženju u složenom krajoliku industrije digitalne imovine.",
    educationUnderMainTitle4: "Naši tečajevi pokrivaju širok raspon tema, uključujući",
    educationUnderMainTitle5: "blockchain tehnologiju, kriptovalute, novčanike, razmjenu i još mnogo toga",
    educationUnderMainTitle6: "Uz naše obrazovne resurse steći ćete solidno razumijevanje o osnovama blockchaina i kriptovaluta, kao i",
    educationUnderMainTitle7: "praktične savjete i strategije",
    educationUnderMainTitle8: "za maksimiziranje vaših ulaganja. Istražite naše tečajeve danas i započnite svoje putovanje prema tome da postanete stručnjak za kriptovalute i blockchain!",
    educationUnderMainTitle9: "pružamo lekcije kako privatnim tako i poslovnim korisnicima",
    educationUnderMainTitle10: "unaprijedite svoje poslovanje ulaganjem u edukaciju svojih zaposlenika i uvođenjem kripto sustava plaćanja za krajnje korisnike",
    
    educationTitle1: "Blockchain tehnologija",
    educationUnderTitle11: "Što je Blockchain?",
    educationUnderTitle12: "Proof of work; rudarenje, rudarske farme",
    educationUnderTitle13: "Proof of Stake",
    educationUnderTitle14: "Soft and hard fork",

    educationTitle2: "Tipovi kriptovaluta",
    educationUnderTitle21: "Što su kriptovalute?",
    educationUnderTitle22: "Bitcoin",
    educationUnderTitle23: "Altcoins, Stablecoins & Privacy coins",
    educationUnderTitle24: "NFT",
    educationUnderTitle25: "DeFi",

    educationTitle3: "Mjenjačnice kriptovaluta",
    educationUnderTitle31: "Vrsta pružatelja usluga razmjene",
    educationUnderTitle32: "Verifikacija računa",
    educationUnderTitle33: "Trgovanje i maržna trgovina",
    educationUnderTitle34: "Troškovi razmjene",
    educationUnderTitle35: "Ulog i pozajmljivanje",

    educationTitle4: "Novčanici",
    educationUnderTitle41: "Što je kripto novčanik i kako funkcionira?",
    educationUnderTitle42: "Razlika između privatnog i javnog ključa",
    educationUnderTitle43: "Vrste novčanika",
    educationUnderTitle44: "(web, mobilni, papir, desktop, hardver...)",


    // ABOUT PAGE
    aboutTitle1: "O nama",
    aboutTitle2: "Naša priča",
    aboutText1: "je fin-tech tvrtka i naša je vizija omogućiti našim klijentima da sigurno uključe kriptovalute u svoj svakodnevni život i poslovanje.",
    aboutText2: "Uzeli smo najbolje iz oba svijeta, financija i tehnologije, kako bismo ponudili jednostavnu i sigurnu kripto razmjenu, pristupnik za kripto plaćanja i edukaciju o kriptovaluti. Također nudimo mobilnu aplikaciju za jednostavno kupovanje i trošenje kriptovaluta.",
    aboutText3: "Mi smo iskusan tim u području financija i blockchain tehnologije koji nastoje učiniti svijet digitalnih valuta dostupnim svima.",
    aboutTitle3: "Naši najveći umovi",
    aboutText4: "Kako bismo osigurali najbolja rješenja i inovativne proizvode, uspostavili smo tim koji je savršena mješavina mladih talenata i iskusnih stručnjaka.",


    // PRIVACY POLICY PAGE
    privacyTitle1: "Pravila Privatnosti",
    privacyDate1: "Zadnja Izmjena :",
    privacyDate2: "31. ožujka, 2023",
    privacyLocation1: "POČETNA",
    privacyLocation2: "PRAVILA PRIVATNOSTI",

    privacyTitle2: "Uvod",
    privacyUnderTitle21: "BLOK BE d.o.o., Kapucinska ulica 31, Osijek, OIB: 98712426804 (dalje u tekstu: “BLOK BE”) je voditelj obrade po ovim Pravilima privatnosti. BLOK BE vodi platformu za razmjenu digitalne imovine za kupnju stabilnih kovanica. Izrazi 'mi,' 'nas' i 'naš' se odnose na BLOK BE i njegove podružnice. Posvećeni smo zaštiti i poštivanje vaše (“vi”, “vaš”, “kupac”) privatnosti, kao i čuvanje podataka koje posjetitelji naše web stranice i korisnici usluga dijele s nama.",
    privacyUnderTitle22: "Ova Pravila o privatnosti određuju kako prikupljamo, koristimo i upravljamo osobnim podacima koje primamo od vas ili treće strane u vezi s našim pružanjem usluga vama ili koje mi prikupljamo iz vaše upotrebe naših usluga ('Usluge') i/ili naše web stranice ('Site').",
    privacyUnderTitle23: "Pristupom i korištenjem naše usluge, prihvaćate i pristajete na praksu opisanu u našoj Politici privatnosti. Pažljivo pročitajte sljedeće. Ako se ne slažete s ovom Politikom privatnosti ne biste trebali koristiti našu stranicu i usluge.",
    privacyUnderTitle24: "Također ćemo vas zamoliti da pristanete na našu upotrebu kolačića u skladu s našom Politikom kolačića kada prvo posjetite našu web stranicu. Za više informacija o kolačićima, vrstama kolačića koje koristimo i kako ih koristimo pogledajte našu Politiku kolačića.",
    privacyUnderTitle25: "Ugradili smo čvrste sigurnosne mjere kako bismo osigurali zaštitu vaših osobnih podataka (“Osobni podaci”) protiv neovlaštene ili nezakonite obrade i upotrebe, te protiv slučajnog gubitka, uništenja, oštećenja, krađa ili otkrivanja.",
    privacyUnderTitle26: "Svako ažuriranje naših Pravila o privatnosti bit će vam priopćeno i objavljeno na našoj web stranici.",
    privacyUnderTitle27: "Ako imate bilo kakvih pitanja o našoj Politici privatnosti kako je gore navedeno, kontaktirajte nas (kontakt podaci su navedeni dolje u poglavlju “Kontaktirajte nas”).",

    privacyTitle3: "Prikupljanje i korištenje podataka",
    privacyUnderTitle31: "Prikupljamo osobne podatke koje nam dajete izravno ili koje mi generiramo kada otvorite BLOK BE račun, kada izvršite bilo koju transakciju na našoj Platformi ili koristite druge BLOK BE usluge ili našu web stranicu.",
    privacyUnderTitle32: "Podatke koje nam pružate registracijom na BLOK BE račun, korištenjem naše podrške na Stranici ili dopisivanje s nama telefonom, e-poštom ili bilo kojim drugim medijem. To može uključivati:",
    privacyUnderTitle33: "Podaci za kontakt, kao što su puno ime, kućna adresa i adresa e-pošte, datum i mjesto rođenja, broj mobilnog telefona;",
    privacyUnderTitle34: "Informacije o računu, poput korisničkog imena, lozinke, postavki računa i preferencija;",
    privacyUnderTitle35: "Financijske informacije, kao što su brojevi bankovnih računa, bankovni izvod i informacije o trgovanju;",
    privacyUnderTitle36: "Podaci za potvrdu identiteta, kao što su preslike vaše osobne iskaznice, putovnica ili vozačke dozvole;",
    privacyUnderTitle37: "Informacije o potvrdi prebivališta, poput pojedinosti o računu za komunalije ili slične informacije;",
    privacyUnderTitle38: "Informacije o načinu na koji koristite naše Usluge, kao što je kada ste koristili naše usluge i specifične usluge koje se koriste;",
    privacyUnderTitle39: "Informacije koje se odnose na komunikaciju s nama, bilo putem naše web stranice ili putem e-pošte, telefonom ili bilo kojim drugim medijem.",
    privacyUnderTitle310: "Nadalje, možemo prikupljati i obrađivati osobne podatke koje dobrovoljno i uz svoj pristanak dajete BLOK BE-u u obrascima na našoj web stranici, primjerice kada se prijavite za informacije i biltene. Pretplatu na newsletter možete odjaviti tako da otvorite jedan od BLOK BE e-mailova koje ste primili i kliknete na “unsubscribe” na dnu stranice.",
    privacyUnderTitle311: "Svaki put kada koristite naše usluge ili pristupite našoj web stranici, možemo automatski prikupljati informacije koje će nam pomoći u analizi web stranice i poboljšanju korisničkog iskustva, kao i naših usluga. Ove informacije mogu uključivati:",
    privacyUnderTitle312: "Informacije o računalu ili mobilnom uređaju, uključujući IP adresu, operativni sustav, mrežni sustav, vrsta preglednika i postavke;",
    privacyUnderTitle313: "Informacije o korištenju web stranice.",
    privacyUnderTitle314: "Neki osobni podaci mogu biti prikupljeni od trećih strana koje pružaju usluge vama ili nama, kreditne reference, usluge u svrhu sprječavanje prijevara, vladine agencije i druge financijske institucije (gdje je to dopušteno zakonom), kao što su:",
    privacyUnderTitle315: "Informacije o reputaciji;",
    privacyUnderTitle316: "Financijske reputacije;",
    privacyUnderTitle317: "Poslovne aktivnosti pravnih osoba.",
    privacyUnderTitle318: "Naše usluge također mogu uključivati integrirani sadržaj ili veze na sadržaj koji pružaju treće strane (kao što je live chat, sadržaj društvenih medija, dodaci i aplikacije). Dodatne treće strane mogu uključivati naše pridružene subjekte.",
    privacyUnderTitle319: "Za specifične i ograničene svrhe, kao što je otkrivanje i sprječavanje financijskog kriminala ili kako bi naše usluge bile dostupne korisnicima, također možemo obrađivati određene posebne kategorije podataka. Obrađivat ćemo ih samo ako smo dobili vaš izričiti pristanak ili nam je to na drugi način zakonski dopušteno. To može uključivati biometrijske podatke koji se odnose na fiziološke, fizičke ili karakteristike ponašanja osobe, uključujući prepoznavanje lica ili otiska prsta ili slične tehnologije za pranje novca i sprječavanje prijevare.",

    privacyTitle4: "Svrha obrade Osobnih Podataka",
    privacyUnderTitle41: "Podaci koje prikupljamo i koristimo služe u sljedeće svrhe:",
    privacyUnderTitle42: "Potvrda vašeg identiteta u skladu s važećim zakonima ili propisima o poznavanju vašeg klijenta, pranju novca i drugim zakonima ili propisima financijskog sektora;",
    privacyUnderTitle43: "Komunikacija i uspostavljanje odgovornog poslovnog odnosa s Vama",
    privacyUnderTitle44: "Prilagođeni sadržaj razumijevanjem Vaših potreba i vaše podobnosti za proizvode i usluge;",
    privacyUnderTitle45: "Informacije za Vas o novim proizvodima i uslugama;",
    privacyUnderTitle46: "Informacije za Vas o promjenama i poboljšanjima naše stranice;",
    privacyUnderTitle47: "Prikazivanje oglasa na drugim web stranicama",
    privacyUnderTitle48: "Obrada naplate i naplata bilo kakvih naknada;",
    privacyUnderTitle49: "Isporuka proizvoda i usluga Vama;",
    privacyUnderTitle410: "Provođenje ankete i dobivanje povratnih informacija od Vas;",
    privacyUnderTitle411: "Praćenje i poboljšanje naše stranice;",
    privacyUnderTitle412: "Usklađenost s našim zakonskim ili regulatornim obvezama",

    privacyTitle5: "Pravne osnove za obradu vaših Osobnih Podataka",
    privacyUnderTitle51: "Pravni temelj za obradu vaših osobnih podataka može biti jedno od sljedećeg:",
    privacyUnderTitle52: "Pridržavanje naših ugovora i dogovora s vama",
    privacyUnderTitle53: "Podliježemo brojnim zakonskim obvezama koje nameću relevantni zakoni zbog kojih moramo prikupljati i pohranjivati vaše osobne podatke (moramo čuvati određene podatke o našim klijentima zbog zakona protiv pranja novca) za provjeru identiteta, usklađenost sa sudom naloge, obradu plaćanja, porezne zakone ili druge obveze izvješćivanja i kontrole protiv pranja novca.",
    privacyUnderTitle54: "Često zakon zahtijeva da vas obavijestimo o određenim promjenama proizvoda ili usluga ili zakona. Možda ćemo vas morati obavijestiti o promjenama uvjeta ili značajki naših proizvoda ili usluga. Moramo obraditi vaše osobne podatke kako bismo vam poslali ove pravne obavijesti. Nastavit ćete primati ove informacije od nas čak i ako odlučite da ne želite primati izravne marketinške informacije od nas.",
    privacyUnderTitle55: "",
    privacyUnderTitle56: "",
    privacyUnderTitle57: "Zakonska obveza poštivanja 'Upoznajte svog klijenta' i regulatornih obveza dubinske analize klijenta",
    privacyUnderTitle58: "Kako bismo potvrdili vaš identitet u vezi s prihvaćanjem Vas kao našeg klijenta, možemo uključiti treće strane koje provode provjere identiteta u naše ime.",
    privacyUnderTitle59: "Gdje obrađujemo vaše osjetljive osobne podatke (ponekad poznate kao posebna kategorija osobnih podataka) kako bismo se pridržavali državnih propisa ili smjernica, kao što je naša obveza da vam pružimo podršku ako ste ili postanete ranjivi kupac.",
    privacyUnderTitle510: "Legitimni interes",
    privacyUnderTitle511: "Za praćenje i poboljšanje naše web stranice i usluga, možemo prikupljati i koristiti vaše osobne podatke, mi ili treća strana. Postoje različiti komercijalni ili poslovni razlozi za korištenje vaših osobnih podataka, kao što su sigurnost sustava, prevencija kriminala, sigurnost imovine, razvoj proizvoda i usluga, AML ili upravljanje rizicima.",
    privacyUnderTitle512: "Možda ćemo trebati koristiti osobne podatke prikupljene od Vas kako bismo istražili probleme ili riješili sporove s Vama jer je naš legitimni interes osigurati da se problemi i sporovi istraže i riješe na pravovremen i učinkovit način.",
    privacyUnderTitle513: "Možda ćemo morati upotrijebiti vaše osobne podatke kako bismo bili u skladu s primjenjivim zakonima i propisima, sudski pozivi, sudski nalozi ili drugi sudski procesi ili zahtjevi bilo kojeg primjenjivog regulatornog tijela. Ovo ne činimo samo kako bismo ispunili svoje zakonske obveze, već zato što bi to moglo biti i u našem legitimnom interesu.",
    privacyUnderTitle514: "Stranice i e-pošta naše web stranice mogu sadržavati web svjetionike ili pikselne oznake ili bilo koje druge slične vrste alata za analizu podataka koji nam omogućuju praćenje primitka korespondencije i brojenje broja korisnika koji su posjetili našu web stranicu ili otvorili našu korespondenciju. Vaše osobne podatke možemo prikupiti s osobnim podacima naših drugih klijenata na anonimnoj osnovi (to jest, s uklonjenim vašim osobnim identifikatorima), tako da nas rigoroznija statistička analiza općih obrazaca može dovesti do pružanja boljih proizvoda i usluga. Ako su vaši osobni podaci potpuno anonimizirani, nije nam potrebna pravna osnova jer podaci više neće predstavljati osobne podatke. Ako vaši osobni podaci nisu u anonimiziranom obliku, u našem je legitimnom interesu kontinuirano procjenjivati te osobne podatke kako bismo osigurali da su proizvodi i usluge koje pružamo relevantni za tržište.",
    privacyUnderTitle515: "Možda ćemo trebati obraditi vaše osobne podatke za interne poslovne i istraživačke svrhe, kao i za potrebe vođenja evidencije. Takva je obrada u našem vlastitom legitimnom interesu i potrebna je kako bismo ispunili svoje zakonske obveze. To može uključivati bilo koju komunikaciju koju imamo s vama u vezi s proizvodima i uslugama koje vam pružamo i našim odnosom s vama. Također ćemo voditi evidenciju kako bismo osigurali da se pridržavate svojih ugovornih obveza u skladu sa ugovorom ('Uvjeti pružanja usluge') koji regulira naš odnos s vama.",
    privacyUnderTitle516: "Vaše osobne podatke možemo koristiti kako bismo vam slali marketinške komunikacije e-poštom ili drugim dogovorenim oblicima (uključujući kampanje na društvenim mrežama), kako bismo osigurali da uvijek budete u tijeku s našim najnovijim proizvodima i uslugama. Ako vam šaljemo marketinške komunikacije, to ćemo učiniti na temelju vašeg pristanka i registriranih marketinških postavki.",
    privacyUnderTitle517: "Pristanak",
    privacyUnderTitle518: "Kada pristajete da prikupljamo vaše osobne podatke. Općenito, ne oslanjamo se na privolu kao pravnu osnovu za obradu vaših osobnih podataka osim u vezi s našom upotrebom kolačića ili kada vam šaljemo izravnu marketinšku komunikaciju treće strane putem e-pošte ili tekstualne poruke.",
    privacyUnderTitle519: "U pogledu osobnih podataka koje obrađujemo temeljem Vašeg pristanka, skrećemo Vam pozornost da Vaš pristanak možete povući u bilo kojem trenutku. U tom slučaju prestat ćemo obrađivati te osobne podatke, ali samo za razdoblje nakon povlačenja pristanka i samo one osobne podatke koje ne obrađujemo na nekom drugom pravnom temelju.",

    privacyTitle6: "Sigurnost informacija",
    privacyUnderTitle61: "Sigurnost i povjerljivost Vaših osobnih podataka od najveće su nam važnosti. Kako bismo to osigurali, koristimo niz sigurnosnih mjera:",
    privacyUnderTitle62: "Samo ovlaštenom osoblju dopušten je pristup vašim osobnim podacima i dužan je tretirati informacije kao vrlo povjerljive;",
    privacyUnderTitle63: "Osposobljavanje osoblja za zaštitu podataka i informacijsku sigurnost;",
    privacyUnderTitle64: "Imenici i baze podataka zaštićeni lozinkom;",
    privacyUnderTitle65: "Politike i procedure sigurnosti i zaštite podataka;",
    privacyUnderTitle66: "Tehnologija Secure Sockets Layered (SSL) kako bi se osiguralo da su vaši podaci potpuni, šifrirano i sigurno poslano putem interneta;",
    privacyUnderTitle67: "2-faktorska autentifikacija;",
    privacyUnderTitle68: "Skeniranje ranjivosti za aktivnu zaštitu naših poslužitelja od hakera i drugih ranjivosti;",
    privacyUnderTitle69: "Sve financijski osjetljive i/ili kreditne informacije prenose se putem SSL tehnologije i šifrirano u našoj bazi podataka;",
    privacyUnderTitle610: "Stalni sigurnosni pregled i poboljšanje;",
    privacyUnderTitle611: "Ostale mjere za ublažavanje rizika utvrđenih tijekom procesa procjene rizika.",
    privacyUnderTitle612: "Međutim, važno je napomenuti da ne možemo jamčiti apsolutnu sigurnost vaših podataka. Štoviše, nismo odgovorni za sigurnost informacija koje nam prenosite preko mreža koje ne kontroliramo, uključujući internet i bežične mreže.",
    privacyUnderTitle613: "Vi ste odgovorni za sigurno i sigurno čuvanje lozinke i PIN brojeva Vašeg BLOK BE računa. Nikada ne dijelite podatke o svom računu ni s kim drugim niti im dopustite da se prijave kao Vi. Ako dođe do neovlaštene upotrebe ili bilo koje druge povrede sigurnosti koja uključuje Vaše podatke, morate nas obavijestiti što je prije moguće (pogledajte Kontaktirajte nas).",

    privacyTitle7: "Razotkrivanja",
    privacyUnderTitle71: "Vaši se podaci mogu dijeliti s trećim stranama te pravnim i regulatornim tijelima samo ako je to potrebno za zakonito obavljanje naših poslovnih aktivnosti kao što su:",
    privacyUnderTitle72: "Uz vaš pristanak ili na vaš zahtjev;",
    privacyUnderTitle73: "Kada se to zahtijeva u skladu s važećim zakonom, propisima ili pravilima;",
    privacyUnderTitle74: "Gdje je to potrebno za pružanje usluga Vama;",
    privacyUnderTitle75: "Za zaštitu prava, imovine ili sigurnosti nas, naših klijenata ili drugih;",
    privacyUnderTitle76: "S drugim financijskim institucijama u vezi s pogrešnim plaćanjem ili financijskim kriminalom;",
    privacyUnderTitle77: "S trećim stranama koje nam pružaju usluge, poput analize tržišta, dopisnika banke, agencije za sprječavanje prijevara i drugi agenti koji djeluju u naše ime;",
    privacyUnderTitle78: "Ako naši legitimni poslovni interesi zahtijevaju otkrivanje;",
    privacyUnderTitle79: "U anonimiziranom obliku kao dio statistike ili drugih agregiranih podataka koji se dijele s trećima stranke; ili",
    privacyUnderTitle710: "Za provedbu ili primjenu naših Uvjeta pružanja usluge i drugih ugovora.",
    privacyUnderTitle711: "Vaši se podaci neće prodavati, razmjenjivati ili dijeliti s bilo kojim trećim stranama bez vašeg pristanka, osim za pružanje usluga BLOK BE ili u skladu sa zakonom. Korištenjem naših usluga i prihvaćanjem naših Uvjeta pružanja usluge, pristajete na otkrivanje Vaših osobnih podataka kako je opisano u ovim Pravilima privatnosti.",

    privacyTitle8: "Prijenos vaših podataka",
    privacyUnderTitle81: "Svi podaci koje nam dostavite pohranjuju se na našim sigurnim poslužiteljima. Tamo gdje svoje podatke prenosimo izvan EGP-a, osiguravamo da postoje odgovarajuće zaštitne mjere.",
    privacyUnderTitle82: "Vaši osobni podaci obrađuju se u EGP-u, ali se mogu prenositi diljem svijeta zbog nekih naših pružatelja usluga trećih strana koji su angažirani u naše ime. Osobni podaci i drugi podaci stoga se mogu izvesti izvan jurisdikcije u kojoj živite. Vaši osobni podaci mogu biti obrađeni i pohranjeni u stranoj zemlji ili zemljama. Pod tim okolnostima, vlade, sudovi, tijela za provedbu zakona ili regulatorne agencije te zemlje ili tih zemalja mogu dobiti pristup vašim osobnim podacima putem stranih zakona. Morate biti svjesni da standardi privatnosti tih zemalja mogu biti niži od standarda jurisdikcije u kojoj živite. Uvijek ćemo se pobrinuti da svaki prijenos vaših osobnih podataka bude zakoniti da su Izvršitelji obrade podataka u trećim zemljama obvezni pridržavati se Opće uredbe (EU) 2016/679 o zaštiti osobnih podataka (GDPR) i Zakona o provedbi opće uredbe o zaštiti podataka.",

    privacyTitle9: "Zadržavanje podataka",
    privacyUnderTitle91: "Postavili smo pravila i postupke za zadržavanje podataka kako bismo zadržali sve osobne podatke ne dulje nego što zahtijevaju primjenjivi zakoni ili propisi, u svrhe za koje su dobiveni.",
    privacyUnderTitle92: "Razdoblja čuvanja zapisa određuju se na temelju vrste zapisa, prirode aktivnosti, proizvoda ili usluge, zemlje i primjenjivih lokalnih zakonskih ili regulatornih zahtjeva.",
    privacyUnderTitle93: "Kada se korisnički račun ukine ili istekne, možda ćemo morati održavati evidenciju tijekom značajnog vremenskog razdoblja, budući da podliježemo određenim zakonima protiv pranja novca koji od nas zahtijevaju da zadržimo sljedeće, u razdoblju od 10 godina nakon što je poslovni odnos s Vama prekinut.",
    privacyUnderTitle94: "Razdoblja čuvanja mogu se povremeno promijeniti (ili odbaciti ako se smatraju niskim rizikom) na temelju poslovnih ili zakonskih i regulatornih zahtjeva.",
    privacyUnderTitle95: "Također ćemo izbrisati Vaše podatke na vaš zahtjev, osim ako ih nismo obvezni čuvati u skladu s našim zakonskim obvezama. Više o tome možete saznati ako nas kontaktirate.",

    privacyTitle10: "Vaša prava",
    privacyUnderTitle101: "Možete pristupiti, provjeriti, ispraviti i ažurirati svoje osobne podatke koje imamo. Obavijestite nas u bilo kojem trenutku da su se vaši osobni podaci promijenili. U nekim slučajevima trebamo popratne dokumente od vas kao dokaz tj. osobne podatke za regulatorne ili druge pravne svrhe. Možete dobiti kopiju osobnih podaka koje imamo o Vama.",
    privacyUnderTitle102: "Možete zatražiti brisanje svojih osobnih podataka koje imamo. Možemo odbiti zahtjev za brisanjem ako i dalje trebamo vaše osobne podatke radi usklađenosti sa zakonom. Ako smo mi otkrili vaše osobne podatke drugima, obavijestit ćemo ih o tome zahtjevati brisanje gdje je to moguće.",
    privacyUnderTitle103: "Možete zatražiti od nas da obustavimo ili blokiramo obradu vaših osobnih podataka u određenim okolnostima. Na primjer, možda želite utvrditi točnost vašeg Osobnih podataka ili se ne slažete s razlogom njihove obrade. Ako smo objelodanili osobne podatke drugima, obavijestit ćemo ih o ograničenju obrada ako je moguće.",
    privacyUnderTitle104: "Možete zatražiti prijenos određenog dijela Vaših osobnih podataka drugoj strani.",
    privacyUnderTitle105: "Imate pravo tražiti preispitivanje bilo koje odluke o vama, tj. na temelju automatiziranog procesa, koji utječe na vašu mogućnost pristupa našim proizvodima ili usluge ili ima neki drugi značajan učinak na vas i zahtijeva ljudsku intervenciju.",
    privacyUnderTitle106: "Ako obrađujemo vaše osobne podatke na temelju legitimnog interesa (ili interesa a treće strane) možete osporiti ovo. Međutim, možda imamo pravo nastaviti obradu vaših podataka na temelju naših legitimnih interesa ili gdje je to relevantno na pravne zahtjeve. Također imate pravo prigovora gdje obrađujemo vaše Osobne podatke za izravne marketinške svrhe.",
    privacyUnderTitle107: "Imate pravo podnijeti pritužbu nadzornom tijelu, kako biste izvršili svoja prava, kako je gore navedeno.",


    privacyTitle11: "Marketing",
    privacyUnderTitle111: "Vijesti i ažuriranja BLOK BE tvrtke, kao i relevantne marketinške informacije mogu biti priopćene Vama. To ćemo učiniti samo ako ste nam dali svoj pristanak ili inače tamo gdje nam je to dopušteno zakonom u potrazi za našim legitimnim interesima kao što je promicanje naših usluga. Također možemo organizirati natjecanje, promociju ili anketu.",
    privacyUnderTitle112: "Ako ne želite primati marketinški materijal od BLOK BE u bilo kojem obliku komunikacije, možete se odjaviti u bilo kojem trenutku:",
    privacyUnderTitle113: "Kliknite ”odjavi pretplatu” na dnu e-poruke koju smo vam poslali",
    privacyUnderTitle114: "Kontaktirajte nas i zatražite isključenje.",
    privacyUnderTitle115: "Imajte na umu da Vas i dalje možemo kontaktirati za ažuriranja statusa računa i aktivnosti, upite za ankete poštivanje proizvoda i usluga, odgovaranje na vaše upite ili pritužbe i sl.komunikacije koje smo Vam dostavili nakon što ste odjavili pretplatu.",

    privacyTitle12: "Ažuriranje Pravila Privatnosti",
    privacyUnderTitle121: "Naša Pravila o privatnosti mogu se revidirati, modificirati i/ili ažurirati, bez prethodne najave. Pregledavamo ga redovito kako bismo bili sigurni da smo uzeli u obzir sve nove obveze i tehnologije vezane uz naše poslovanje. Sve promjene koje napravimo bit će objavljene na ovom mjestu, pa je preporučljivo da ga često pregledavate.",

    privacyTitle13: "Kontaktirajte Nas",
    privacyUnderTitle131: "Ako imate bilo kakvih pitanja ili komentara u vezi s ovim Pravilima privatnosti i/ili praksama, slobodno nas kontaktirajte na sljedeću e-mail adresu: [support@blokbe.com], ili poštom na adresu Kapucinska ulica 31, Osijek. Ako niste zadovoljni načinom na koji obrađujemo Vaše osobne podatke ili ako Vam ne pružimo zadovoljavajući odgovor na Vaš zahtjev ili prigovor, možete se obratiti Agenciji za zaštitu osobnih podataka, Selska cesta 136, Zagreb, e-mail: azop@azop.hr.",


        // TERMS OF SERVICE PAGE
        termsOfServiceTitle1: "Uvjeti Pružanja Usluge",
        termsOfServiceDate1: "Zadnja izmjena :",
        termsOfService1Date2: "31. ožujka, 2023",
        termsOfServiceLocation1: "POČETNA",
        termsOfServiceLocation2: "UVJETI PRUŽANJA USLUGE",
    
        termsOfServiceTitle2: "Uvod",
        termsOfServiceUnderTitle21: "Ovaj pravni dokument je važeći i pravno obvezujući ugovor između vas kao fizičke ili pravne osobe i tvrtke BLOK BE ('Ugovor'). Ako se ne slažete s ovim Uvjetima pružanja usluge, navedenim u ovom Ugovoru, nemojte pristupati našoj stranici i uslugama. Kupac može odmah raskinuti Ugovor pisanom obavijesti BLOK BE ili ga može raskinuti BLOK BE u skladu sa svim odredbama ovog ugovora.",
        termsOfServiceUnderTitle22: "je trgovačko društvo osnovano prema hrvatskim zakonima, matični broj 030237716, registrirano na adresi: Kapucinska 31, Osijek.",
        termsOfServiceUnderTitle23: "Imajte na umu da možda nećete moći koristiti sve funkcije stranice ili usluga, što će ovisiti o vašoj zemlji prebivališta. Također imajte na umu da u nekim jurisdikcijama upotreba kriptovaluta može biti protuzakonita. Stoga je vaša odgovornost pridržavati se pravila i zakona u vašoj zemlji prebivališta i/ili zemlji iz koje pristupate ovom web-mjestu i/ili aplikaciji i uslugama koje nudimo.",
    
        termsOfServiceTitle3: "Podobnost",
        termsOfServiceUnderTitle31: "Otvaranjem BLOK BE računa i/ili korištenjem naše stranice i/ili aplikacije izričito se slažete da ste pročitali, u potpunosti razumjeli i prihvatili naše Uvjete pružanja usluge, Politiku kolačića i Politiku privatnosti. Nadalje, jamčite da ste odgovarajuće dobi i da ste potpuno sposobni prihvatiti ove Uvjete pružanja usluge i ući u transakciju koja uključuje digitalnu imovinu.",
        termsOfServiceUnderTitle32: "Također morate biti stanovnik zemlje u kojoj su usluge BLOK BE dostupne i dopuštene. Popis tih zemalja možete pronaći ovdje. Što se tiče poslovnih korisnika BLOK BE (korporativnih klijenata), dostupnost usluga ovisit će o vašoj zemlji osnivanja i adresi registriranog ureda.",
        termsOfServiceUnderTitle33: "",
    
        termsOfServiceTitle4: "Prava i ograničenja naše Stranice i Usluga",
        termsOfServiceUnderTitle41: "Naziv BLOK BE, web stranica www.blokbe.com, logotipi i sva autorska prava i druga prava intelektualnog vlasništva u svim materijalima na ovoj stranici su vlasništvo BLOK BE, osim ako mi drugačije ne odredimo, i zaštićeni su autorskim pravima, zaštitnim znakom i drugi zakoni o pravima intelektualnog vlasništva. Nije vam dopušteno njihovo kopiranje, oponašanje ili korištenje bez našeg prethodnog pisanog pristanka ili autorskih prava, poslovne tajne, patenta ili drugih prava intelektualnog vlasništva ili prava javnosti ili privatnosti bilo koje treće strane.",
        termsOfServiceUnderTitle42: "Sve dok se slažete s ovim Uvjetima pružanja usluge i pridržavate ih se, BLOK BE vam daje osobno, neisključivo, neprenosivo, nepodlicencirano i ograničeno pravo da uđete i koristite Stranicu i Usluge, isključivo u odobrene svrhe kako to dopušta BLOK BE. Suglasni ste da nećete kopirati, distribuirati, prenositi, prodavati, mijenjati, objavljivati ili na bilo koji drugi način koristiti naše materijale ili ih koristiti drugačije nego u predviđene svrhe; svako takvo neovlašteno korištenje predstavlja kršenje autorskih prava, zaštitnih znakova i drugih primjenjivih zakona i može rezultirati kaznenim ili građanskim kaznama. Pod uvjetom da se pridržavate svih obavijesti o autorskim pravima i drugim pravima vlasništva, možete slobodno pregledavati, ispisivati i/ili preuzimati kopije materijala s ove stranice, isključivo za vašu osobnu, informativnu i/ili nekomercijalnu upotrebu. Korištenje naših materijala u bilo koju drugu svrhu osim navedene u ovim Uvjetima usluge strogo je zabranjeno i krši autorsko pravo, zaštitni znak i druge primjenjive zakone te može dovesti do kaznenih ili građanskih kazni. Upućivanje na bilo koji proizvod, uslugu, proces ili drugu informaciju, imenom, zaštitnim znakom, proizvođačem, dobavljačem ili na neki drugi način, ne predstavlja niti implicira našu podršku, sponzorstvo ili preporuku",
        termsOfServiceUnderTitle43: "Slažete se i prihvaćate isključivu odgovornost za sadržaj koji nam dajete, na našoj stranici ili putem komunikacije s nama, uključujući ali ne ograničavajući se na informacije i materijale dostavljene u vezi s vašim profilima, upitima, zahtjevima za informacijama, komentarima i BLOK-om BE isključuje svoju punu odgovornost za to.",
        termsOfServiceUnderTitle44: "Ništa u ovim Uvjetima pružanja usluge ne daje vam nikakvu licencu (osim kako je navedeno u ovom odlomku), pravo, naslov ili vlasništvo nad, ili nad Stranicom, bilo kojom od Usluga, autorskih prava ili znakova.",
    
        termsOfServiceTitle5: "Naše Usluge",
        termsOfServiceUnderTitle51: "BLOK BE svojim klijentima pruža uslugu razmjene digitalne imovine te kupnju kriptovaluta ne ograničavajući se na razne stabilne tokene, dostupne na našoj web stranici na",
        termsOfServiceUnderTitle5111: ". BLOK BE isto može pružiti putem online platforme, mobilne aplikacije te direktnom suradnjom s korisnikom.",

        termsOfServiceUnderTitle52: "Našim korisnicima nudimo kreiranje BLOK BE računa koji im omogućuje:",
        termsOfServiceUnderTitle53: "Kupnju digitalne imovine za fiat novac putem opcije plaćanja debitnom/kreditnom karticom",
        termsOfServiceUnderTitle54: "Primanje digitalne imovine na njihovu navedenu adresu novčanika digitalnih sredstava",
        termsOfServiceUnderTitle55: "Kupnja digitalne imovine",
        termsOfServiceUnderTitle56: "Možete kupiti digitalnu imovinu putem BLOK BE samo koristeći valjane metode plaćanja koje smo mi naveli. Kupnju možete izvršiti putem naše mobilne aplikacije, dostupne za Android i iOS. Koristimo se našim pružateljima usluga bankovnog i kartičnog plaćanja kako bismo primili sredstva korisnika.",
        termsOfServiceUnderTitle57: "Prilikom postavljanja narudžbe za kupnju digitalnih sredstava, prikazat će vam se unaprijed definirani paketi (naziv i cijena) koje možete izabrati. Nakon što odaberete željeni nalog, odnosno iznos sredstava koji ste dužni uplatiti, prikazat će vam se iznos digitalne imovine koji ćete za njega dobiti. Imate mogućnost prihvatiti narudžbu u određenom roku i time izraziti razumijevanje i slaganje s našim tečajem. Nakon što potvrdite svoju narudžbu, mi ćemo prenijeti kupljenu digitalnu imovinu, što je prije moguće, na vašu naznačenu adresu novčanika za digitalnu imovinu, koju ste naveli u narudžbenici. Nakon što vam pošaljemo vašu digitalnu imovinu, morat će je potvrditi mreža digitalne imovine, što može potrajati određeno vrijeme.",
        termsOfServiceUnderTitle58: "BLOK BE ne jamči da ćete dobiti točan iznos digitalne imovine naveden unarudžbenica. Slažete se s prihvaćanjem naše kupoprodajne cijene kada autorizirate transakciju, ali kupnja ovisi o stvarnom primitku sredstava s naše strane, izvršenom valjanom metodom plaćanja s vaše strane, kao i o plaćanju svih primjenjivih naknada. Imajte na umu da vam vaša banka ili davatelj kartice može naplatiti dodatnu naknadu.",
        termsOfServiceUnderTitle59: "Zadržavamo pravo da ispravimo sve pogreške koje se pojave uključujući, ali ne ograničavajući se na naše usluge, u potvrdi narudžbenice i obradi kupnje, revidiranjem vaše kupovne transakcije u skladu s tim ili otkazivanjem kupnje i povratom bilo kojeg primljenog iznosa.",
        termsOfServiceUnderTitle510: "Suglasni ste da su sve kupnje konačne nakon što ih odobrite. Nakon što kliknete za potvrdu svoje narudžbe, od vas će se tražiti da je ponovno potvrdite unosom svog PIN broja ili putem Touch/Face ID-a. Stoga je mogućnost pogreške značajno smanjena. BLOK BE ne prihvaća nikakve povrate niti pruža povrat novca za vašu kupnju digitalnih sredstava. Nakon što pošaljemo narudžbu na navedenu adresu digitalnih sredstava koju ste naveli u narudžbenici, ona se ne može opozvati niti dohvatiti ni pod kojim okolnostima.",
        termsOfServiceUnderTitle511: "U slučaju da svojoj banci podnesete postupak povrata uplate, zadržavamo pravo na bilo koji postupak pretrpljenih financijskih gubitaka, kao što jesu, ali ne ograničavajući se na administrativne pristojbe koje naplaćuje stjecatelj kartice i kartične sheme.",
        termsOfServiceUnderTitle512: "BLOK BE ima pravo od vas tražiti potvrdu/jamstvo da je naznačena adresa digitalnog novčanika koju ste unijeli doista vaša.",
        termsOfServiceUnderTitle513: "Prilikom slanja narudžbenice, morat ćete navesti adresu novčanika za digitalnu imovinu na koju želite primiti digitalnu imovinu. Nakon što od vas primi narudžbu za kupnju, BLOK BE će dovršiti narudžbu kupnjom digitalnih sredstava u vaše ime i slanjem na adresu novčanika digitalnih sredstava koju ste naveli u narudžbi.",
        termsOfServiceUnderTitle514: "Dodatno, BLOK BE ne pruža uslugu investicijskog savjetovanja u vezi s našim uslugama. Vaša je isključiva odluka o kupnji digitalnih sredstava, au slučaju bilo kakvih gubitaka, BLOK BE neće biti odgovoran.",
        termsOfServiceUnderTitle515: "VAŽNO: Imajte na umu da su sve kupnje nepovratne nakon što se digitalna imovina prenese na određeni račun novčanika digitalne imovine.",
    
        termsOfServiceTitle6: "Blok Be Račun",
        termsOfServiceUnderTitle61: "Da biste kreirali BLOK BE račun, morate se registrirati na našoj platformi koristeći svoje ime, e-mail, kreirati lozinku računa i dati sve potrebne podatke. Korištenjem naših usluga slažete se da ćete ih koristiti samo za sebe, a ne u ime bilo koje treće strane. Vaša je odgovornost za sve aktivnosti koje se odvijaju pod vašim računom, stoga preporučujemo da pažljivo održavate sigurnost pristupa svom računu i da nas odmah obavijestite ako sumnjate na bilo kakvo kršenje sigurnosti povezanog s vašim računom kod nas. Prihvaćate sve rizike bilo kakvog ovlaštenog ili neovlaštenog pristupa vašem BLOK BE računu, u najvećoj mjeri dopuštenoj zakonom.",
        termsOfServiceUnderTitle62: "Preporučujemo vam da izradite snažnu lozinku, s kombinacijom velikih i malih slova, brojeva i posebnih znakova, koju ne koristite ni za jednu drugu web stranicu ili online uslugu.",
    
        termsOfServiceTitle7: "Provjera identiteta",
        termsOfServiceUnderTitle71: "Omogućena 2-faktorska autentifikacija i biometrijska autentifikacija (Touch/Face ID) koju BLOK BE koristi za sve kupnje su obavezne. Više o tome možete pročitati u odjeljku Sigurnost, dalje u nastavku.",
        termsOfServiceUnderTitle72: "Provjera klijenta obavezan je postupak zbog pravila protiv pranja novca ('AML') i naših postupaka Upoznajte svog klijenta ('KYC') kojih se moramo pridržavati. Možemo surađivati s dobavljačima trećih strana kako bismo potvrdili vaš identitet. Postupak provjere ovisit će, među ostalim kriterijima, o iznosu narudžbe. Od vas će se tražiti dokaz vašeg identiteta koji je izdala vlada, a možda ćemo tražiti i selfie fotografiju. Ostale informacije koje od vas možemo zatražiti uključuju, ali nisu ograničene na, podatke za kontakt, financijske podatke informacije, selfie fotografiju i druge informacije za provjeru identiteta. Suglasni ste pružiti točne i istinite informacije i obavijestiti nas o svim promjenama dokumenata za provjeru. Više o tome možete pročitati u našoj KYC/AML politici.",
        termsOfServiceUnderTitle73: "Naši poslovni klijenti morat će pružiti određene podatke o samoj tvrtki, o stvarnim vlasnicima koji drže više od 25% tvrtke, ovlaštenim predstavnicima te će također morati dostaviti svu dokumentaciju potrebnu za razumijevanje vlasničke strukture ako je potrebno. Zahtjevi će biti definirani u KYC/AML politici tvrtke.",
        termsOfServiceUnderTitle74: "Možemo, prema vlastitom nahođenju, odbiti otvoriti BLOK BE račun za vas ili ograničiti broj računa koje možete imati ili suspendirati ili ukinuti bilo koji račun, posebno ako ne pružite bilo kakve podatke koje smatramo potrebnima za vašu prijavu za BLOK BE račun, zbog KYC i AML zakona i propisa.",
        termsOfServiceUnderTitle75: "Zadržavamo pravo provođenja upita, sami ili putem trećih strana, u vezi s provjerom vašeg identiteta ili vaše i/ili naše zaštite od prijevare ili drugog financijskog kriminala. To bi moglo dovesti do otkrivanja vaših osobnih podataka agencijama za kreditne reference i sprječavanje prijevara ili financijskim kriminalom, na što ste suglasni prihvaćanjem ovih Uvjeta pružanja usluge.",
        termsOfServiceUnderTitle76: "Za detaljne informacije o tome kako prikupljamo, obrađujemo, koristimo i otkrivamo vaše osobne podatke, pogledajte našu Politiku privatnosti. Potvrđujete da ste pročitali i razumjeli naša",
        termsOfServiceUnderTitle761: "Pravila privatnosti",
        termsOfServiceUnderTitle762: "prije nego što nam date takve osobne podatke. Kako nam je vaša privatnost od najveće važnosti, preporučujemo da je pažljivo pročitate kako biste razumjeli na koji način čuvamo vaše osobne podatke.",

        termsOfServiceTitle8: "Sigurnost",
        termsOfServiceUnderTitle81: "Prihvaćate da ste odgovorni za sve kupnje narudžbi koje su izvršene korištenjem vašeg BLOK BE računa. 2-faktorska autentifikacija mora biti omogućena prilikom pristupa vašem BLOK BE računu i naručivanja. Svaku narudžbu morate potvrditi s vaše strane, unosom vašeg PIN broja ili biometrijskom autentifikacijom, odnosno autorizacijom otiska prsta ili face ID-a s vaše strane.",
        termsOfServiceUnderTitle82: "Vaša je isključiva odgovornost čuvati sigurnost podataka vašeg računa. Preporučujemo da koristite snažnu lozinku koju ne koristite ni za jednu drugu uslugu. Ne dijelite podatke o svom računu ni s kim i uvijek ih čuvajte sigurnima.",
        termsOfServiceUnderTitle83: "BLOK BE neće biti odgovoran za bilo kakav gubitak ili štetu uzrokovanu izgubljenim ili ukradenim podacima računa, ili neovlaštenim pristupom vašem BLOK BE računu ili vašoj adresi e-pošte. Ako sumnjate na bilo koju od ovih prijevarnih aktivnosti, odmah nas kontaktirajte na",
    
        termsOfServiceTitle9: "Ograničenja kupnje",
        termsOfServiceUnderTitle91: "BLOK BE je implementirao ograničenja kupnje za svoje kupce na periodičnoj (dnevnoj, mjesečnoj) osnovi. Na temelju naših internih politika i pravila za AML i CFT, može postojati ograničenje maksimalnog dnevnog depozita ili maksimalnog mjesečnog depozita za kupnju digitalne imovine.",
        termsOfServiceUnderTitle92: "Također možemo ograničiti broj narudžbi izvršenih platnom karticom po danu. Ograničenja mogu varirati ovisno o različitim čimbenicima kao što jesu, ali ne ograničavajući se na razinu verifikacije i način plaćanja.",
        termsOfServiceUnderTitle93: "Svoja ograničenja možete vidjeti prijavom na svoj račun u našoj aplikaciji. Ako želite, možete zatražiti veća ograničenja kupnje tako da nas kontaktirate na",
        termsOfServiceUnderTitle94: "Možemo zatražiti dodatnu dokumentaciju kako bismo valjano odlučili o ishodu vašeg zahtjeva. Možemo odbiti vaš zahtjev za povećanjem vaših limita ili ih čak smanjiti zbog naših pravila o sprječavanju pranja novca i financiranja terorizma i drugih pravila. Zadržavamo pravo promjene ograničenja kupnje ako smatramo potrebnim.",
    
        termsOfServiceTitle10: "Zatvaranje Računa",
        termsOfServiceUnderTitle101: "U bilo kojem trenutku imate pravo zatvoriti svoj BLOK BE račun. Ako iz bilo kojeg razloga više ne želite imati račun kod nas, kontaktirajte nas na",
        termsOfServiceUnderTitle1011: ". BLOK BE će nastaviti sa zatvaranjem vašeg računa, osim ako je u tijeku istraga u vezi s vašim BLOK BE računom.",
        termsOfServiceUnderTitle102: "Imajte na umu da ćete ostati odgovorni za sve radnje poduzete prije zatvaranja vašeg BLOK BE računa.",
        termsOfServiceUnderTitle103: "Osobni podaci pohranjuju se, arhiviraju i taje u skladu sa zakonskim i drugim propisima ili odlukama ili izbornim aktom voditelja obrade. osobni podaci se brišu nakon isteka svih zakonskih obveza vezanih uz pohranu osobnih podataka.",
    
        termsOfServiceTitle11: "Obustava računa, gašenje, ograničenje i promjena usluga",
        termsOfServiceUnderTitle111: "BLOK BE zadržava pravo obustaviti, ograničiti, promijeniti ili prekinuti vaše korištenje naših usluga bez prethodne obavijesti, u bilo kojem trenutku, uključujući, ali ne ograničavajući se na sljedeće slučajeve:",
        termsOfServiceUnderTitle112: "U slučaju da prekršite bilo koji od ovih Uvjeta pružanja usluge ili se uključite u bilo koju ograničenu aktivnost.",
        termsOfServiceUnderTitle113: "Kada ste nam dostavili lažne ili pogrešne informacije ili nam niste dostavili dokumentaciju koju smo tražili kako bismo bili u skladu s propisima AML i KYC",
        termsOfServiceUnderTitle114: "Imamo sumnju na pranje novca, prijevaru, financiranje terorizma ili bilo koji drugi financijski kriminal.",
        termsOfServiceUnderTitle115: "Gdje razumno vjerujemo da smo to dužni učiniti kako bismo postupali u skladu sa zakonom, sudskim nalogom, propisom ili bilo kojom ovlašću kojoj podliježemo u bilo kojoj jurisdikciji , kao i zahtjevima nadležnih javnih i lokalnih vlasti",
        termsOfServiceUnderTitle116: "Gdje razumno vjerujemo da je naša sigurnost ili bi mogla biti potencijalno ugrožena, ili je vaša upotreba vašeg BLOK BE računa štetna za nas, ili se koristi na bilo koji prijevaran ili neovlašten način",
        termsOfServiceUnderTitle117: "Tamo gdje razumno vjerujemo da moramo zaštititi vaše osobne podatke vašeg BLOK BE računa, u vezi s podacima koji su ukradeni ili izgubljeni",
        termsOfServiceUnderTitle118: "Kako bismo zaštitili svoj ugled",
        termsOfServiceUnderTitle119: "Ako je u tijeku istraga u vezi s vašim BLOK BE računom",
        termsOfServiceUnderTitle1110: "Ako zatražite znatno veću narudžbenicu digitalne imovine",
        termsOfServiceUnderTitle1111: "Ako ste naš poslovni klijent i proglašen je stečaj ili nesolventnost, likvidacija, ili se odvija sličan događaj",
        termsOfServiceUnderTitle1112: "Ako ste naš poslovni klijent i došlo je do promjene stvarnog vlasništva izdanog temeljnog kapitala Vašeg poduzeća",
        termsOfServiceUnderTitle1113: "Ako ste naš poslovni klijent i osoba koja je zakonski zastupnik Vašeg poduzeća zamijenjena",
        termsOfServiceUnderTitle1114: "U drugim prilikama koje zahtijevaju naše sudjelovanje kako bismo bili u skladu sa zakonima i propisima, te sigurnosti, kako vašeg računa tako i BLOK BE",
        termsOfServiceUnderTitle1115: "Suglasni ste da nismo odgovorni za bilo kakve gubitke nastale u vezi s obustavom računa. Zadržavamo pravo ograničiti vam pristup određenim dijelovima naših usluga, prema našem razumnom uvjerenju da to predstavlja rizik za našu tvrtku. Ograničenja možemo poništiti ako procijenimo da su za to ispunjeni uvjeti.",
        termsOfServiceUnderTitle1116: "BLOK BE zadržava pravo ukinuti vaš BLOK BE račun u bilo kojem trenutku, uz prethodnu obavijest. Međutim, ne moramo vam dati nikakve dodatne informacije o ukidanju ili obustavi vašeg računa. Slažete se da zbog naših postupaka sigurnosti i upravljanja rizicima nemamo obvezu dalje otkrivati bilo kakve pojedinosti.",
    
        termsOfServiceTitle12: "Skup klauzula",
        termsOfServiceUnderTitle121: "Zadržavamo pravo da dobijemo kompenzaciju za sve nepodmirene iznose. Ako nam dugujete naknade ili bilo koji drugi iznos, iskoristit ćemo svoje pravo na prijeboj uzimajući taj iznos od bilo kojeg iznosa koji vam trebamo platiti ili ćemo teretiti vaš odabrani način plaćanja koji je pohranjen kod nas. Ovlašćujete nas ili našeg ovlaštenog procesora plaćanja da teretimo ili odbijamo vašu pohranjenu platnu karticu za sve primjenjive naknade ili iznose koji se duguju u vezi s vašim računom kod nas i vašim korištenjem naših usluga.",
    
        termsOfServiceTitle13: "Opća Odgovornost",
        termsOfServiceUnderTitle131: "Vaša je odgovornost da:",
        termsOfServiceUnderTitle132: "Poštujte i slijedite ove Uvjete pružanja usluge, kao i našu Politiku privatnosti",
        termsOfServiceUnderTitle133: "Pridržavajte se svih zakona, propisa i pravila vaše jurisdikcije koji se mogu odnositi na vaše korištenje naših usluga",
        termsOfServiceUnderTitle134: "Zaključite koji se porezi, ako ih ima, i u kojoj mjeri se primjenjuju na vaše kupnje putem naših Usluga i prikupite, prijavite i doznačite točne iznose poreza odgovarajućem poreznom tijelu. Nećemo biti odgovorni za vaše izvršenje poreznih obveza.",
        termsOfServiceUnderTitle135: "Pružite točne, istinite i potpune informacije za KYC provjeru i odmah obavijestite o svim promjenama vaših KYC dokumenata i informacija nakon završetka procesa verifikacije",
        termsOfServiceUnderTitle136: "Sačuvajte kopiju svih informacija koje ste nam dali ili koje su dostupne putem vašeg BLOK BE računa. Nećemo biti odgovorni za bilo kakve nastale gubitke informacija",
        termsOfServiceUnderTitle137: "Prihvaćate i potvrđujete da nećete koristiti naše Usluge i Stranicu u bilo koju svrhu koja je nezakonita, nezakonita, neetična ili nedosljedna i/ili u suprotnosti s ovim Uvjetima pružanja usluge i svrhom za za koje su stvorene ova stranica i usluge.",
        termsOfServiceUnderTitle138: "Sve neovlaštene upotrebe ove web stranice i sve prijetnje rezultirat će našom istragom. Zadržavamo pravo utvrditi sva kršenja ovih Uvjeta pružanja usluge i djelovati u skladu s tim, uključujući, ali ne ograničavajući se na prijavu sumnje na nezakonite aktivnosti odgovarajućim tijelima za provođenje zakona ili drugim ovlaštenim pravnim ili regulatornim tijelima. Također ćemo im dostaviti potrebne informacije i osobne podatke osumnjičene osobe.",
    
    
        termsOfServiceTitle14: "Objave pravnim tijelima",
        termsOfServiceUnderTitle141: "BLOK BE pridržava pravo podijeliti vaše informacije i osobne podatke pravnim tijelima kada to smatra potrebnim i razumnim, uključujući, ali ne ograničavajući se na zakonske zahtjeve, sudski nalog ili drugi pravni postupak, istragu i sumnju na nezakonite aktivnosti, sprječavanje financijskog gubitka ili fizičke štete i istraživanje kršenja naših Uvjeta pružanja usluge ili Politike privatnosti.",
        termsOfServiceUnderTitle142: "Slažete se i pristajete da možemo dijeliti vaše osobne podatke. Za više informacija pogledajte našu Politiku privatnosti.",
    
        termsOfServiceTitle15: "Vaša Jamstva i Zastupanje",
        termsOfServiceUnderTitle151: "Prihvaćanjem ovih Uvjeta pružanja usluge, izjavljujete, jamčite i obvezujete se BLOK BE-u da:",
        termsOfServiceUnderTitle152: "ste zakonski primjerene dobi i imate punu sposobnost, moć i ovlast prihvatiti Uvjete pružanja usluge i sklapanja transakcija koje uključuju digitalnu imovinu",
        termsOfServiceUnderTitle153: "sklapaju ovaj Ugovor u svoje ime, a ne u ime bilo koje treće strane",
        termsOfServiceUnderTitle154: "razumijete rizike povezane s našim uslugama",
        termsOfServiceUnderTitle155: "nije ni zabranjeno niti ograničeno korištenje stranice/aplikacije",
        termsOfServiceUnderTitle156: "nećete djelovati u suprotnosti s bilo kojim primjenjivim zakonom ili propisom sklapanjem ovog Ugovora",
        termsOfServiceUnderTitle157: "nećete koristiti naše usluge ili web-mjesto za prikrivanje ili maskiranje bilo kakvog kršenja primjenjivih zakona o sprječavanju pranja novca ili terorizma",
        termsOfServiceUnderTitle158: "niste prekršili, oduzeli, sankcionirali predmet Ekonomskog Ograničenja u vezi sa sankcijama ili inače kažnjeni pod; primili bilo kakvu usmenu ili pisanu obavijest od bilo koje vlade u vezi s vašim stvarnim ili mogućim kršenjem prema; ili ste primili bilo koju drugu prijavu da ste predmet ili meta sankcija, ograničenja, kazni ili radnji provedbe ili istrage prema bilo kojem primjenjivom zakonu (uključujući, ali ne ograničavajući se na zakone protiv pranja novca, zakone o financiranju terorizma, antikorupcijske zakone ili zakoni o gospodarskim sankcijama)",
        termsOfServiceUnderTitle159: "neće kupovati digitalnu imovinu ničim drugim osim fiat sredstvima koja su legalno stečena od strane vas i ono što pripada vama",
        termsOfServiceUnderTitle1510: "Prikupiti ćete pošteno i brzo, prijaviti i uplatiti točne iznose poreza u vezi s vašim aktivnostima na našoj stranici nadležnom poreznom tijelu",
        termsOfServiceUnderTitle1511: "nećete krivotvoriti ili pružiti netočne, obmanjujuće ili lažne podatke koje tražimo, uključujući, ali ne ograničavajući se na postupak provjere vašeg identiteta i registracije ili tijekom bilo kojeg drugog procesa dubinske analize; odmah ćete dostaviti nove informacije u slučaju netočnih ili zastarjelih informacija koje su nam dostavljene",
        termsOfServiceUnderTitle1512: "koristit ćete razuman antivirusni, anti-malware i drugi softver i tehnike za zaštitu vas i vašeg novčanika digitalne imovine od neovlaštenog pristupa ili drugih zlonamjernih radnji; nećete olakšati nikakve rutine računalnog programiranja uključujući,ali ne ograničavajući se na viruse, zlonamjerni softver, trojanske konje ili crve koji mogu uzrokovati štetu, korupciju, prekid, zlouporabu ili neovlašteni pristup bilo kojim podacima ili našim Uslugama",
        termsOfServiceUnderTitle1513: "nećete koristiti nikakve robote, pauke, druge automatske uređaje ili ručne postupke za pristup, nadzor ili kopiranje naše web stranice bez našeg prethodnog pismenog dopuštenja",
        termsOfServiceUnderTitle1514: "nećete zlostavljati i/ili prijetiti našim zaposlenicima ili drugim korisnicima",
        termsOfServiceUnderTitle1515: "u potpunosti ćete pomoći s našim razumnim zahtjevima u vezi s našim obvezama prema ovim Uvjetima pružanja usluge",
        termsOfServiceUnderTitle1516: "odmah ćete nas i točno obavijestiti ako znate ili imate razloga znati je li bilo koja od prethodnih izjava ili jamstava više nije točna ili postaje netočna",
    
        termsOfServiceTitle16: "Nema jamstva",
        termsOfServiceUnderTitle161: "BLOK BE Usluge se pružaju na temelju 'kakve jesu' i 'kako su dostupne', osim ako izričito izjavimo suprotno u pisanom obliku. Ne dajemo vam nikakva zastupanja, jamstva, ugovore ili jamstva bilo koje vrste, u mjeri dopuštenoj važećim zakonima. Mi se izričito odričemo, a vi se odričete, svih jamstava bilo koje vrste, izričitih, prešutnih ili zakonskih, uključujući, ali ne ograničavajući se na bilo koja implicitna jamstva vlasništva, mogućnosti prodaje, prikladnosti za određenu svrhu i/ili nekršenja, s obzirom na web mjesto i usluge.",
        termsOfServiceUnderTitle162: "Ne jamčimo da će pristup Stranici ili Uslugama i njezinim materijalima biti neprekinut, pravovremen ili da neće sadržavati bugove ili pogreške. Razumijete i prihvaćate da ne dajemo nikakve izjave niti jamčimo za točnost ili potpunost informacija koje vam pružamo putem naše stranice ili usluga",
        termsOfServiceUnderTitle163: "Razumijete i slažete se da mi ne jamčimo da je bilo koja od naših usluga prikladna ili prikladna za vaše potrebe i da morate uzeti vlastiti neovisni pravni i drugi savjet o takvim uslugama;",
        termsOfServiceUnderTitle164: "Prihvaćate svoju isključivu odgovornost za sigurnosno kopiranje i održavanje kopija svih informacija koje pohranite ili prenesete na naše Usluge, s obzirom na činjenicu da informacije mogu postati izgubljene ili oštećene ili privremeno nedostupne zbog prekida rada interneta, kvara softvera, neovlaštenog pristupa ili drugog oblika katastrofa i kvarova.",
    
        termsOfServiceTitle17: "Odšteta",
        termsOfServiceUnderTitle171: "Ovime se slažete da ćete obeštetiti BLOK BE i njegove partnere, u punoj mjeri dopuštenoj važećim zakonom, od bilo kakvog potraživanja, zahtjeva, radnje, odgovornosti, troška, gubitka, štete, postupka ili troškova koji su pretrpljeni ili nastali ako izravno ili ne izravno proizlaze iz vašeg korištenja Stranice, Vaše upotrebe Usluga ili od vašeg kršenja ovih Uvjeta pružanja usluge ili vašeg kršenja bilo kojeg prava bilo koje druge osobe ili entiteta.",
    
        termsOfServiceTitle18: "Sadržaj Treće strane",
        termsOfServiceUnderTitle181: "Možete naići na poveznice na web stranice ili usluge trećih strana koje nisu pod kontrolom BLOK BE.",
        termsOfServiceUnderTitle182: "BLOK BE ne daje nikakva zastupanja i ne prihvaća nikakvu odgovornost za bilo koje usluge trećih strana. Nemamo kontrolu nad njima ili bilo kojim njihovim sadržajem, ne podržavamo niti usvajamo ništa od toga i nećemo snositi odgovornost za to. Vi ćete snositi sve rizike povezane s korištenjem takvog sadržaja. Također, možda ćete biti vezani svim dodatnim uvjetima koje zahtijevaju pružatelji usluga trećih strana; to je isključivo između vas i trećih strana, a mi se odričemo svake obveze ili odgovornosti za bilo kakav gubitak ili nastalu štetu. Nismo odgovorni za točnost ili pouzdanost bilo kojih informacija, podataka, mišljenja, savjeta ili izjava sadržanih u Uslugama trećih strana.",
    
        termsOfServiceTitle19: "Nadležnost",
        termsOfServiceUnderTitle191: "Ovi Uvjeti pružanja usluge regulirani su i tumače se u skladu s pravom Republike Hrvatske. U slučaju spora, strane su suglasne da će se za njegovo rješavanje neopozivo podvrgnuti isključivo nadležnom sudu u Osijeku.",
    
        termsOfServiceTitle20: "Viša sila",
        termsOfServiceUnderTitle201: "U slučaju štete uzrokovane višom silom, uključujući ali ne ograničavajući se na prirodne katastrofe, nestanke struje ili kvarove, više sile, radnje bilo koje vlade, tržišna kretanja, kvarove računala, internetskog poslužitelja, povrede sigurnosti ili kibernetičke napade, ili bilo koje drugo kašnjenje, neizvršenja, kvarova ili prekida koji se ne mogu razumno predvidjeti ili spriječiti, BLOK BE neće biti odgovoran za bilo koji učinak obveze prema ovim Uvjetima pružanja usluge.",
    
        termsOfServiceTitle21: "Obavijesti i Komunikacije",
        termsOfServiceUnderTitle211: "Ovime se slažete i pristajete na elektroničko primanje svih komunikacija, ugovora, dokumenata, potvrda, obavijesti i objave koje BLOK BE pruža u vezi s vašim BLOK BE računom i/ili korištenjem naših Usluga. Možemo komunicirati s vama objavljivanjem informacija na našoj stranici i/ili slanjem informacija na vašu ovlaštenu adresu e-pošte, navedenu na vašem računu kod BLOK BE. Također vas možemo kontaktirati putem pošte. Provjerite jesu li adresa, telefonski broj i adresa e-pošte navedeni na vašem računu kod BLOK BE ažurni. Informacije koje smo mi poslali/objavili smatrat će se učinkovitima i primiti ih kada ih mi objavimo na našoj stranici, za poštansku komunikaciju drugi radni dan nakon objave i za komunikaciju poštom u trenutku kada su poslane na vašu e-poštu.",
    
        termsOfServiceTitle22: "Izmjena Uvjeta Pružanja Usluge",
        termsOfServiceUnderTitle221: "BLOK BE zadržava pravo izmjene i ažuriranja Uvjeta pružanja usluge u bilo kojem trenutku. O svim promjenama obavijestit ćemo vas unaprijed, ako je to moguće. Vaša je isključiva odgovornost pregledati izmijenjene Uvjete pružanja usluge .Ako se ne slažete s izmijenjenim Uvjetima pružanja usluge nemojte više koristiti ovu stranicu i naše usluge. Nastavak korištenja naših usluga znači prihvaćanje i suglasnost s izmijenjenim Uvjetima pružanja usluge. Datum posljednjeg ažuriranja nalazi se na vrhu ovog dokumenta.",
    
        termsOfServiceTitle23: "Prigovori",
        termsOfServiceUnderTitle231: "Kontaktirajte nas na za sve komentare, pritužbe ili potrebnu pomoć putem naše mail adrese [support@blokbe.com].",
    
        termsOfServiceTitle24: "Razno",
        termsOfServiceUnderTitle241: "Samo vi, kao vlasnik BLOK BE računa, imate bilo kakva prava za provođenje ovih Uvjeta. Vaša prava, licence i/ili obveze su vaša osobna stvar i ne možete ih prenijeti na treću stranu. BLOK BE može prenijeti ili dodijeliti svoja prava i obveze prema ovim Uvjetima u bilo kojem trenutku, uključujući u sklopu spajanja, akvizicije ili druge korporativne reorganizacije. Ako se ne slažete s prijenosom možete raskinuti ovaj Ugovor i više ne koristiti naše Usluge i Stranicu.",
        termsOfServiceUnderTitle242: "Možda nećemo uvijek striktno provoditi svoja prava prema ovim Uvjetima pružanja usluge; tamo gdje ne provodimo svoja prava prema ovim Uvjetima, ne odričemo se svojih prava.",
        termsOfServiceUnderTitle243: "Ako se bilo koja odredba ovog Ugovora smatra nevažećom ili neprovedivom, osim ako nije drugačije navedeno u ovim Uvjetima pružanja usluge, to neće utjecati na valjanost preostalih odredbi. Bilo koja druga odredba će se provoditi u mjeri u kojoj je to moguće.",
        termsOfServiceUnderTitle244: "U slučaju raskida ili isteka ovog Ugovora, sve odredbe koje se po prirodi protežu nakon raskida ili isteka Ugovora, i dalje će biti obvezujuće i nakon toga, uključujući, ali ne ograničavajući se na opću upotrebu Stranice, raskid/obustavu/otkazivanje, sporovi, dugovanja prema BLOK BE i opće odredbe.",
    
        termsOfServiceTitle25: "Odricanje od odgovornosti",
        termsOfServiceUnderTitle251: "Financijski rizik: Kupnja digitalne imovine može sa sobom nositi značajan rizik. Tržišta se mogu brzo promijeniti u smislu cijene, likvidnosti, dubine tržišta i dinamike trgovanja. Razlog za te velike fluktuacije može proizaći iz gubitka povjerenja u digitalnu imovinu, odluka koje je donijela vlada ili političkih izjava, vijesti, hakerskih napada, stvaranja (ira)racionalnih mjehurića ili tehničkih problema. Vrijednost digitalne imovine može se drastično promijeniti i potencijalno čak pasti na nulu.",
        termsOfServiceUnderTitle252: "Dodatni rizici:",
        termsOfServiceUnderTitle253: "Mogu postojati dodatni rizici koje nismo predvidjeli ili identificirali u našim Uvjetima pružanja usluge. Stoga biste trebali pažljivo razmotriti svoju financijsku situaciju i toleranciju rizika prije korištenja naše usluge. Isključivo ste vi odgovorni za sve kupovne aktivnosti na našem web-mjestu. Također, slažete se da ste u potpunosti odgovorni za čuvanje svih informacija danih putem naše stranice ili mobilne aplikacije, uključujući, ali ne ograničavajući se na privatne ključeve, korisnička imena, lozinke i podatke o bankovnom računu. Toplo vam preporučujemo da redovito mijenjate svoje lozinke i čuvate ih na sigurnom mjestu od neovlaštenog pristupa, kao i svoje privatne ključeve, sigurnosne kodove i početne riječi.",
        termsOfServiceUnderTitle254: "Ne postoji jamstvo da neće biti kvarova koji su izvan naše kontrole u vezi s internetskim vezama, hardverom, softverom ili Blockchain mrežom 'koji bi mogli ometati našu uslugu i uzrokovati pogreške, kašnjenja ili kvarove. Ovime potvrđujete da BLOK BE neće biti odgovoran za bilo kakve komunikacijske neuspjehe, smetnje, pogreške, izobličenja ili kašnjenja do kojih možete doći prilikom korištenja naših Usluga. Unatoč tome, uvijek ćemo nastojati pružiti Vam naše Usluge što je prije moguće.",
        termsOfServiceUnderTitle255: "Ograničenje odgovornosti: Cijene digitalne imovine mogu i variraju u bilo kojem trenutku. BLOK BE se odriče svake odgovornosti povezane s korištenjem digitalne imovine. Vaša je isključiva odgovornost procijeniti svoje znanje i financijsku situaciju kako biste razumjeli rizike povezane s upotrebom digitalne imovine, uključujući, ali ne ograničavajući se na tržišne fluktuacije, regulatorne ili zakonodavne promjene ili nepoznate inherentne tehničke nedostatke.",
        termsOfServiceUnderTitle256: "Vi ćete snositi svaki gubitak koji rezultira vašom pogreškom ili krivnjom, uključujući ali ne ograničavajući se na sigurnosne povrede lozinki ili privatnih ključeva, neovlašteni pristup računu ili e-pošti.",
        termsOfServiceUnderTitle257: "BLOK BE nije odgovoran za bilo kakve pogreške u informacijama danim na našim stranicama, koje mogu biti netočne ili sadržavati tiskarske pogreške i propuste. Zadržavamo pravo ispraviti sve pogreške, netočnosti ili propuste te promijeniti ili ažurirati informacije u bilo kojem trenutku bez prethodne najave, stoga preporučujemo da ih, prije nego što se oslonite na te informacije i donesete odluke ili radnje, provjerite u neovisnim izvorima.",
        termsOfServiceUnderTitle258: "Ne preuzimamo nikakvu odgovornost niti odgovornost za bilo kakve gubitke izravno ili neizravno povezane s bilo kakvim pogreškama, kašnjenjima, kvarovima, propustima ili prekidima u radu naše stranice ili pružanju bilo koje usluge.",
        termsOfServiceUnderTitle259: "BLOK BE neće biti odgovoran za bilo koji ili sve gubitke dobiti, gubitak prihoda, gubitak ili oštećenje podataka, gubitak poslovanja, dobre volje ili ugleda, gubitak prilike ili za bilo kakve izravne, neizravne, kaznene, slučajne, posljedične i druge štete koje nisu rezultat grubog nemara, namjerne prijevare ili prijevare, bilo da se gubici temelje na ugovoru, nemaru, deliktu, neopravdanom bogaćenju, objektivnoj odgovornosti, kršenju zakona ili propisa ili bilo kojoj drugoj osnovi.",
        termsOfServiceUnderTitle2510: "Ništa u ovim uvjetima ne isključuje niti ograničava odgovornost BLOK BE za prijevaru, lažno lažno predstavljanje ili lažno pogrešno prikazivanje, ili smrt ili osobnu ozljedu uzrokovanu našim nemarom, koja ne može biti ograničena ili isključena zakonom. Vaši postupci i odluke ostaju u potpunosti vaša odgovornost.",
        termsOfServiceUnderTitle2511: "Ništa u ovim Uvjetima pružanja usluge neće se smatrati niti se namjerava smatrati, niti će uzrokovati da Vas i BLOK BE tretiraju kao partnere ili zajedničke pothvate, ili da Vas ili BLOK BE tretiraju kao agenta drugoga.",
        termsOfServiceUnderTitle2512: "Osim ako nije izričito i izričito navedeno u ovim Uvjetima, BLOK BE se odriče svih jamstava, implicitnih ili izričitih, u najvećoj mogućoj mjeri prema primjenjivom zakonu u vezi s dostupnošću, kvalitetom, pouzdanošću, relevantnošću, pravodobnošću ili točnošću u odnosu na naše Usluge, web stranicu sadržaj ili korištenje naših usluga. Sva jamstva, uvjeti, izjave i svi drugi uvjeti bilo koje vrste implicirani statutom ili običajnim pravom isključeni su iz ovih Uvjeta pružanja usluge u najvećoj mjeri dopuštenoj primjenjivim zakonom.",
};