import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";

import education from "../images/NEW/education.png";
import blockchain from "../images/NEW/blockchain.png";
import cryptocurrency from "../images/NEW/cryptocurrency.png";
import wallet from "../images/NEW/wallet.png";
import exchange from "../images/NEW/exchange.png";

import { en, hr } from "./Translation";
import { TranslationContext } from "./TranslationContext";

const Education = () => {
  const { language, isFading } = useContext(TranslationContext);
  const texts = language === "hr" ? hr : en;

  useEffect(() => {
  });


  return (
    <>
      <section className="bg-half bg-light">
        <div className={`container education text-container ${isFading ? 'fade-out' : ''}`}>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="section-title">
                <div className="text-center">
                  <h4 className="title mb-4">{texts.educationMainTitle}</h4>
                </div>
                <p className="text-muted text-justify mb-0 mt-4 wow animate__animated animate__fadeInUp edutext">
                  {texts.educationUnderMainTitle1}{" "}
                  <span className="text-primary font-weight-bold">
                    {texts.educationUnderMainTitle2}
                  </span>
                  {texts.educationUnderMainTitle3}
                  <br />
                  <br />
                  {texts.educationUnderMainTitle4}{" "}
                  <span className="text-primary font-weight-bold">
                    {texts.educationUnderMainTitle5}
                  </span>
                  .
                  <br />
                  <br />
                  {texts.educationUnderMainTitle6}{" "}
                  <span className="text-primary font-weight-bold">
                    {texts.educationUnderMainTitle7}
                  </span>{" "}
                  {texts.educationUnderMainTitle8}
                  <br />
                  <br />
                </p>
                <img
                  src={education}
                  className="img-fluid rounded-md mb-4 mt-2 wow animate__animated animate__fadeInUp eduimg eduimg1"
                  alt=""
                />
                <ul className="list-unstyled text-muted wow animate__animated animate__fadeInUp edutext">
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-school" />
                    </span>
                    {texts.educationUnderMainTitle9}
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 mr-2">
                      <i className="mdi mdi-school" />
                    </span>
                    {texts.educationUnderMainTitle10}
                  </li>
                </ul>
                <div className="desktopview">
                  <div className="row align-items-center justify-content-around mb-5 mt-5">
                    <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                      <h4 className="my-3 wow animate__animated animate__fadeInUp">
                        {texts.educationTitle1}
                      </h4>
                      <ul className="list-unstyled text-muted mb-3 wow animate__animated animate__fadeInUp">
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-information" />
                          </span>
                          {texts.educationUnderTitle11}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-information" />
                          </span>
                          {texts.educationUnderTitle12}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-information" />
                          </span>
                          {texts.educationUnderTitle13}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-information" />
                          </span>
                          {texts.educationUnderTitle14}
                        </li>
                      </ul>
                    </div>
                    <img
                      src={blockchain}
                      className="img-fluid rounded-md mb-4 wow animate__animated animate__fadeInUp eduimg col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0"
                      alt=""
                    />
                  </div>
                  <div className="row align-items-center justify-content-around mb-5">
                    <img
                      src={cryptocurrency}
                      className="img-fluid rounded-md mb-4 wow animate__animated animate__fadeInUp eduimg col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0"
                      alt=""
                    />
                    <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                      <h4 className="my-3 wow animate__animated animate__fadeInUp">
                        {texts.educationTitle2}
                      </h4>
                      <ul className="list-unstyled text-muted mb-3 wow animate__animated animate__fadeInUp">
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-circle-multiple" />
                          </span>
                          {texts.educationUnderTitle21}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-circle-multiple" />
                          </span>
                          {texts.educationUnderTitle22}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-circle-multiple" />
                          </span>
                          {texts.educationUnderTitle23}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-circle-multiple" />
                          </span>
                          {texts.educationUnderTitle24}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-circle-multiple" />
                          </span>
                          {texts.educationUnderTitle25}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row align-items-center justify-content-around mb-5">
                    <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                      <h4 className="my-3 wow animate__animated animate__fadeInUp">
                        {texts.educationTitle4}
                      </h4>
                      <ul className="list-unstyled text-muted mb-3 wow animate__animated animate__fadeInUp">
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-wallet" />
                          </span>
                          {texts.educationUnderTitle41}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-wallet" />
                          </span>
                          {texts.educationUnderTitle42}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-wallet" />
                          </span>
                          {texts.educationUnderTitle43}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-wallet" />
                          </span>
                          {texts.educationUnderTitle44}
                        </li>
                      </ul>
                    </div>
                    <img
                      src={wallet}
                      className="img-fluid rounded-md mb-4 wow animate__animated animate__fadeInUp eduimg col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0"
                      alt=""
                    />
                  </div>
                  <div className="row align-items-center justify-content-around">
                    <img
                      src={exchange}
                      className="img-fluid rounded-md mb-4 wow animate__animated animate__fadeInUp eduimg col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0"
                      alt=""
                    />
                    <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                      <h4 className="my-3 wow animate__animated animate__fadeInUp">
                        {texts.educationTitle3}
                      </h4>
                      <ul className="list-unstyled text-muted mb-3 wow animate__animated animate__fadeInUp">
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-swap-horizontal" />
                          </span>
                          {texts.educationUnderTitle31}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-swap-horizontal" />
                          </span>
                          {texts.educationUnderTitle32}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-swap-horizontal" />
                          </span>
                          {texts.educationUnderTitle33}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-swap-horizontal" />
                          </span>
                          {texts.educationUnderTitle34}
                        </li>
                        <li className="mb-0">
                          <span className="text-primary h5 mr-2">
                            <i className="mdi mdi-swap-horizontal" />
                          </span>
                          {texts.educationUnderTitle35}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mobileview">
                  <h4 className="my-3 wow animate__animated animate__fadeInUp">
                    {texts.educationTitle1}
                  </h4>
                  <ul className="list-unstyled text-muted mb-3 wow animate__animated animate__fadeInUp">
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-information" />
                      </span>
                      {texts.educationUnderTitle11}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-information" />
                      </span>
                      {texts.educationUnderTitle12}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-information" />
                      </span>
                      {texts.educationUnderTitle13}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-information" />
                      </span>
                      {texts.educationUnderTitle14}
                    </li>
                  </ul>
                  <img
                    src={blockchain}
                    className="img-fluid rounded-md mb-4 wow animate__animated animate__fadeInUp eduimg"
                    alt=""
                  />
                  <h4 className="my-3 wow animate__animated animate__fadeInUp">
                    {texts.educationTitle2}
                  </h4>
                  <ul className="list-unstyled text-muted mb-3 wow animate__animated animate__fadeInUp">
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-circle-multiple" />
                      </span>
                      {texts.educationUnderTitle21}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-circle-multiple" />
                      </span>
                      {texts.educationUnderTitle22}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-circle-multiple" />
                      </span>
                      {texts.educationUnderTitle23}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-circle-multiple" />
                      </span>
                      {texts.educationUnderTitle24}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-circle-multiple" />
                      </span>
                      {texts.educationUnderTitle25}
                    </li>
                  </ul>
                  <img
                    src={cryptocurrency}
                    className="img-fluid rounded-md mb-4 wow animate__animated animate__fadeInUp eduimg"
                    alt=""
                  />
                  <h4 className="my-3 wow animate__animated animate__fadeInUp">
                    {texts.educationTitle4}
                  </h4>
                  <ul className="list-unstyled text-muted mb-3 wow animate__animated animate__fadeInUp">
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-wallet" />
                      </span>
                      {texts.educationUnderTitle41}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-wallet" />
                      </span>
                      {texts.educationUnderTitle42}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-wallet" />
                      </span>
                      {texts.educationUnderTitle43}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-wallet" />
                      </span>
                      {texts.educationUnderTitle44}
                    </li>
                  </ul>
                  <img
                    src={wallet}
                    className="img-fluid rounded-md mb-4 wow animate__animated animate__fadeInUp eduimg"
                    alt=""
                  />
                  <h4 className="my-3 wow animate__animated animate__fadeInUp">
                    {texts.educationTitle3}
                  </h4>
                  <ul className="list-unstyled text-muted mb-3 wow animate__animated animate__fadeInUp">
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-swap-horizontal" />
                      </span>
                      {texts.educationUnderTitle31}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-swap-horizontal" />
                      </span>
                      {texts.educationUnderTitle32}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-swap-horizontal" />
                      </span>
                      {texts.educationUnderTitle33}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-swap-horizontal" />
                      </span>
                      {texts.educationUnderTitle34}
                    </li>
                    <li className="mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className="mdi mdi-swap-horizontal" />
                      </span>
                      {texts.educationUnderTitle35}
                    </li>
                  </ul>
                  <img
                    src={exchange}
                    className="img-fluid rounded-md mb-4 wow animate__animated animate__fadeInUp eduimg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section className="section pt-0 mb-5">
        <div className="container">
          <div className="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
            <div className="col-12 text-center">
              <div
                className="section-title wow animate__animated animate__fadeInUp"
                data-wow-delay=".45s"
              >
                <h4
                  className={`title mb-2 text-container ${
                    isFading ? "fade-out" : ""
                  }`}
                >
                  {texts.contactAllTitle}
                </h4>
                <p
                  className={`text-muted para-desc mx-auto text-container ${
                    isFading ? "fade-out" : ""
                  }`}
                >
                  {texts.contactAllUnderTitle1} <br />{" "}
                  {texts.contactAllUnderTitle2}{" "}
                  <span className="text-primary font-weight-bold">BlokBe</span>.
                </p>
                <div className="mt-2">
                  <div className="btn btn-primary mt-2 mr-2">
                    <i className="mdi mdi-account-box" />
                    <NavLink
                      className={`text-white text-container ${
                        isFading ? "fade-out" : ""
                      }`}
                      to="/contact"
                    >
                      {texts.contactAllButton}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Education;
