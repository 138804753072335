import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { en, hr } from "./Translation";
import { TranslationContext } from "./TranslationContext";

const Terms = () => {
  const { language, isFading } = useContext(TranslationContext);
  const texts = language === "hr" ? hr : en;

  useEffect(() => {
  });


  return (
    <>
      <section className="bg-half bg-light d-table w-100">
        <div className={`container text-container ${isFading ? 'fade-out' : ''}`}>
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <div className="page-next-level">
                <h4 className="title">{texts.termsOfServiceTitle1}</h4>
                <ul className="list-unstyled mt-4">
                  <li className="list-inline-item h6 date text-muted">
                    <span className="text-dark">
                      {texts.termsOfServiceDate1}
                    </span>{" "}
                    {texts.termsOfService1Date2}
                  </li>
                </ul>
                <div className="page-next">
                  <nav aria-label="breadcrumb" className="d-inline-block">
                    <ul className="breadcrumb bg-white rounded shadow mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          {texts.termsOfServiceLocation1}
                        </NavLink>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {texts.termsOfServiceLocation2}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section className="section">
        <div className={`container text-container ${isFading ? 'fade-out' : ''}`}>
          <div className="row justify-content-center">
            <div className="col-lg-9 text-justify">
              <div className="card shadow border-0 rounded">
                <div className="card-body">
                  <h5 className="card-title">
                    1. {texts.termsOfServiceTitle2}
                  </h5>
                  <p className="text-muted">
                    1.1 {texts.termsOfServiceUnderTitle21}
                  </p>
                  <p className="text-muted">
                    BLOK BE (BLOK BE d.o.o.) {texts.termsOfServiceUnderTitle22}
                  </p>
                  <p className="text-muted">
                    1.2 {texts.termsOfServiceUnderTitle23}
                  </p>
                  <h5 className="card-title">
                    2. {texts.termsOfServiceTitle3}
                  </h5>
                  <p className="text-muted">
                    2.1. {texts.termsOfServiceUnderTitle31}
                  </p>
                  <p className="text-muted">
                    2.2 {texts.termsOfServiceUnderTitle32}
                  </p>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle33}
                  </p>
                  <h5 className="card-title">
                    3. {texts.termsOfServiceTitle4}
                  </h5>
                  <p className="text-muted">
                    3.1 {texts.termsOfServiceUnderTitle41}
                  </p>
                  <p className="text-muted">
                    3.2 {texts.termsOfServiceUnderTitle42}
                  </p>
                  <p className="text-muted">
                    3.3 {texts.termsOfServiceUnderTitle43}
                  </p>
                  <p className="text-muted">
                    3.4 {texts.termsOfServiceUnderTitle44}
                  </p>
                  <h5 className="card-title">
                    4. {texts.termsOfServiceTitle5}
                  </h5>
                  <p className="text-muted">
                    4.1 {texts.termsOfServiceUnderTitle51}{" "}
                    <a href="/">https://www.blokbe.com</a>
                    {texts.termsOfServiceUnderTitle5111}
                  </p>
                  <p className="text-muted">
                    4.2 {texts.termsOfServiceUnderTitle52}
                  </p>
                  <ul className="list-unstyled text-muted">
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle53}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle54}
                    </li>
                  </ul>
                  <p className="text-muted">
                    4.3 {texts.termsOfServiceUnderTitle55}
                  </p>
                  <ul className="list-unstyled text-muted">
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle56}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle57}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle58}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle59}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle510}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle511}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle512}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle513}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle514}
                    </li>
                  </ul>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle515}
                  </p>
                  <h5 className="card-title">
                    5. {texts.termsOfServiceTitle6}
                  </h5>
                  <p className="text-muted">
                    5.1 {texts.termsOfServiceUnderTitle61}
                  </p>
                  <p className="text-muted">
                    5.2 {texts.termsOfServiceUnderTitle62}
                  </p>
                  <h5 className="card-title">
                    6. {texts.termsOfServiceTitle7}
                  </h5>
                  <p className="text-muted">
                    6.1 {texts.termsOfServiceUnderTitle71}
                  </p>
                  <p className="text-muted">
                    6.2 {texts.termsOfServiceUnderTitle72}
                  </p>
                  <p className="text-muted">
                    6.3 {texts.termsOfServiceUnderTitle73}
                  </p>
                  <p className="text-muted">
                    6.4 {texts.termsOfServiceUnderTitle74}
                  </p>
                  <p className="text-muted">
                    6.5 {texts.termsOfServiceUnderTitle75}
                  </p>
                  <p className="text-muted">
                    6.6 {texts.termsOfServiceUnderTitle76}{" "}
                    <NavLink to="/privacy-policy">
                    {texts.termsOfServiceUnderTitle761}
                        </NavLink>
                      
                    {texts.termsOfServiceUnderTitle762}
                  </p>
                  <h5 className="card-title">
                    7. {texts.termsOfServiceTitle8}
                  </h5>
                  <p className="text-muted">
                    7.1 {texts.termsOfServiceUnderTitle81}
                  </p>
                  <p className="text-muted">
                    7.2 {texts.termsOfServiceUnderTitle82}
                  </p>
                  <p className="text-muted">
                    7.3 {texts.termsOfServiceUnderTitle83}{" "}
                    <a href="mailto:support@blokbe.com">
                      support@blokbe.com.
                    </a>
                  </p>
                  <h5 className="card-title">
                    8. {texts.termsOfServiceTitle9}
                  </h5>
                  <p className="text-muted">
                    8.1 {texts.termsOfServiceUnderTitle91}
                  </p>
                  <p className="text-muted">
                    8.2 {texts.termsOfServiceUnderTitle92}
                  </p>
                  <p className="text-muted">
                    8.3 {texts.termsOfServiceUnderTitle93}
                    <a href="mailto:support@blokbe.com">
                      {" "}
                      support@blokbe.com
                    </a>
                  </p>
                  <p className="text-muted">
                    8.4 {texts.termsOfServiceUnderTitle94}
                  </p>
                  <h5 className="card-title">
                    9. {texts.termsOfServiceTitle10}
                  </h5>
                  <p className="text-muted">
                    9.1 {texts.termsOfServiceUnderTitle101}{" "}
                    <a href="mailto:support@blokbe.com">
                      support@blokbe.com
                    </a>{" "}
                    {texts.termsOfServiceUnderTitle1011}
                  </p>
                  <p className="text-muted">
                    9.2 {texts.termsOfServiceUnderTitle102}
                  </p>
                  <p className="text-muted">
                    9.3 {texts.termsOfServiceUnderTitle103}
                  </p>
                  <h5 className="card-title">
                    10. {texts.termsOfServiceTitle11}
                  </h5>
                  <p className="text-muted">
                    10.1 {texts.termsOfServiceUnderTitle111}
                  </p>
                  <ul className="list-unstyled text-muted">
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle112}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle113}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle114}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle115}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle116}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle117}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle118}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle119}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1110}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1111}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1112}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1113}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1114}
                    </li>
                  </ul>
                  <p className="text-muted">
                    10.2 {texts.termsOfServiceUnderTitle1115}
                  </p>
                  <p className="text-muted">
                    10.3 {texts.termsOfServiceUnderTitle1116}
                  </p>
                  <h5 className="card-title">
                    11. {texts.termsOfServiceTitle12}
                  </h5>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle121}
                  </p>
                  <h5 className="card-title">
                    12. {texts.termsOfServiceTitle13}
                  </h5>
                  <p className="text-muted">
                    12.1 {texts.termsOfServiceUnderTitle131}
                  </p>
                  <ul className="list-unstyled text-muted">
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle132}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle133}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle134}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle135}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle136}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle137}
                    </li>
                  </ul>
                  <p className="text-muted">
                    12.2 {texts.termsOfServiceUnderTitle138}
                  </p>
                  <h5 className="card-title">
                    13. {texts.termsOfServiceTitle14}
                  </h5>
                  <p className="text-muted">
                    13.1 {texts.termsOfServiceUnderTitle141}
                  </p>
                  <p className="text-muted">
                    13.2 {texts.termsOfServiceUnderTitle142}
                  </p>
                  <h5 className="card-title">
                    14. {texts.termsOfServiceTitle15}
                  </h5>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle151}
                  </p>
                  <ul className="list-unstyled text-muted">
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle152}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle153}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle154}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle155}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle156}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle157}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle158}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle159}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1510}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1511}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1512}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1513}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1514}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1515}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle1516}
                    </li>
                  </ul>
                  <h5 className="card-title">
                    15. {texts.termsOfServiceTitle16}
                  </h5>
                  <p className="text-muted">
                    15.1 {texts.termsOfServiceUnderTitle161}
                  </p>
                  <p className="text-muted">
                    15.2 {texts.termsOfServiceUnderTitle162}
                  </p>
                  <p className="text-muted">
                    15.3 {texts.termsOfServiceUnderTitle163}
                  </p>
                  <p className="text-muted">
                    15.4 {texts.termsOfServiceUnderTitle164}
                  </p>
                  <h5 className="card-title">
                    16. {texts.termsOfServiceTitle17}
                  </h5>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle171}
                  </p>
                  <h5 className="card-title">
                    17. {texts.termsOfServiceTitle18}
                  </h5>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle181}
                  </p>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle182}
                  </p>
                  <h5 className="card-title">
                    18. {texts.termsOfServiceTitle19}
                  </h5>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle191}
                  </p>
                  <h5 className="card-title">19. {texts.termsOfServiceTitle20}</h5>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle201}
                  </p>
                  <h5 className="card-title">20. {texts.termsOfServiceTitle21}</h5>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle211}
                  </p>
                  <h5 className="card-title">
                    21. {texts.termsOfServiceTitle22}
                  </h5>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle221}
                  </p>
                  <h5 className="card-title">22. {texts.termsOfServiceTitle23}</h5>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle231}
                  </p>
                  <h5 className="card-title">23. {texts.termsOfServiceTitle24}</h5>
                  <p className="text-muted">
                    23.1 {texts.termsOfServiceUnderTitle241}
                  </p>
                  <p className="text-muted">
                    23.2 {texts.termsOfServiceUnderTitle242}
                  </p>
                  <p className="text-muted">
                    23.3 {texts.termsOfServiceUnderTitle243}
                  </p>
                  <p className="text-muted">
                    23.4 {texts.termsOfServiceUnderTitle244}
                  </p>
                  <h5 className="card-title">24. {texts.termsOfServiceTitle25}</h5>
                  <p className="text-muted">
                    24.1 {texts.termsOfServiceUnderTitle251}
                  </p>
                  <p className="text-muted">24.2 {texts.termsOfServiceUnderTitle252}</p>
                  <ul className="list-unstyled text-muted">
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle253}
                    </li>
                    <li>
                      <i
                        data-feather="arrow-right"
                        className="fea icon-sm mr-2 erou"
                      />
                      {texts.termsOfServiceUnderTitle254}
                    </li>
                  </ul>
                  <p className="text-muted">
                    24.3 {texts.termsOfServiceUnderTitle255}
                  </p>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle256}
                  </p>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle257}
                  </p>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle258}
                  </p>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle259}
                  </p>
                  <p className="text-muted">
                   {texts.termsOfServiceUnderTitle2510}
                  </p>
                  <p className="text-muted">
                   {texts.termsOfServiceUnderTitle2511}
                  </p>
                  <p className="text-muted">
                    {texts.termsOfServiceUnderTitle2512}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
