import React, { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";

// Icons
// import { IconName } from "react-icons/md";
import MapComponent from "./MapComponent";
// Images
import partner1 from "../images/partners/990_garage.png";
import partner2 from "../images/partners/ac_kotromanovic.png";
import partner3 from "../images/partners/alice-in-blockchains.png";
import partner4 from "../images/partners/auto_reor.png";
import partner5 from "../images/partners/autoskola_blue.png";
import partner6 from "../images/partners/autoskola_starcevic.png";
import partner7 from "../images/partners/castellum_art.png";
import partner8 from "../images/partners/cingi_lingi_carda.png";
import partner9 from "../images/partners/dm_team.png";
import partner10 from "../images/partners/dream_car.jpg";
import partner11 from "../images/partners/euroauto.png";
import partner12 from "../images/partners/exoro.png";
import partner13 from "../images/partners/extreme_sport.png";
import partner14 from "../images/partners/fusion_studio.png";
import partner15 from "../images/partners/hemp_agro.jpg";
import partner16 from "../images/partners/hotel_silver.png";
import partner17 from "../images/partners/hotel_split.png";
import partner18 from "../images/partners/hu-bo.jpg";
import partner19 from "../images/partners/ikonst.png";
import partner20 from "../images/partners/ines_tehnika.png";
import partner21 from "../images/partners/kamp_kalebic.png";
import partner22 from "../images/partners/krisclinic.png";
import partner23 from "../images/partners/la-dutti.png";
import partner24 from "../images/partners/massa.png";
import partner25 from "../images/partners/medros.png";
import partner26 from "../images/partners/merlinka.png";
import partner27 from "../images/partners/optika_oko.png";
import partner28 from "../images/partners/pamida.png";
import partner29 from "../images/partners/poliklinika_svante.png";
import partner30 from "../images/partners/rulla.png";
import partner31 from "../images/partners/solvermax.png";
import partner32 from "../images/partners/villa_katarina.png";

import login from "../images/blokbe/login.png";
import walkthrough1 from "../images/blokbe/Walkthrough1.png";
import walkthrough2 from "../images/blokbe/Walkthrough2.png";
import walkthrough3 from "../images/blokbe/Walkthrough3.png";

import { en, hr } from "./Translation";
import { TranslationContext } from "./TranslationContext";

const Home = () => {
  const { language, isFading } = useContext(TranslationContext);
  const texts = language === "hr" ? hr : en;

  const pdfLink = language === "hr" ? "/form-hr.pdf" : "/form-en.pdf";

  useEffect(() => {
    window.scrollTo(0, 0);

    // Animated Landing Slider
    var currentSlide = 0;
    var slides = $(".sec-1-input");
    var slideCount = slides.length;
    function bannerSwitcher() {
      // Uncheck the current slide
      slides.eq(currentSlide).prop("checked", false);
      // Increment the current slide index, wrapping around if necessary
      currentSlide = (currentSlide + 1) % slideCount;
      // Check the new current slide
      slides.eq(currentSlide).prop("checked", true);
    }
    var bannerTimer = setInterval(bannerSwitcher, 5000);
    $("nav .controls label").on("click", function () {
      clearInterval(bannerTimer);
      bannerTimer = setInterval(bannerSwitcher, 5000);
    });

    // DROP DOWN SERVICES
    const handleClick = (event) => {
      event.preventDefault();
      const accordion = event.currentTarget.closest(".card[id^='card']");
      if (accordion) {
        setTimeout(() => {
          const scrollOffset =
            accordion.getBoundingClientRect().top + window.pageYOffset - 120;
          window.scrollTo({
            top: scrollOffset,
            behavior: "smooth",
          });
        }, 450);
      }
    };

    const accordions = document.querySelectorAll(".card[id^='card']");
    accordions.forEach((accordion) => {
      const link = accordion.querySelector("a");
      link.addEventListener("click", handleClick);
    });
  }, []);

  return (
    <>
      <section id="section-1" className="mt-106">
        <div className="content-slider">
          <div className="area">
            <ul className="circles">
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
              <li />
            </ul>
          </div>
          <input
            type="radio"
            id="banner1"
            className="sec-1-input"
            name="banner"
            defaultChecked
          />
          <input
            type="radio"
            id="banner2"
            className="sec-1-input"
            name="banner"
          />
          <input
            type="radio"
            id="banner3"
            className="sec-1-input"
            name="banner"
          />
          <input
            type="radio"
            id="banner4"
            className="sec-1-input"
            name="banner"
          />
          <div
            className={`slider text-container ${isFading ? "fade-out" : ""}`}
          >
            <div id="top-banner-1" className="banner">
              <div className="landing-image" id="bg1" />
              <div className="banner-inner-wrapper">
                <h1 className="animate__animated animate__fadeInUp">
                  {texts.homeTitle1}
                </h1>
                <div className="animate__animated animate__fadeInUp">
                  {texts.homeUnderTitle1}
                  <br />
                  {texts.homeUnderTitle11}
                </div>
                <div className="line animate__animated animate__fadeInUp" />
                <div className="learn-more-button animate__animated animate__fadeInUp">
                  <NavLink to="/crypto-exchange">
                    {texts.homeButtonTitle1}
                  </NavLink>
                </div>
              </div>
            </div>
            <div id="top-banner-2" className="banner">
              <div className="landing-image" id="bg2" />
              <div className="banner-inner-wrapper">
                <h1>{texts.homeTitle2}</h1>
                <div>
                  {texts.homeUnderTitle2}
                  <br />
                  {texts.homeUnderTitle22}
                </div>
                <div className="line" />
                <div className="learn-more-button">
                  <NavLink to="/crypto-payment">
                    {texts.homeButtonTitle2}
                  </NavLink>
                </div>
              </div>
            </div>
            <div id="top-banner-3" className="banner">
              <div className="landing-image" id="bg3" />
              <div className="banner-inner-wrapper">
                <h1>{texts.homeTitle3}</h1>
                <div>
                  {texts.homeUnderTitle3}
                  <br />
                  {texts.homeUnderTitle33}
                </div>
                <div className="line" />
                <div className="learn-more-button">
                  <NavLink to="/blokbe-app">{texts.homeButtonTitle3}</NavLink>
                </div>
              </div>
            </div>
            <div id="top-banner-4" className="banner">
              <div className="banner-inner-wrapper">
                <h1>{texts.homeTitle4}</h1>
                <div>{texts.homeUnderTitle4}</div>
                <div className="line" />
                <div className="learn-more-button">
                  <NavLink to="/contact">{texts.homeButtonTitle4}</NavLink>
                </div>
              </div>
            </div>
          </div>
          <nav>
            <div className="controls animate__animated animate__fadeInUp">
              <label
                htmlFor="banner1"
                className={`text-container ${isFading ? "fade-out" : ""}`}
              >
                <span className="progressbar">
                  <span className="progressbar-fill" />
                </span>
                <span>01</span> {texts.sliderTitle1}
              </label>
              <label
                htmlFor="banner2"
                className={`text-container ${isFading ? "fade-out" : ""}`}
              >
                <span className="progressbar">
                  <span className="progressbar-fill" />
                </span>
                <span>02</span> {texts.sliderTitle2}
              </label>
              <label
                htmlFor="banner3"
                className={`text-container ${isFading ? "fade-out" : ""}`}
              >
                <span className="progressbar">
                  <span className="progressbar-fill" />
                </span>
                <span>03</span> {texts.sliderTitle3}
              </label>
              <label
                htmlFor="banner4"
                className={`text-container ${isFading ? "fade-out" : ""}`}
              >
                <span className="progressbar">
                  <span className="progressbar-fill" />
                </span>
                <span>04</span> {texts.sliderTitle4}
              </label>
            </div>
          </nav>
        </div>
      </section>

      <div>
        <section className="section bg-light">
          <svg
            className="svvg"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
            viewBox="0 0 100 100"
          >
            {/* <path fill="#165DA7" class="in-top"
          d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z" /> */}
            <path
              fill="#165DA7"
              className="in-bottom"
              d="M102,67.1c-9.6-6.1-22-3.1-29.5,2-15.4,10.7-19.6,37.5-7.6,47.8s35.9,3.9,44.5-12.5C115.5,92.6,113.9,74.6,102,67.1Z"
            />
          </svg>
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-12 text-center">
                <div
                  className={`section-title mb-4 pb-2 text-container ${isFading ? "fade-out" : ""
                    }`}
                >
                  <h4
                    className="title mb-4 wow animate__animated animate__fadeInUp"
                    data-wow-delay=".5s"
                  >
                    {texts.landingPartnersTitle}
                  </h4>
                  <p
                    className="text-muted para-desc mb-0 mx-auto wow animate__animated animate__fadeInUp"
                    data-wow-delay=".3s"
                  >
                    {texts.landingPartnersUnderTitle}
                  </p>
                </div>
              </div>
              <div
                className="slider-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <div className="slide-track slideLeft" id="slideLeft">
                  <div className="slide-partner">
                    <img src={partner1} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner2} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner3} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner4} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner5} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner6} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner7} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner8} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner9} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner10} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner11} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner12} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner13} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner14} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner15} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner16} height={100} width={100} alt="" />
                  </div>
                </div>
              </div>
              <div className="slider-partners wow animate__animated animate__fadeInUp">
                <div className="slide-track slideRight" id="slideRight">
                  <div className="slide-partner">
                    <img src={partner17} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner18} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner19} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner20} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner21} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner22} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner23} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner24} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner25} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner26} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner27} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner28} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner29} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner30} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner31} height={100} width={100} alt="" />
                  </div>
                  <div className="slide-partner">
                    <img src={partner32} height={100} width={100} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <div
                  className={`section-title mb-4 pb-2 text-container ${isFading ? "fade-out" : ""
                    }`}
                >
                  <h4
                    className="title mb-4 wow animate__animated animate__fadeInUp"
                    data-wow-delay=".5s"
                  >
                    {texts.homeServicesTitle}
                  </h4>
                  <p
                    className="text-muted para-desc mb-0 mx-auto wow animate__animated animate__fadeInUp"
                    data-wow-delay=".3s"
                  >
                    {texts.homeServicesUnderTitle1}{" "}
                    <span className="text-primary font-weight-bold">
                      BlokBe
                    </span>{" "}
                    {texts.homeServicesUnderTitle2}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`faq-content mt-4 text-container ${isFading ? "fade-out" : ""
                }`}
            >
              <div className="accordion" id="accordionExampleone">
                {/* EXCHANGE */}
                <div
                  id="card1"
                  className="card border-0 rounded mb-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".3s"
                >
                  <a
                    data-toggle="collapse"
                    href="#collapseone"
                    className="faq position-relative collapsed"
                    aria-expanded="false"
                    aria-controls="collapseone"
                  >
                    <div
                      className="card-header border-0 card-color pt-3 pl-3 pb-3 pr-5"
                      id="headingfifone"
                    >
                      <h4 className="title mb-0">
                        <span className="text-primary">
                          {texts.homeServiceTitle1}
                        </span>
                      </h4>
                      <p className="text-muted mb-0 faq-ans">
                        <br />
                        {texts.homeServiceUnderTitle1}
                      </p>
                    </div>
                  </a>
                  <div
                    id="collapseone"
                    className="collapse"
                    aria-labelledby="headingfifone"
                    data-parent="#accordionExampleone"
                  >
                    <div className="card-body pb-4 text-muted">
                      <p className="text-muted mb-0 faq-ans">
                        <span className="text-primary expandTitle">
                          {texts.homeService1Subtitle1}
                        </span>
                      </p>
                      <hr />
                      <br />
                      {texts.homeService1UnderSubtitle1}
                      <br />
                      <br />
                      {texts.homeService1UnderSubtitle11}
                      <br />
                      <br />
                      {texts.homeService1UnderSubtitle111}
                      <br />
                      <NavLink to="/contact">{texts.homeService1UnderSubtitle1111}</NavLink> {texts.homeService1UnderSubtitle11111}
                      <br />
                      <br />
                      <span className="text-primary expandTitle">
                        {texts.homeService1Subtitle2}
                      </span>
                      <hr />
                      <br />
                      {texts.homeService1UnderSubtitle2}
                      <p />
                    </div>
                  </div>
                </div>
                {/* PAYMENT */}
                <div
                  id="card2"
                  className="card border-0 rounded mb-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".2s"
                >
                  <a
                    data-toggle="collapse"
                    href="#collapsetwo"
                    className="faq position-relative collapsed"
                    aria-expanded="false"
                    aria-controls="collapsetwo"
                  >
                    <div
                      className="card-header border-0 card-color pt-3 pl-3 pb-3 pr-5"
                      id="headingfifone"
                    >
                      <h4 className="title mb-0">
                        <span className="text-primary">
                          {texts.homeServiceTitle2}
                        </span>
                      </h4>
                      <p className="text-muted mb-0 faq-ans">
                        <br />
                        {texts.homeServiceUnderTitle2}
                        <br />
                        <br />
                        {texts.homeServiceUnderTitle22}
                      </p>
                    </div>
                  </a>
                  <div
                    id="collapsetwo"
                    className="collapse"
                    aria-labelledby="headingtwo"
                    data-parent="#accordionExampleone"
                  >
                    <div className="card-body pb-4 text-muted">
                      <p className="text-muted mb-0 faq-ans">
                        <span className="text-primary expandTitle">
                          {texts.homeCryptoPaymentTitle1}
                        </span>
                      </p>
                      <hr />
                      <br />
                      {texts.homeCryptoPaymentUnderTitle1}{" "}
                      <span className="text-primary font-weight-bold">
                        {texts.homeCryptoPaymentUnderTitle11}
                      </span>{" "}
                      {texts.homeCryptoPaymentUnderTitle12}
                      <span className="text-primary font-weight-bold">
                        {" "}
                        {texts.homeCryptoPaymentUnderTitle13}
                      </span>{" "}
                      {texts.homeCryptoPaymentUnderTitle14}
                      <span className="text-primary font-weight-bold">
                        {" "}
                        {texts.homeCryptoPaymentUnderTitle15}{" "}
                      </span>
                      {texts.homeCryptoPaymentUnderTitle16}
                      <br />
                      <a
                        href="https://gateway.blokbe.com/"
                        className="btn btn-primary mt-2 mr-2"
                      >
                        {texts.homeCryptoPaymentUnderTitle17}
                      </a>
                      <br />
                      <br />
                      <span className="text-primary expandTitle">
                        {texts.homeCryptoPaymentTitle2}
                      </span>
                      <hr />
                      <br />
                      {texts.homeCryptoPaymentUnderTitle21}
                      <br />
                      <br />
                      {texts.homeCryptoPaymentUnderTitle22}
                      <br />
                      {texts.homeCryptoPaymentUnderTitle23}
                      <br />
                      {texts.homeCryptoPaymentUnderTitle24}{" "}
                      <span className="text-primary font-weight-bold">
                        treasury@blokbe.com
                      </span>
                      <br />
                      <a
                        href={pdfLink}
                        className="btn btn-primary mt-2 mr-2"
                      >
                        {texts.homeCryptoPaymentUnderTitle26}
                      </a>
                      <br />
                      <br />
                      {texts.homeCryptoPaymentUnderTitle27}
                      <br />
                      <br />
                      <span className="text-primary expandTitle">
                        {texts.homeCryptoPaymentTitle3}
                      </span>
                      <hr />
                      <br />
                      {texts.homeCryptoPaymentUnderTitle31}
                      <br />
                      <br />
                      {texts.homeCryptoPaymentUnderTitle32}
                      <br />
                      <br />
                      <span className="text-primary expandTitle">
                        {texts.homeCryptoPaymentTitle4}
                      </span>
                      <hr />
                      <br />
                      <div className="row">
                        <div className="col-md-4 col-12 mt-4 pt-4 col-mobile">
                          <div className="features feature-primary">
                            <div className="image position-relative d-inline-block">
                              <i className="mdi mdi-laptop h2 text-primary" />
                            </div>
                            <div className="content mt-4">
                              <h5>{texts.homeCryptoPaymentUnderTitle41}</h5>
                              <p className="text-muted mb-0">
                                {texts.homeCryptoPaymentUnderTitle411}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-12 mt-4 pt-4 col-mobile">
                          <div className="features feature-primary">
                            <div className="image position-relative d-inline-block">
                              <i className="mdi mdi-lightbulb-on-outline h2 text-primary" />
                            </div>
                            <div className="content mt-4">
                              <h5>{texts.homeCryptoPaymentUnderTitle42}</h5>
                              <p className="text-muted mb-0">
                                {texts.homeCryptoPaymentUnderTitle422}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-12 mt-4 pt-4 col-mobile">
                          <div className="features feature-primary">
                            <div className="image position-relative d-inline-block">
                              <i className="mdi mdi-check-all h2 text-primary" />
                            </div>
                            <div className="content mt-4">
                              <h5>{texts.homeCryptoPaymentUnderTitle43}</h5>
                              <p className="text-muted mb-0">
                                {texts.homeCryptoPaymentUnderTitle433}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-12 mt-4 pt-4 col-mobile">
                          <div className="features feature-primary">
                            <div className="image position-relative d-inline-block">
                              <i className="mdi mdi-chart-bubble h2 text-primary" />
                            </div>
                            <div className="content mt-4">
                              <h5>{texts.homeCryptoPaymentUnderTitle44}</h5>
                              <p className="text-muted mb-0">
                                {texts.homeCryptoPaymentUnderTitle444}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-12 mt-4 pt-4 col-mobile">
                          <div className="features feature-primary">
                            <div className="image position-relative d-inline-block">
                              <i className="mdi mdi-timetable h2 text-primary" />
                            </div>
                            <div className="content mt-4">
                              <h5>{texts.homeCryptoPaymentUnderTitle45}</h5>
                              <p className="text-muted mb-0">
                                {texts.homeCryptoPaymentUnderTitle455}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-12 mt-4 pt-4 col-mobile">
                          <div className="features feature-primary">
                            <div className="image position-relative d-inline-block">
                              <i className="mdi mdi-piggy-bank h2 text-primary" />
                            </div>
                            <div className="content mt-4">
                              <h5>{texts.homeCryptoPaymentUnderTitle46}</h5>
                              <p className="text-muted mb-0">
                                {texts.homeCryptoPaymentUnderTitle466}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-12 mt-4 pt-4 col-mobile">
                          <div className="features feature-primary">
                            <div className="image position-relative d-inline-block">
                              <i className="mdi mdi-swap-horizontal h2 text-primary" />
                            </div>
                            <div className="content mt-4">
                              <h5>{texts.homeCryptoPaymentUnderTitle47}</h5>
                              <p className="text-muted mb-0">
                                {texts.homeCryptoPaymentUnderTitle477}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-12 mt-4 pt-4 col-mobile">
                          <div className="features feature-primary">
                            <div className="image position-relative d-inline-block">
                              <i className="mdi mdi-face-agent h2 text-primary" />
                            </div>
                            <div className="content mt-4">
                              <h5>{texts.homeCryptoPaymentUnderTitle48}</h5>
                              <p className="text-muted mb-0">
                                {texts.homeCryptoPaymentUnderTitle488}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-12 mt-4 pt-4 col-mobile">
                          <div className="features feature-primary">
                            <div className="image position-relative d-inline-block">
                              <i className="mdi mdi-shield-lock h2 text-primary" />
                            </div>
                            <div className="content mt-4">
                              <h5>{texts.homeCryptoPaymentUnderTitle49}</h5>
                              <p className="text-muted mb-0">
                                {texts.homeCryptoPaymentUnderTitle499}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <span className="text-primary expandTitle">
                        {texts.homeCryptoPaymentTitle5}
                      </span>
                      <hr />
                      <br />
                      <div className="row">
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://autoskola-starcevic.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner6} alt="Autoskola Starcevic" />
                              </div>
                              <div className="content mt-4">
                                <p>Autoškola Starčević</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.radissonhotels.com/en-us/hotels/radisson-blu-resort-split-spa">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner17} alt="Hotel Split" />
                              </div>
                              <div className="content mt-4">
                                <p>Hotel Split d.d.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.ines.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner20} alt="Ines Tehnika" />
                              </div>
                              <div className="content mt-4">
                                <p>Ines tehnika j.d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.facebook.com/rubicon.pub.osijek/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner18} alt="HU-BO" />
                              </div>
                              <div className="content mt-4">
                                <p>HU-BO d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://autoskola-blue.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner5} alt="Autoskola Blue" />
                              </div>
                              <div className="content mt-4">
                                <p>Autoškola Blue</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://kamp-kalebic.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner21} alt="Kamp Kalebic" />
                              </div>
                              <div className="content mt-4">
                                <p>Kamp Kalebić</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.euroauto.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner11} alt="Euroauto" />
                              </div>
                              <div className="content mt-4">
                                <p>Euroauto d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://villakatarina.com.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner32} alt="Villa Katarina" />
                              </div>
                              <div className="content mt-4">
                                <p>Villa Katarina</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://poliklinika-svetiante.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img
                                  src={partner29}
                                  alt="Poliklinika Sv. Ante"
                                />
                              </div>
                              <div className="content mt-4">
                                <p>Polikilinka Sv. Ante</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.auto-reor.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner4} alt="Auto Reor" />
                              </div>
                              <div className="content mt-4">
                                <p>Auto Reor d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.njuskalo.hr/trgovina/dream-car">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner10} alt="Dream Car" />
                              </div>
                              <div className="content mt-4">
                                <p>DreamCar</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://rullastreetfood.com/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner30} alt="Rulla Streetfood" />
                              </div>
                              <div className="content mt-4">
                                <p>Rulla j.d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://kotromanovic.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner2} alt="Ac Kotromanovic" />
                              </div>
                              <div className="content mt-4">
                                <p>AC Kotromanović</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.facebook.com/hemp.agro.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner14} alt="Hemp Agro" />
                              </div>
                              <div className="content mt-4">
                                <p>Hemp Agro d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.extremesport.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner13} alt="Extreme Sport" />
                              </div>
                              <div className="content mt-4">
                                <p>Extreme sport d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://cingi-lingi-carda.eatbu.com/?lang=hr">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner8} alt="Cingi Lingi" />
                              </div>
                              <div className="content mt-4">
                                <p>Čingi Lingi Čarda d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.hoteltvrdja.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner7} alt="Castellum Art" />
                              </div>
                              <div className="content mt-4">
                                <p>Castellum Art d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          {/* <a href=""> */}
                          <div className="features feature-primary">
                            <div className="position-relative d-inline-block rounded-corners">
                              <img src={partner9} alt="Dm Team" />
                            </div>
                            <div className="content mt-4">
                              <p>DMTeam j.d.o.o.</p>
                            </div>
                          </div>
                          {/* </a> */}
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://krisclinic.hr">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner22} alt="Krisclinic" />
                              </div>
                              <div className="content mt-4">
                                <p>Krisclinic obrt</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://merlinka.hr">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner26} alt="Merlinka" />
                              </div>
                              <div className="content mt-4">
                                <p>Merlinka d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.aliceinblockchains.io">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img
                                  src={partner3}
                                  alt="Alice in blockchains"
                                />
                              </div>
                              <div className="content mt-4">
                                <p>Alice in blockchains</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://ladutti.com">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner23} alt="La Dutti" />
                              </div>
                              <div className="content mt-4">
                                <p>La Dutti frizerski obrt</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.medros.hr">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner25} alt="MEDrOS" />
                              </div>
                              <div className="content mt-4">
                                <p>MEDrOS medicina rada i sporta</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.facebook.com/studio.fusion.os">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner14} alt="Studio Fusion" />
                              </div>
                              <div className="content mt-4">
                                <p>Studio Fusion</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.facebook.com/998Garage/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner1} alt="998 Garage d.o.o." />
                              </div>
                              <div className="content mt-4">
                                <p>998 Garage d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="#;">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner28} alt="Pamida d.o.o." />
                              </div>
                              <div className="content mt-4">
                                <p>Pamida d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.solvermax.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner31} alt="Solvermax j.d.o.o." />
                              </div>
                              <div className="content mt-4">
                                <p>Solvermax j.d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://hotel-silver.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner16} alt="Srebro d.o.o." />
                              </div>
                              <div className="content mt-4">
                                <p>Srebro d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://www.exoro.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner12} alt="Srebro d.o.o." />
                              </div>
                              <div className="content mt-4">
                                <p>Exoro d.p.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://optika-oko.business.site/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner27} alt="Srebro d.o.o." />
                              </div>
                              <div className="content mt-4">
                                <p>Konjik d.o.o. - Optika Oko</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="https://massa.hr/">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner24} alt="Srebro d.o.o." />
                              </div>
                              <div className="content mt-4">
                                <p>Massa d.o.o.</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners">
                          <a href="#;">
                            <div className="features feature-primary">
                              <div className="position-relative d-inline-block rounded-corners">
                                <img src={partner19} alt="Srebro d.o.o." />
                              </div>
                              <div className="content mt-4">
                                <p>i-KONST</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <br />
                      <MapComponent />
                      <br />
                      <br />
                      <span className="text-primary expandTitle">
                        {texts.homeCryptoPaymentTitle6}
                      </span>
                      <hr />
                      <br />
                      <div className="timeline">
                        <p>{texts.homeCryptoPaymentTitle61}</p>
                        <p>{texts.homeCryptoPaymentTitle62}</p>
                        <p>{texts.homeCryptoPaymentTitle63}</p>
                        <p>{texts.homeCryptoPaymentTitle64}</p>
                        <p>{texts.homeCryptoPaymentTitle65}</p>
                        <p>{texts.homeCryptoPaymentTitle66}</p>
                        <p>{texts.homeCryptoPaymentTitle67}</p>
                      </div>
                      <p />
                    </div>
                  </div>
                </div>
                {/* APP */}
                <div
                  id="card3"
                  className="card border-0 rounded mb-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".3s"
                >
                  <a
                    data-toggle="collapse"
                    href="#collapsethree"
                    className="faq position-relative collapsed"
                    aria-expanded="false"
                    aria-controls="collapsethree"
                  >
                    <div
                      className="card-header border-0 card-color pt-3 pl-3 pb-3 pr-5"
                      id="headingfifone"
                    >
                      <h4 className="title mb-0">
                        <span className="text-primary">
                          {texts.homeServiceTitle3}
                        </span>
                      </h4>
                      <p className="text-muted mb-0 faq-ans">
                        <br />
                        {texts.homeServiceUnderTitle3}
                      </p>
                    </div>
                  </a>
                  <div
                    id="collapsethree"
                    className="collapse"
                    aria-labelledby="headingthree"
                    data-parent="#accordionExampleone"
                  >
                    <div className="card-body py-4">
                      <p className="text-muted mb-0 faq-ans"></p>
                      <div className="desktopview">
                        <div className="row align-items-center mx-3 mb-4">
                          <div className="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
                            <div className="section-title">
                              <h1 className="title mb-3">
                                {texts.homeAppTitle1}{" "}
                                <span className="text-primary">
                                  {texts.homeAppTitle12}
                                </span>{" "}
                                {texts.homeAppTitle13}
                              </h1>
                              <p className="text-muted">
                                {texts.homeAppUnderTitle11}
                              </p>
                              <ul className="list-unstyled text-muted">
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle12}
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle13}
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle14}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-5 order-1 order-md-2">
                            <div className="app-feature-shape-right position-relative">
                              <div className="text-center text-md-end">
                                <img
                                  src={login}
                                  className="img-fluid"
                                  alt=""
                                  width={250}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center mx-3 mb-4">
                          <div className="col-lg-6 col-md-5">
                            <div className="app-feature-shape-left position-relative">
                              <div className="text-center text-md-start">
                                <img
                                  src={walkthrough1}
                                  className="img-fluid"
                                  alt=""
                                  width={250}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-7 mt-5 mt-sm-0">
                            <div className="section-title">
                              <h1 className="title mb-3">
                                <i className="mdi mdi-chevron-double-right text-primary" />
                                {texts.homeAppTitle2}
                              </h1>
                              <p className="para-desc text-muted">
                                {texts.homeAppUnderTitle21}
                              </p>
                              <ul className="list-unstyled text-muted">
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle22}
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle23}
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle24}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center mx-3 mb-4">
                          <div className="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
                            <div className="section-title">
                              <h1 className="title mb-3">
                                <i className="mdi mdi-chevron-double-right text-primary" />
                                {texts.homeAppTitle3}
                              </h1>
                              <p className="para-desc text-muted">
                                {texts.homeAppUnderTitle31}
                              </p>
                              <ul className="list-unstyled text-muted">
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle32}
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle33}
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle34}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-5 order-1 order-md-2">
                            <div className="app-feature-shape-right position-relative">
                              <div className="text-center text-md-end">
                                <img
                                  src={walkthrough2}
                                  className="img-fluid"
                                  alt=""
                                  width={250}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center mx-3">
                          <div className="col-lg-6 col-md-5">
                            <div className="app-feature-shape-left position-relative">
                              <div className="text-center text-md-start">
                                <img
                                  src={walkthrough3}
                                  className="img-fluid"
                                  alt=""
                                  width={250}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-7 mt-5 mt-sm-0">
                            <div className="section-title">
                              <h1 className="title mb-3">
                                <i className="mdi mdi-chevron-double-right text-primary" />
                                {texts.homeAppTitle4}
                              </h1>
                              <p className="para-desc text-muted">
                                {texts.homeAppUnderTitle41}
                              </p>
                              <ul className="list-unstyled text-muted">
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle42}
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle43}
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  {texts.homeAppUnderTitle44}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="mx-auto mt-5">
                            <h4 className="text-center">
                              {texts.homeAppTitle5}
                            </h4>
                            <a
                              href="https://apps.apple.com/app/blok-be/id6444213022"
                              className="btn btn-primary mt-2 mr-2"
                            >
                              <i className="mdi mdi-apple" />
                              App Store
                            </a>
                            <a
                              href="https://play.google.com/store/apps/details?id=com.blokbe.app"
                              className="btn btn-outline-primary mt-2 bg-light"
                            >
                              <i className="mdi mdi-google-play" /> Play Store
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="mobileview">
                        <div className="row align-items-center mb-4">
                          <div className="col-lg-6 col-md-7 mt-sm-0 mb-3">
                            <div className="section-title">
                              <h1 className="title mb-3">
                                Here at{" "}
                                <span className="text-primary">BlokBe</span>{" "}
                                security is not a product but a process.
                              </h1>
                              <p className="text-muted">
                                Our procedures are handcrafted with the
                                slightest details. We use cutting edge
                                technologies to make sure your assets are always
                                safe.
                              </p>
                              <ul className="list-unstyled text-muted">
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  Scrambled keypad design prevents onlookers
                                  from detecting the PIN
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  Native biometric authentication in mobile
                                  banking procedures
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  We do not store any of sensitive credit or
                                  debit card data. Your device, your storage.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-5">
                            <div className="app-feature-shape-right position-relative">
                              <div className="text-center text-md-end">
                                <img
                                  src={login}
                                  className="img-fluid"
                                  alt=""
                                  width={150}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center mb-4">
                          <div className="col-lg-6 col-md-7 mt-4 mt-sm-0 mb-3">
                            <div className="section-title">
                              <h1 className="title mb-3">
                                <i className="mdi mdi-chevron-double-right text-primary" />
                                Non-custodial wallet
                              </h1>
                              <p className="para-desc text-muted">
                                BlokBe let's you to be your own bank. This
                                implies that you have full control over your
                                funds.
                              </p>
                              <ul className="list-unstyled text-muted">
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  STEP 1: Create your wallet
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  STEP 2: Add it to BlokBe App
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  STEP 3: Start banking.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-5">
                            <div className="app-feature-shape-left position-relative">
                              <div className="text-center text-md-start">
                                <img
                                  src={walkthrough1}
                                  className="img-fluid"
                                  alt=""
                                  width={150}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center mb-4">
                          <div className="col-lg-6 col-md-7 mt-4 mt-sm-0 mb-3">
                            <div className="section-title">
                              <h1 className="title mb-3">
                                <i className="mdi mdi-chevron-double-right text-primary" />
                                Fast transfer
                              </h1>
                              <p className="para-desc text-muted">
                                In few clicks transfer assets to our trusted
                                merchants or directly into your wallet.
                              </p>
                              <ul className="list-unstyled text-muted">
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  STEP 1: Payment method
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  STEP 2: Merchant or wallet
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  STEP 3: Amount. Done.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-5">
                            <div className="app-feature-shape-right position-relative">
                              <div className="text-center text-md-end">
                                <img
                                  src={walkthrough2}
                                  className="img-fluid"
                                  alt=""
                                  width={150}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-lg-6 col-md-7 mt-4 mt-sm-0 mb-3">
                            <div className="section-title">
                              <h1 className="title mb-3">
                                <i className="mdi mdi-chevron-double-right text-primary" />
                                Verified partners program
                              </h1>
                              <p className="para-desc text-muted">
                                BlokBe verified partners program provides most
                                reliable and secure merchant network to work
                                with trust.
                              </p>
                              <ul className="list-unstyled text-muted">
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  STEP 1: Setup merchant reference
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  STEP 2: Choose offered packages
                                </li>
                                <li className="mb-0">
                                  <span className="text-primary h5 mr-2">
                                    <i className="mdi mdi-check-circle-outline" />
                                  </span>
                                  STEP 3: Start banking.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-5">
                            <div className="app-feature-shape-left position-relative">
                              <div className="text-center text-md-start">
                                <img
                                  src={walkthrough3}
                                  className="img-fluid"
                                  alt=""
                                  width={150}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mx-auto mt-5">
                            <h4 className="text-center">Get the app on</h4>
                            <a
                              href="https://apps.apple.com/app/blok-be/id6444213022"
                              className="btn btn-primary mt-2 mr-2"
                            >
                              <i className="mdi mdi-apple" />
                              App Store
                            </a>
                            <a
                              href="https://play.google.com/store/apps/details?id=com.blokbe.app"
                              className="btn btn-outline-primary mt-2 bg-light"
                            >
                              <i className="mdi mdi-google-play" /> Play Store
                            </a>
                          </div>
                        </div>
                      </div>
                      <p />
                    </div>
                  </div>
                </div>
                {/* ATM */}
                <div
                  id="card4"
                  className="card border-0 rounded mb-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".4s"
                >
                  <a
                    data-toggle="collapse"
                    href="#collapsefour"
                    className="faq position-relative collapsed"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                  >
                    <div
                      className="card-header border-0 card-color pt-3 pl-3 pb-3 pr-5"
                      id="headingfifone"
                    >
                      <h4 className="title mb-0">
                        <span className="text-primary">
                          {texts.homeServiceTitle4}
                        </span>
                      </h4>
                    </div>
                  </a>
                  <div
                    id="collapsefour"
                    className="collapse"
                    aria-labelledby="headingfour"
                    data-parent="#accordionExampleone"
                  >
                    <div className="card-body py-4 mx-3">
                      <p className="text-muted mb-0 faq-ans">
                        {texts.homeServiceUnderTitle41}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <section className="section pt-0 mb-5">
          <div className="container">
            <div className="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
              <div className="col-12 text-center">
                <div
                  className="section-title wow animate__animated animate__fadeInUp"
                  data-wow-delay=".45s"
                >
                  <h4
                    className={`title mb-2 text-container ${isFading ? "fade-out" : ""
                      }`}
                  >
                    {texts.contactAllTitle}
                  </h4>
                  <p
                    className={`text-muted para-desc mx-auto text-container ${isFading ? "fade-out" : ""
                      }`}
                  >
                    {texts.contactAllUnderTitle1} <br />{" "}
                    {texts.contactAllUnderTitle2}{" "}
                    <span className="text-primary font-weight-bold">
                      BlokBe
                    </span>
                    .
                  </p>
                  <div className="mt-2">
                    <div className="btn btn-primary mt-2 mr-2">
                      <i className="mdi mdi-account-box" />
                      <NavLink
                        className={`text-white text-container ${isFading ? "fade-out" : ""
                          }`}
                        to="/contact"
                      >
                        {texts.contactAllButton}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
