import React, { useEffect, useContext } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import Footer from "./Footer";
import CookieConsent from "./CookieConsent";

import navLogo from "../images/logo.png";
import engLogo from "../images/NEW/Flag_of_Croatia.png";
import croLogo from "../images/NEW/Flag_of_UK.png";

// Translate
import { en, hr } from "./Translation";
import { TranslationContext } from "./TranslationContext";

const Layout = () => {
  // Translate
  const { translate, language, isFading } = useContext(TranslationContext);
  const texts = language === "hr" ? hr : en;
  const flagSrc = language === "en" ? croLogo : engLogo;
  const flagAlt = language === "en" ? "croatian" : "english";

  const location = useLocation();
  const hideFooter =
    location.pathname === "/blokbe-atm" ||
    location.pathname === "/privacy-policy" ||
    location.pathname === "/terms-of-conditions" ||
    location.pathname === "/contact";
  const showPriceContainer = location.pathname === "/";

  useEffect(() => {
    if (showPriceContainer) {
      fetchCoinData();
    }
  }, [showPriceContainer]);

  function fetchCoinData() {
    const coins = [
      {
        name: "bitcoin",
        price: ".price1",
        change: ".change1",
        price2: ".price11",
        change2: ".change11",
      },
      {
        name: "ethereum",
        price: ".price2",
        change: ".change2",
        price2: ".price22",
        change2: ".change22",
      },
      {
        name: "solana",
        price: ".price3",
        change: ".change3",
        price2: ".price33",
        change2: ".change33",
      },
      {
        name: "binancecoin",
        price: ".price4",
        change: ".change4",
        price2: ".price44",
        change2: ".change44",
      },
      {
        name: "ripple",
        price: ".price5",
        change: ".change5",
        price2: ".price55",
        change2: ".change55",
      },
      {
        name: "cardano",
        price: ".price6",
        change: ".change6",
        price2: ".price66",
        change2: ".change66",
      },
      {
        name: "cosmos",
        price: ".price7",
        change: ".change7",
        price2: ".price77",
        change2: ".change77",
      },
    ];

    fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coins
        .map((c) => c.name)
        .join(",")}&vs_currencies=usd&include_24hr_change=true`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        coins.forEach(function (coin) {
          const priceStr = `$${data[coin.name].usd.toFixed(2)}`;
          const changeStr = `${data[coin.name].usd_24h_change.toFixed(2) > 0 ? "+" : ""
            }${data[coin.name].usd_24h_change.toFixed(2)}`;
          document.querySelector(coin.price).innerHTML = priceStr;
          document.querySelector(coin.price2).innerHTML = priceStr;
          document.querySelector(coin.change).innerHTML = changeStr;
          document.querySelector(coin.change2).innerHTML = changeStr;
          document.querySelector(coin.change).style.color =
            data[coin.name].usd_24h_change >= 0 ? "green" : "red";
          document.querySelector(coin.change2).style.color =
            data[coin.name].usd_24h_change >= 0 ? "green" : "red";
        });
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  }

  return (
    <>
      <header id="topnav" className="defaultscroll sticky">
        {showPriceContainer && (
          <div className="price-container">
            <div className="price-box">
              <div className="bitcoin-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png"
                  alt="bitcoin logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id btc-size">BTC/USD</div>
                  &nbsp;&nbsp;
                  <div className="price1 price-styling" />
                  &nbsp;
                  <div className="change1 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="ethereum-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                  alt="ethereum logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">ETH/USD</div>&nbsp;&nbsp;
                  <div className="price2 price-styling" />
                  &nbsp;
                  <div className="change2 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="solana-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png"
                  alt="solana logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">SOL/USD</div>&nbsp;&nbsp;
                  <div className="price3 price-styling" />
                  &nbsp;
                  <div className="change3 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="bnb-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                  alt="binance coin logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">BNB/USD</div>&nbsp;&nbsp;
                  <div className="price4 price-styling" />
                  &nbsp;
                  <div className="change4 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="xrp-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/52.png"
                  alt="xrp ripple logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">XRP/USD</div>&nbsp;&nbsp;
                  <div className="price5 price-styling" />
                  &nbsp;
                  <div className="change5 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="ada-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png"
                  alt="cardano logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">ADA/USD</div>&nbsp;&nbsp;
                  <div className="price6 price-styling" />
                  &nbsp;
                  <div className="change6 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="link-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png"
                  alt="cosmos logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">ATOM/USD</div>&nbsp;&nbsp;
                  <div className="price7 price-styling" />
                  &nbsp;
                  <div className="change7 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="bitcoin-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png"
                  alt="bitcoin logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id btc-size">BTC/USD</div>
                  &nbsp;&nbsp;
                  <div className="price11 price-styling" />
                  &nbsp;
                  <div className="change11 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="ethereum-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                  alt="ethereum logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">ETH/USD</div>&nbsp;&nbsp;
                  <div className="price22 price-styling" />
                  &nbsp;
                  <div className="change22 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="solana-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png"
                  alt="solana logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">SOL/USD</div>&nbsp;&nbsp;
                  <div className="price33 price-styling" />
                  &nbsp;
                  <div className="change33 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="bnb-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                  alt="binance coin logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">BNB/USD</div>&nbsp;&nbsp;
                  <div className="price44 price-styling" />
                  &nbsp;
                  <div className="change44 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="xrp-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/52.png"
                  alt="xrp ripple logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">XRP/USD</div>&nbsp;&nbsp;
                  <div className="price55 price-styling" />
                  &nbsp;
                  <div className="change55 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="ada-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png"
                  alt="cardano logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">ADA/USD</div>&nbsp;&nbsp;
                  <div className="price66 price-styling" />
                  &nbsp;
                  <div className="change66 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <div className="link-box box-styling">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png"
                  alt="cosmos logo"
                  className="logo-size"
                />
                <div className="inner-box-styling">
                  <div className="currency-id">ATOM/USD</div>&nbsp;&nbsp;
                  <div className="price77 price-styling" />
                  &nbsp;
                  <div className="change77 change-styling">%</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container">
          <div>
            <NavLink to="/" className="logo text-primary klozlink">
              <img
                className="shadow-logo"
                src={navLogo}
                width="110px"
                alt="Logo"
              />
            </NavLink>
          </div>

          <div className="menu-extras">
            <div className="menu-item">
              <a className="navbar-toggle">
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
            </div>
          </div>

          <div id="navigation" style={{ float: "right" }} className={`text-container ${isFading ? 'fade-out' : ''}`}>
            <ul className="navigation-menu">
              <li>
                <NavLink to="/" className="klozlink">{texts.navHome}</NavLink>
              </li>

              <li className="has-submenu parent-menu-item">
                <a href="#;">{texts.navServices}</a>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <NavLink to="/crypto-exchange" className="sub-menu-item klozlink">
                      {texts.navExchange}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/crypto-payment" className="sub-menu-item klozlink">
                      {texts.navPayment}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/blokbe-app" className="sub-menu-item klozlink">
                      {texts.navApp}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/blokbe-atm" className="sub-menu-item klozlink">
                      {texts.navAtm}
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li>
                <NavLink to="/education" className="klozlink">{texts.navEducation}</NavLink>
              </li>
              <li>
                <NavLink to="/about" className="klozlink">{texts.navAbout}</NavLink>
              </li>

              <li className="has-submenu parent-menu-item">
                <a href="#">{texts.navPrivacyAndTerms}</a>
                <span className="menu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <NavLink to="/privacy-policy" className="klozlink">{texts.navPrivacy}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/terms-of-conditions" className="klozlink">
                      {texts.navTerms}
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li>
                <NavLink to="/contact" className="klozlink">{texts.navContact}</NavLink>
              </li>

              <li>
                <NavLink className="klozlink">
                  <img
                    onClick={() => {
                      translate();
                    }}
                    className="flag-radius"
                    src={flagSrc} // Use the flagSrc variable based on the current language
                    alt={flagAlt} // Use the flagAlt variable based on the current language
                    width="40px"
                  />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <Outlet />

      {!hideFooter && <Footer />}
      <CookieConsent />
    </>
  );
};

export default Layout;
