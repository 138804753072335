import React, { useState, useEffect } from 'react';
import footerLogo from "../images/logo.png";

const CookieConsent = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const acceptedCookies = localStorage.getItem('cookieAccepted');
        if (!acceptedCookies) {
            setShowBanner(true);
        }
    }, []);

    useEffect(() => {
        if (showBanner) {
            // Disable scrolling when the banner is displayed
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scrolling when the banner is hidden
            document.body.style.overflow = 'auto';  // or 'scroll' if you have it set to scroll by default
        }
    
        // Cleanup function: This will ensure that if this component unmounts for any reason, the overflow property is reset to auto
        return () => {
            document.body.style.overflow = 'auto'; // or 'scroll' if you have it set to scroll by default
        };
    }, [showBanner]);

    const acceptCookies = () => {
        localStorage.setItem('cookieAccepted', 'true');
        setShowBanner(false);
    };

    return (
        showBanner && (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.7)', // You can adjust the opacity as desired
                color: 'white',
                zIndex: 9999, // This will ensure the modal sits on top of all other elements
                display: 'flex',
                alignItems: 'center', // This will vertically center the content within the modal
                justifyContent: 'center', // This will horizontally center the content within the modal
            }}>
                <div style={{
                    textAlign: 'center',
                    width: '70%', // This gives it some padding on larger screens, you can adjust as necessary
                    maxWidth: '500px', // Ensuring the modal isn't too wide on large screens
                    padding: '20px',
                    backgroundColor: 'rgba(50, 50, 50, 0.9)', // A slightly different shade for the actual content for differentiation
                    borderRadius: '10px',
                }}>
                    <img
                        className="shadow-logo logom"
                        src={footerLogo}
                        alt="logo"
                        width="100px"
                        style={{
                            marginBottom: '15px',
                        }}
                    /><br></br>

                    This website uses cookies to enhance your user experience.<br></br>

                    <button
                        onClick={acceptCookies}
                        style={{
                            marginTop: '15px',
                            padding: '5px 15px',
                            cursor: 'pointer',
                        }}
                        className='btn btn-primary'
                    >
                        Accept
                    </button>
                </div>
            </div>

        )
    );
}

export default CookieConsent;
