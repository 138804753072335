import React from "react";
import { useEffect, useContext } from "react";
import MapComponent from "./MapComponent";

import { NavLink } from "react-router-dom";

// Images
import partner1 from "../images/partners/990_garage.png";
import partner2 from "../images/partners/ac_kotromanovic.png";
import partner3 from "../images/partners/alice-in-blockchains.png";
import partner4 from "../images/partners/auto_reor.png";
import partner5 from "../images/partners/autoskola_blue.png";
import partner6 from "../images/partners/autoskola_starcevic.png";
import partner7 from "../images/partners/castellum_art.png";
import partner8 from "../images/partners/cingi_lingi_carda.png";
import partner9 from "../images/partners/dm_team.png";
import partner10 from "../images/partners/dream_car.jpg";
import partner11 from "../images/partners/euroauto.png";
import partner12 from "../images/partners/exoro.png";
import partner13 from "../images/partners/extreme_sport.png";
import partner14 from "../images/partners/fusion_studio.png";
import partner15 from "../images/partners/hemp_agro.jpg";
import partner16 from "../images/partners/hotel_silver.png";
import partner17 from "../images/partners/hotel_split.png";
import partner18 from "../images/partners/hu-bo.jpg";
import partner19 from "../images/partners/ikonst.png";
import partner20 from "../images/partners/ines_tehnika.png";
import partner21 from "../images/partners/kamp_kalebic.png";
import partner22 from "../images/partners/krisclinic.png";
import partner23 from "../images/partners/la-dutti.png";
import partner24 from "../images/partners/massa.png";
import partner25 from "../images/partners/medros.png";
import partner26 from "../images/partners/merlinka.png";
import partner27 from "../images/partners/optika_oko.png";
import partner28 from "../images/partners/pamida.png";
import partner29 from "../images/partners/poliklinika_svante.png";
import partner30 from "../images/partners/rulla.png";
import partner31 from "../images/partners/solvermax.png";
import partner32 from "../images/partners/villa_katarina.png";

import cryptopaymentIMG from "../images/NEW/cryptopayment.png";

import { en, hr } from "./Translation";
import { TranslationContext } from "./TranslationContext";

const BlokbeCryptoPayment = () => {
  const { language, isFading } = useContext(TranslationContext);
  const texts = language === "hr" ? hr : en;

  const pdfLink = language === "hr" ? "/form-hr.pdf" : "/form-en.pdf";

  useEffect(() => {});

  return (
    <>
      <section className="bg-half bg-light">
        <svg
          className="svvg"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          viewBox="0 0 100 100"
        >
          <path
            fill="#6bb2fc"
            className="out-top"
            d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z"
          />
          <path
            fill="#165DA7"
            className="in-top"
            d="M20.6,4.1C11.6,1.5-1.9,2.5-8,11.2-16.3,23.1-8.2,45.6,7.4,50S42.1,38.9,41,24.5C40.2,14.1,29.4,6.6,20.6,4.1Z"
          />
          <path
            fill="#6bb2fc"
            className="out-bottom"
            d="M105.9,48.6c-12.4-8.2-29.3-4.8-39.4.8-23.4,12.8-37.7,51.9-19.1,74.1s63.9,15.3,76-5.6c7.6-13.3,1.8-31.1-2.3-43.8C117.6,63.3,114.7,54.3,105.9,48.6Z"
          />
          <path
            fill="#165DA7"
            className="in-bottom"
            d="M102,67.1c-9.6-6.1-22-3.1-29.5,2-15.4,10.7-19.6,37.5-7.6,47.8s35.9,3.9,44.5-12.5C115.5,92.6,113.9,74.6,102,67.1Z"
          />
        </svg>
        <div
          className={`container text-container ${isFading ? "fade-out" : ""}`}
        >
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="section-title">
                <div className="text-center">
                  <h4 className="title mb-4 wow animate__animated animate__fadeInUp">
                    BlokBe {texts.homeServiceTitle2}
                  </h4>
                  <img
                    src={cryptopaymentIMG}
                    className="img-fluid rounded-md shadow-md wow animate__animated animate__fadeIn animate__slow"
                    alt=""
                  />
                </div>
                <p
                  className="text-muted mb-0 mt-4 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  <span className="text-primary font-weight-bold">
                    BlokBe {texts.homeServiceTitle2}{" "}
                  </span>
                  {texts.homeServiceUnderTitle21}
                  <br />
                  <br />
                  {texts.homeServiceUnderTitle22}
                </p>
                <h4
                  className="my-4 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.homeCryptoPaymentTitle1}
                </h4>
                <p
                  className="text-muted wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.homeCryptoPaymentUnderTitle1}
                  <span className="text-primary font-weight-bold">
                    {" "}
                    {texts.homeCryptoPaymentUnderTitle11}{" "}
                  </span>
                  {texts.homeCryptoPaymentUnderTitle12}{" "}
                  <span className="text-primary font-weight-bold">
                    {texts.homeCryptoPaymentUnderTitle13}
                  </span>
                  {texts.homeCryptoPaymentUnderTitle14}{" "}
                  <span className="text-primary font-weight-bold">
                    {texts.homeCryptoPaymentUnderTitle15}
                  </span>
                  {texts.homeCryptoPaymentUnderTitle16}
                  <br />
                  <a
                    href="https://gateway.blokbe.com/"
                    className="btn btn-primary mt-2 mr-2"
                  >
                    {texts.homeCryptoPaymentUnderTitle17}
                  </a>
                </p>
                <h4
                  className="my-4 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.homeCryptoPaymentTitle2}
                </h4>
                <p
                  className="text-muted mb-0 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.homeCryptoPaymentUnderTitle211}{" "}
                  <span className="text-primary font-weight-bold">
                    {texts.homeCryptoPaymentUnderTitle212}
                  </span>{" "}
                  {texts.homeCryptoPaymentUnderTitle213}
                </p>
                <p
                  className="text-muted mb-0 wow animate__animated animate__fadeInUp text-primary"
                  data-wow-delay=".1s"
                >
                  <br />
                  {texts.homeCryptoPaymentUnderTitle22}
                  <br />
                  {texts.homeCryptoPaymentUnderTitle23}
                  <br />
                  {texts.homeCryptoPaymentUnderTitle24}{" "}
                  <span className="text-primary font-weight-bold">
                    treasury@blokbe.com
                  </span>
                  <br />
                  <a href={pdfLink} className="btn btn-primary mt-2 mr-2">
                    {texts.homeCryptoPaymentUnderTitle26}
                  </a>
                </p>
                <p
                  className="text-muted wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  <br />
                  {texts.homeCryptoPaymentUnderTitle27}
                </p>
                <h4
                  className="my-4 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  {texts.homeCryptoPaymentTitle3}
                </h4>
                <p
                  className="text-muted mb-0 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".2s"
                >
                  {texts.homeCryptoPaymentUnderTitle31}
                </p>
                <p
                  className="text-muted wow animate__animated animate__fadeInUp"
                  data-wow-delay=".2s"
                >
                  <br />
                  {texts.homeCryptoPaymentUnderTitle321}{" "}
                  <span className="text-primary font-weight-bold">
                    {texts.homeCryptoPaymentUnderTitle322}{" "}
                  </span>
                  {texts.homeCryptoPaymentUnderTitle323}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section
        className={`section text-container ${isFading ? "fade-out" : ""}`}
      >
        <h2
          className="title mb-4 text-center wow animate__animated animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {texts.homeCryptoPaymentTitle4}
        </h2>
        <div className="container">
          <div className="row">
            <div
              className="col-md-4 col-12 mt-4 pt-4 col-mobile wow animate__animated animate__fadeInUp"
              data-wow-delay=".2s"
            >
              <div className="features feature-primary">
                <div className="image position-relative d-inline-block">
                  <i className="mdi mdi-laptop h2 text-primary" />
                </div>
                <div className="content mt-4">
                  <h5>{texts.homeCryptoPaymentUnderTitle41}</h5>
                  <p className="text-muted mb-0">
                    {texts.homeCryptoPaymentUnderTitle411}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-12 mt-4 pt-4 col-mobile wow animate__animated animate__fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="features feature-primary">
                <div className="image position-relative d-inline-block">
                  <i className="mdi mdi-lightbulb-on-outline h2 text-primary" />
                </div>
                <div className="content mt-4">
                  <h5>{texts.homeCryptoPaymentUnderTitle42}</h5>
                  <p className="text-muted mb-0">
                    {texts.homeCryptoPaymentUnderTitle422}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-12 mt-4 pt-4 col-mobile wow animate__animated animate__fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="features feature-primary">
                <div className="image position-relative d-inline-block">
                  <i className="mdi mdi-check-all h2 text-primary" />
                </div>
                <div className="content mt-4">
                  <h5>{texts.homeCryptoPaymentUnderTitle43}</h5>
                  <p className="text-muted mb-0">
                    {texts.homeCryptoPaymentUnderTitle433}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-12 mt-4 pt-4 col-mobile wow animate__animated animate__fadeInUp"
              data-wow-delay=".5s"
            >
              <div className="features feature-primary">
                <div className="image position-relative d-inline-block">
                  <i className="mdi mdi-chart-bubble h2 text-primary" />
                </div>
                <div className="content mt-4">
                  <h5>{texts.homeCryptoPaymentUnderTitle44}</h5>
                  <p className="text-muted mb-0">
                    {texts.homeCryptoPaymentUnderTitle444}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-12 mt-4 pt-4 col-mobile wow animate__animated animate__fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="features feature-primary">
                <div className="image position-relative d-inline-block">
                  <i className="mdi mdi-timetable h2 text-primary" />
                </div>
                <div className="content mt-4">
                  <h5>{texts.homeCryptoPaymentUnderTitle45}</h5>
                  <p className="text-muted mb-0">
                    {texts.homeCryptoPaymentUnderTitle455}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-12 mt-4 pt-4 col-mobile wow animate__animated animate__fadeInUp"
              data-wow-delay=".7s"
            >
              <div className="features feature-primary">
                <div className="image position-relative d-inline-block">
                  <i className="mdi mdi-piggy-bank h2 text-primary" />
                </div>
                <div className="content mt-4">
                  <h5>{texts.homeCryptoPaymentUnderTitle46}</h5>
                  <p className="text-muted mb-0">
                    {texts.homeCryptoPaymentUnderTitle466}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-12 mt-4 pt-4 col-mobile wow animate__animated animate__fadeInUp"
              data-wow-delay=".8s"
            >
              <div className="features feature-primary">
                <div className="image position-relative d-inline-block">
                  <i className="mdi mdi-swap-horizontal h2 text-primary" />
                </div>
                <div className="content mt-4">
                  <h5>{texts.homeCryptoPaymentUnderTitle47}</h5>
                  <p className="text-muted mb-0">
                    {texts.homeCryptoPaymentUnderTitle477}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-12 mt-4 pt-4 col-mobile wow animate__animated animate__fadeInUp"
              data-wow-delay=".9s"
            >
              <div className="features feature-primary">
                <div className="image position-relative d-inline-block">
                  <i className="mdi mdi-face-agent h2 text-primary" />
                </div>
                <div className="content mt-4">
                  <h5>{texts.homeCryptoPaymentUnderTitle48}</h5>
                  <p className="text-muted mb-0">
                    {texts.homeCryptoPaymentUnderTitle488}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-12 mt-4 pt-4 col-mobile wow animate__animated animate__fadeInUp"
              data-wow-delay="1s"
            >
              <div className="features feature-primary">
                <div className="image position-relative d-inline-block">
                  <i className="mdi mdi-shield-lock h2 text-primary" />
                </div>
                <div className="content mt-4">
                  <h5>{texts.homeCryptoPaymentUnderTitle49}</h5>
                  <p className="text-muted mb-0">
                    {texts.homeCryptoPaymentUnderTitle499}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white rotate-180 bg-light">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <section
        className={`section bg-light text-container ${
          isFading ? "fade-out" : ""
        }`}
      >
        <div className="container">
          <h2
            className="title mb-4 text-center wow animate__animated animate__fadeInUp"
            data-wow-delay=".2s"
          >
            {texts.homeCryptoPaymentTitle5}
          </h2>
          <div className="container">
            <div className="row">
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".2s"
              >
                <a href="https://autoskola-starcevic.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner6} alt="Autoskola Starcevic" />
                    </div>
                    <div className="content mt-4">
                      <p>Autoškola Starčević</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".25s"
              >
                <a href="https://www.radissonhotels.com/en-us/hotels/radisson-blu-resort-split-spa">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner17} alt="Hotel Split" />
                    </div>
                    <div className="content mt-4">
                      <p>Hotel Split d.d.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
              >
                <a href="https://www.ines.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner20} alt="Ines Tehnika" />
                    </div>
                    <div className="content mt-4">
                      <p>Ines tehnika j.d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".35s"
              >
                <a href="https://www.facebook.com/rubicon.pub.osijek/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner18} alt="HU-BO" />
                    </div>
                    <div className="content mt-4">
                      <p>HU-BO d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".4s"
              >
                <a href="https://autoskola-blue.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner5} alt="Autoskola Blue" />
                    </div>
                    <div className="content mt-4">
                      <p>Autoškola Blue</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".45s"
              >
                <a href="https://kamp-kalebic.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner21} alt="Kamp Kalebic" />
                    </div>
                    <div className="content mt-4">
                      <p>Kamp Kalebić</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.euroauto.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner11} alt="Euroauto" />
                    </div>
                    <div className="content mt-4">
                      <p>Euroauto d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://villakatarina.com.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner32} alt="Villa Katarina" />
                    </div>
                    <div className="content mt-4">
                      <p>Villa Katarina</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://poliklinika-svetiante.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner29} alt="Poliklinika Sv. Ante" />
                    </div>
                    <div className="content mt-4">
                      <p>Polikilinka Sv. Ante</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.auto-reor.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner4} alt="Auto Reor" />
                    </div>
                    <div className="content mt-4">
                      <p>Auto Reor d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.njuskalo.hr/trgovina/dream-car">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner10} alt="Dream Car" />
                    </div>
                    <div className="content mt-4">
                      <p>DreamCar</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://rullastreetfood.com/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner30} alt="Rulla Streetfood" />
                    </div>
                    <div className="content mt-4">
                      <p>Rulla j.d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://kotromanovic.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner2} alt="Ac Kotromanovic" />
                    </div>
                    <div className="content mt-4">
                      <p>AC Kotromanović</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.facebook.com/hemp.agro.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner15} alt="Hemp Agro" />
                    </div>
                    <div className="content mt-4">
                      <p>Hemp Agro d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.extremesport.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner13} alt="Extreme Sport" />
                    </div>
                    <div className="content mt-4">
                      <p>Extreme sport d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://cingi-lingi-carda.eatbu.com/?lang=hr">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner8} alt="Cingi Lingi" />
                    </div>
                    <div className="content mt-4">
                      <p>Čingi Lingi Čarda d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.hoteltvrdja.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner7} alt="Castellum Art" />
                    </div>
                    <div className="content mt-4">
                      <p>Castellum Art d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                {/* <a href=""> */}
                <div className="features feature-primary">
                  <div className="position-relative d-inline-block rounded-corners">
                    <img src={partner9} alt="Dm Team" />
                  </div>
                  <div className="content mt-4">
                    <p>DMTeam j.d.o.o.</p>
                  </div>
                </div>
                {/* </a> */}
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://krisclinic.hr">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner22} alt="Krisclinic" />
                    </div>
                    <div className="content mt-4">
                      <p>Krisclinic obrt</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://merlinka.hr">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner26} alt="Merlinka" />
                    </div>
                    <div className="content mt-4">
                      <p>Merlinka d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.aliceinblockchains.io">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner3} alt="Alice in blockchains" />
                    </div>
                    <div className="content mt-4">
                      <p>Alice in blockchains</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://ladutti.com">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner23} alt="La Dutti frizerski obrt" />
                    </div>
                    <div className="content mt-4">
                      <p>La Dutti frizerski obrt</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.medros.hr">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner25} alt="MEDrOS" />
                    </div>
                    <div className="content mt-4">
                      <p>MEDrOS medicina rada i sporta</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.facebook.com/studio.fusion.os">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner14} alt="Studio Fusion" />
                    </div>
                    <div className="content mt-4">
                      <p>Studio Fusion</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.facebook.com/998Garage/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner1} alt="998 Garage d.o.o." />
                    </div>
                    <div className="content mt-4">
                      <p>998 Garage d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                {/* <a href="#;"> */}
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner28} alt="Pamida d.o.o." />
                    </div>
                    <div className="content mt-4">
                      <p>Pamida d.o.o.</p>
                    </div>
                  </div>
                {/* </a> */}
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.solvermax.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner31} alt="Solvermax j.d.o.o." />
                    </div>
                    <div className="content mt-4">
                      <p>Solvermax j.d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://hotel-silver.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner16} alt="Srebro d.o.o." />
                    </div>
                    <div className="content mt-4">
                      <p>Srebro d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://www.exoro.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner12} alt="Srebro d.o.o." />
                    </div>
                    <div className="content mt-4">
                      <p>Exoro d.p.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://optika-oko.business.site/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner27} alt="Srebro d.o.o." />
                    </div>
                    <div className="content mt-4">
                      <p>Konjik d.o.o. - Optika Oko</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <a href="https://massa.hr/">
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner24} alt="Srebro d.o.o." />
                    </div>
                    <div className="content mt-4">
                      <p>Massa d.o.o.</p>
                    </div>
                  </div>
                </a>
              </div>
              <div
                className="col-md-4-partners col-12 mt-1 pt-4 col-mobile-partners wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                {/* <a href="#"> */}
                  <div className="features feature-primary">
                    <div className="position-relative d-inline-block rounded-corners">
                      <img src={partner19} alt="Srebro d.o.o." />
                    </div>
                    <div className="content mt-4">
                      <p>i-KONST</p>
                    </div>
                  </div>
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>

        <div
          className="wow animate__animated animate__fadeInUp"
          data-wow-delay=".6s"
        >
          <MapComponent />
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <div className={`mt-5 mb-1 text-container ${isFading ? "fade-out" : ""}`}>
        <h2
          className="title mb-4 text-center blue-title-border wow animate__animated animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {texts.homeCryptoPaymentTitle6}
        </h2>
        <div className="timeline">
          <p
            className="wow animate__animated animate__fadeInUp"
            data-wow-delay=".2s"
          >
            {texts.homeCryptoPaymentTitle61}
          </p>
          <p
            className="wow animate__animated animate__fadeInUp"
            data-wow-delay=".3s"
          >
            {texts.homeCryptoPaymentTitle62}
          </p>
          <p
            className="wow animate__animated animate__fadeInUp"
            data-wow-delay=".4s"
          >
            {texts.homeCryptoPaymentTitle63}
          </p>
          <p
            className="wow animate__animated animate__fadeInUp"
            data-wow-delay=".5s"
          >
            {texts.homeCryptoPaymentTitle64}
          </p>
          <p
            className="wow animate__animated animate__fadeInUp"
            data-wow-delay=".6s"
          >
            {texts.homeCryptoPaymentTitle65}
          </p>
          <p
            className="wow animate__animated animate__fadeInUp"
            data-wow-delay=".7s"
          >
            {texts.homeCryptoPaymentTitle66}
          </p>
          <p
            className="wow animate__animated animate__fadeInUp"
            data-wow-delay=".8s"
          >
            {texts.homeCryptoPaymentTitle67}
          </p>
        </div>
      </div>
      <section className="section pt-0 mb-5">
        <div className="container">
          <div className="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
            <div className="col-12 text-center">
              <div
                className="section-title wow animate__animated animate__fadeInUp"
                data-wow-delay=".45s"
              >
                <h4
                  className={`title mb-2 text-container ${
                    isFading ? "fade-out" : ""
                  }`}
                >
                  {texts.contactAllTitle}
                </h4>
                <p
                  className={`text-muted para-desc mx-auto text-container ${
                    isFading ? "fade-out" : ""
                  }`}
                >
                  {texts.contactAllUnderTitle1} <br />{" "}
                  {texts.contactAllUnderTitle2}{" "}
                  <span className="text-primary font-weight-bold">BlokBe</span>.
                </p>
                <div className="mt-2">
                  <div className="btn btn-primary mt-2 mr-2">
                    <i className="mdi mdi-account-box" />
                    <NavLink
                      className={`text-white text-container ${
                        isFading ? "fade-out" : ""
                      }`}
                      to="/contact"
                    >
                      {texts.contactAllButton}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlokbeCryptoPayment;
