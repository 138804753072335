import React, { useState } from 'react';

const TranslationContext = React.createContext({
  translate: () => {},
  language: 'hr',
});

const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [isFading, setIsFading] = useState(false);

  const translate = () => {
    setIsFading(true);
    setTimeout(() => {
      setLanguage(language === 'hr' ? 'en' : 'hr');
      setIsFading(false);
    }, 250); // Change to the desired duration of the fade
  };

  return (
    <TranslationContext.Provider value={{ translate, language, isFading }}>
      {children}
    </TranslationContext.Provider>
  );
};

export { TranslationContext, TranslationProvider };
