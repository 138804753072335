import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { TranslationProvider } from "./pages/TranslationContext";

// Nav and footer
import Layout from "./pages/Layout";

// Pages
import Home from "./pages/Home";
import BlokbeCryptoExchange from "./pages/CryptoExchange";
import BlokbeCryptoPayment from "./pages/CryptoPayment";
import BlokbeApp from "./pages/App";
import BlokbeATM from "./pages/ATM";
import Education from "./pages/Education";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";

// All CSS
import "./css/colors/blokbe.css";
import "./css/colors/default.css";
import "./css/animate.css";
import "./css/blokbe.css";
import "./css/bootstrap.css";
import "./css/style.css";
import "./css/NEW.css";
import "./css/materialdesignicons.css";


export default function App() {
  return (
    <TranslationProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />

            <Route path="crypto-exchange" element={<BlokbeCryptoExchange />} />
            <Route path="crypto-payment" element={<BlokbeCryptoPayment />} />
            <Route path="blokbe-app" element={<BlokbeApp />} />
            <Route path="blokbe-atm" element={<BlokbeATM />} />

            <Route path="education" element={<Education />} />
            <Route path="about" element={<About />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="terms-of-conditions" element={<Terms />} />
            <Route path="contact" element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </TranslationProvider>
  );
}
